import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import ePartnerConfigFieldName from '../ePartnerConfigFieldName';

export default React.memo(forwardRef(function CustomResourcePool({
                                                        config,
                                                        canChangeBlock,
                                                        canOverride,
                                                        setSaveFunctionPool,
                                                        selectedCasino,
                                                        overriddenBlocks,
                                                        setOverriddenBlocks,
                                                        getConfigOrCasinoConfigProperty
                                                      }, ref) {
  const defaultValues = useMemo(() => ({
    enabled: false,
    name: '',
  }), []);
  const [formData, setFormData] = useState(defaultValues);

  const saveFunction = useCallback(() => {
    let data = {};
    if (((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL])) && (formData.enabled || formData.name)) {
      data[ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL] = {
        enabled: formData.enabled,
        name: formData.name ||  defaultValues.name
      };
    }

    return data;
  }, [selectedCasino, overriddenBlocks, formData, defaultValues]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL]: saveFunction,
    }))
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!config) return;
    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL);
    setFormData({
      enabled: configData && configData.hasOwnProperty('enabled') ? configData.enabled : defaultValues.enabled,
      name: configData && configData.hasOwnProperty('name') ? configData.name : defaultValues.name,
    });
  }, [config, setFormData, defaultValues, getConfigOrCasinoConfigProperty]);

  const toggleCheckStatus = useCallback((value) => {
    setFormData(prev => ({...prev, enabled: value}));
  }, [setFormData]);

  const changeInterval = useCallback((e) => {
    setFormData(prev => ({...prev, name: e.target.value}));
  }, [setFormData]);

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL]: !prev[ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL],
    }));

    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL);
    setFormData({
      enabled: configData && configData.hasOwnProperty('enabled') ? configData.enabled : defaultValues.enabled,
      name: configData && configData.hasOwnProperty('name') ? configData.name : defaultValues.name,
    });
  };

  const reset = () => {
    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL);
    setFormData({
      enabled: configData && configData.hasOwnProperty('enabled') ? configData.enabled : defaultValues.enabled,
      name: configData && configData.hasOwnProperty('name') ? configData.name : defaultValues.name,
    });
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      {!!selectedCasino && canOverride &&
        <div className={'partners_config_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        </div>}

      <span className={'partners_config_body_form_box_title'}>Custom Resource Pool</span>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Enabled</span>
        <Checkbox checked={!!formData.enabled} setChecked={toggleCheckStatus}
                  id={`${ePartnerConfigFieldName.EPCFN_CUSTOM_RESOURCE_POOL}_enabled`} disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Name</span>
        <input type={'text'} className={'medium_input'} value={formData.name} onChange={changeInterval}
               disabled={!canChangeBlock || !formData.enabled}/>
        <Info info={''}/>
      </div>
    </div>
  );
}));
