import { modalTypes } from './modalTypes';

const SHOW_MODAL = 'MODALS/SHOW_MODAL';
const CLOSE_MODAL = 'MODALS/CLOSE_MODAL';

const initialState = {};

Object.keys(modalTypes).forEach(type => {
  initialState[type] = {
    visible: false,
    data: null
  }
});

export const modalReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SHOW_MODAL:
      let showData = payload.data;
      if (payload.type === modalTypes.ERROR) {
        showData = state[modalTypes.ERROR].data ? [...state[modalTypes.ERROR].data, payload.data] : [payload.data];
      }
      return {
        ...state,
        [payload.type]: {
          visible: true,
          data: showData || null,
        }
      };
    case CLOSE_MODAL:
      let data = state[modalTypes[payload.type]].data;
      if (payload.type === modalTypes.ERROR) {
        data = (data && data.length > 1) ? data.slice(1) : null;
      }
      return {
        ...state,
        [payload.type]: {
          visible: false,
          data: data,
        }
      };
    default:
      return state;
  }
};

export const modalActions = {
  showModal(type, data) {
    return {
      type: SHOW_MODAL,
      payload: {
        type,
        data
      }
    }
  },

  closeModal(type) {
    return {
      type: CLOSE_MODAL,
      payload: {
        type
      }
    }
  }
};
