const SET_RATES = 'PARTNER_CONFIG/SET_RATES';
const SET_OPERATORS = 'PARTNER_CONFIG/SET_OPERATORS';
const SET_CONFIG = 'PARTNER_CONFIG/SET_CONFIG';
const UPDATE_OPERATOR_CASINO_LIST = 'PARTNER_CONFIG/UPDATE_OPERATOR_CASINO_LIST';
const SET_GAMES_LIST = 'PARTNER_CONFIG/SET_GAMES_LIST';

const initialState = {
  operators: null,
  config: null,
  rates: null
};

export const partnersConfigReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_OPERATORS:
      return {
        ...state,
        operators: payload
      };
    case SET_RATES:
      return {
        ...state,
        rates: payload
      };
    case SET_CONFIG:
      return {
        ...state,
        config: payload
      };
    case UPDATE_OPERATOR_CASINO_LIST:
      return {
        ...state,
        operators: { ...state.operators, [payload.operatorId]: { ...state.operators[payload.operatorId], casino_list: payload.list } }
      };
    case SET_GAMES_LIST:
      return {
        ...state,
        gameList: payload
      };
    default:
      return state;
  }
};

export const partnersConfigActions = {
  setOperators(payload) {
    return {
      type: SET_OPERATORS,
      payload
    }
  },

  setRates(rates) {
    return {
      type: SET_RATES,
      payload: rates
    }
  },

  setConfig(config) {
    return {
      type: SET_CONFIG,
      payload: config
    }
  },

  updateOperatorCasinoList(operatorId, list) {
    return {
      type: UPDATE_OPERATOR_CASINO_LIST,
      payload: {
        operatorId,
        list
      }
    }
  },

  setGamesList(gamesList) {
    return {
      type: SET_GAMES_LIST,
      payload: gamesList
    }
  },
};
