export const getEnvironment = () => {
  const url = window.location.href;
  let environment = 'production';

  if (/localhost|0\.0\.0\.0|192\.168|\.dev\./.test(url)) {
    environment = 'development';
  } else if (/\.stage\./.test(url)) {
    environment = 'stage';
  }

  return environment;
}

export const getEnvironmentDomainSuffix = () => {
  const url = window.location.href;
  let environment = '';

  if (/localhost|0\.0\.0\.0|192\.168|\.dev\./.test(url)) {
    environment = 'dev';
  } else if (/\.stage\./.test(url)) {
    environment = 'stage';
  }

  return environment;
}
