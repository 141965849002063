import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import DropdownLayout from '../../dropdownLayout';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import { useSelector } from 'react-redux';
import { partnerConfigSelectors } from '../../../redux/partnersConfig/selectors';
import SearchSelectInput from '../../../uiKit/inputs/searchSelectInput';
import eOperatorsConfigsFieldName from '../eOperatorsConfigsFieldName';

export default React.memo(forwardRef(function WinLimits({
                                                          configForView,
                                                          canChangeBlock,
                                                          canOverride,
                                                          setSaveFunctionPool,
                                                          overriddenBlocks,
                                                          toggleOverriddenField,
                                                          setValueToConfigForView
                                                        }, ref) {
  const rates = useSelector(partnerConfigSelectors.getRates);
  const defaultFormData = useMemo(() => ({
    showMaxWinOnStart: false,
    default: {
      currency: '',
      value: 0
    },
    currencies: {}
  }), []);
  const [viewCurrencies, setViewCurrencies] = useState({});
  const [formData, setFormData] = useState(defaultFormData);

  const saveFunction = useCallback(() => {
    const data = {};
    const maxWinLimitData = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT] === configForView.type || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START] === configForView.type) {
      if (formData.default.currency || +formData.default.value) {
        maxWinLimitData.default = { currency: formData.default.currency.toLowerCase(), value: +formData.default.value };
      }

      if (Object.keys(formData.currencies).length) {
        maxWinLimitData.currencies = formData.currencies;
      }

      data.showMaxWinOnStart = formData.showMaxWinOnStart;
      if (Object.keys(maxWinLimitData).length) data.maxWinLimit = maxWinLimitData;
    }
    return data;
  }, [overriddenBlocks, formData, configForView.isGlobalConfig, configForView.type]);

  const updateCurrencies = useCallback((value, defaultCurrency) => {
    let currencies = {};
    if (defaultCurrency && value >= 0) {
      currencies = rates && Object.keys(rates).reduce((prev, currency) => {
        prev[currency.toLowerCase()] = value * rates[currency] / rates[defaultCurrency];
        return prev;
      }, {})
    }

    setViewCurrencies(currencies);
  }, [setViewCurrencies, rates]);

  const setConfigDataToFormData = useCallback(() => {
    const maxWinLimit = configForView.config[eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT];
    let showMaxWinOnStart = configForView.config[eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START];
    showMaxWinOnStart = showMaxWinOnStart === undefined ? defaultFormData.showMaxWinOnStart : showMaxWinOnStart;

    setFormData({
      [eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START]: showMaxWinOnStart,
      currencies: maxWinLimit?.currencies || defaultFormData.currencies,
      default: {
        value: maxWinLimit?.default?.value || defaultFormData.default.value,
        currency: maxWinLimit?.default?.currency.toUpperCase() || defaultFormData.default.currency
      }
    });
    if (maxWinLimit?.default?.value >= 0 && maxWinLimit?.default?.currency) updateCurrencies(maxWinLimit?.default?.value, maxWinLimit?.default?.currency.toUpperCase());
  }, [configForView, setFormData, updateCurrencies, defaultFormData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    setConfigDataToFormData();
  }, [canChangeBlock, setConfigDataToFormData, configForView.config]);

  const toggleShowMaxWinOnStart = useCallback((value) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START, value);
  }, [setValueToConfigForView, configForView.type]);

  const changeMaxWinLimit = (e) => {
    setFormData(prev => ({ ...prev, default: { ...prev.default, value: e.target.value } }));
    updateCurrencies(e.target.value, formData.default?.currency);
  };

  const onBlurMaxWinLimit = (e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT, {
      ...formData,
      default: { ...formData.default, value: e.target.value }
    });
    updateCurrencies(e.target.value, formData.default?.currency);
  };

  const changeLimitCurrency = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT,
      { ...formData, default: { ...formData.default, currency: e.target.value } });
    updateCurrencies(formData.default?.value, e.target.value);
  }, [setValueToConfigForView, configForView.type, formData, updateCurrencies]);

  const overrideMaxWinByCurrency = (e) => {
    setFormData(prev => ({ ...prev, currencies: { ...prev.currencies, [e.target.dataset.currency]: e.target.value } }));
  };

  const onBlurMaxWinByCurrencyText = (e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT, {
      ...formData,
      currencies: { ...formData.currencies, [e.target.dataset.currency]: +e.target.value }
    });
  };

  const toggleOverrideCurrency = (currency) => () => {
    let currencies;
    if (Object.keys(formData.currencies).includes(currency)) {
      const prev = Object.entries(formData.currencies);
      currencies = Object.fromEntries(prev.filter(el => el[0] !== currency));
    } else {
      currencies = { ...formData.currencies, [currency]: (formData.currencies && formData.currencies[currency]) || 0 }
    }

    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT, {
      ...formData,
      currencies
    });
  };

  const checkOverride = () => {
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT);
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START);
  };

  const reset = useCallback(() => {
    setConfigDataToFormData();
  }, [setConfigDataToFormData]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>
      {!configForView.isGlobalConfig && canOverride &&
        <div className={'operators_configs_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT] === configForView.type || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT]}
          />
        </div>}
      <span className={'operators_configs_body_form_box_title'}>Win limits</span>
      <div className={'input_form'}>
        <span className={'input_form_title'}>Show max win on start</span>
        <Checkbox checked={!!formData.showMaxWinOnStart} setChecked={toggleShowMaxWinOnStart}
                  id={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START}`}
                  disabled={!canChangeBlock} />
        <Info info={''} />
      </div>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Max win limit</span>
        <input type={'number'} value={formData.default ? formData.default.value : ''} onChange={changeMaxWinLimit}
               onBlur={onBlurMaxWinLimit}
               disabled={!canChangeBlock} />
        <SearchSelectInput
          selected={formData.default && formData.default.currency ? formData.default.currency.toUpperCase() : ''}
          options={rates ? Object.keys(rates) : []} inputProps={{ className: 'input_small_width' }}
          onSelect={changeLimitCurrency}
          disabled={!canChangeBlock} />
        {/*<select value={formData.default.currency.toUpperCase()} onChange={changeLimitCurrency}>*/}
        {/*  <option value={''} />*/}
        {/*  {rates && Object.keys(rates).map(currency =>*/}
        {/*    <option key={currency} value={currency}>*/}
        {/*      {currency}*/}
        {/*    </option>)}*/}
        {/*</select>*/}

        <Info info={''} />
      </div>

      <DropdownLayout title={'Currencies'}>
        <div className={'dropdown_body'}>
          {rates && Object.keys(rates).map(currency => {
              currency = currency.toLowerCase();
              const overridden = Object.keys(formData.currencies).includes(currency);
              return <div className={'input_form input_form_grid_3_label_right'} key={currency}>
                <AddCheckboxLayout title={'override'} setChecked={toggleOverrideCurrency(currency)}
                                   checked={overridden}
                                   checkboxId={`${currency}_max_win_override_checkbox`}>
                  <input type={'number'} disabled={!overridden || !canChangeBlock}
                         value={!overridden ? viewCurrencies[currency] : formData.currencies[currency] ? formData.currencies[currency] : ''}
                         onChange={overrideMaxWinByCurrency} data-currency={currency}
                         onBlur={onBlurMaxWinByCurrencyText}
                  />
                  <span className={'input_form_subtitle'}>{currency.toUpperCase()}</span>
                </AddCheckboxLayout>
              </div>
            }
          )}
        </div>
      </DropdownLayout>
    </div>
  );
}));
