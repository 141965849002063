import React, {useMemo} from "react";
import Preloader from "../loading/preloader";
import { Link } from 'react-router-dom';
import {Table, Typography, DatePicker} from 'antd';
import {useRoundsModel} from "./listModel";
import {formatCoins} from "../../utils/formatCurrency";

const {Title} = Typography;
const {RangePicker} = DatePicker;

export default function RoundsHistory() {
  const {
    sessionParams,
    urlParams,
    loading,
    isFetching,
    intervalRange,
    list,
    totalCount,
    handlePageChange,
    handleDatesChange
  } = useRoundsModel();

  return <div className={'rounds_history'}>
    {(loading || isFetching) && <Preloader/>}
    <Title>{isFetching ? null : `Game: ${sessionParams.game_bundle}`}</Title>
    <RangePicker value={intervalRange} onChange={handleDatesChange} className={'rounds_history__dates_picker'}/>
    <Table columns={useColumns(urlParams.hash)} dataSource={list} rowKey={item => item.id} size={'middle'}
           pagination={{
             position: ['bottomCenter'],
             current: parseInt(urlParams.page) || 1,
             total: totalCount,
             showSizeChanger: false,
             onChange: handlePageChange,
           }}/>
  </div>
}

function useColumns(hash) {
  return useMemo(() => [
    {
      title: 'Id',
      dataIndex: 'operator_round',
      key: 'operator_round',
    },
    {
      title: 'Started at',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (time) => new Date(time).toString(),
    },
    {
      title: 'Bet',
      dataIndex: 'bet_amount',
      key: 'bet_amount',
      render: (_, item) => formatCoins(item.bet_amount, item.decimals, item.currency),
    },
    {
      title: 'Win',
      dataIndex: 'win_amount',
      key: 'win_amount',
      render: (_, item) => formatCoins(item.win_amount, item.decimals, item.currency),
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      render: (_, item) => formatCoins(item.profit, item.decimals, item.currency),
    },
    {
      title: '',
      key: 'details',
      render: (_, item) => <Link to={`/player-results/details/${item.id}?hash=${hash}`} key={item.id}>Details</Link>
    }
  ], [hash]);
}
