import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import BaseModal from '../../../modals/baseModal';
import { useDispatch, useSelector } from 'react-redux';
import { partnerConfigSelectors } from '../../../../redux/partnersConfig/selectors';
import Info from '../../../inputForms/info';
import { generateBets, roundBets } from '../../../../utils/betsGenerator';
import { modalActions } from '../../../../redux/modals/reducer';
import { modalTypes } from '../../../../redux/modals/modalTypes';
import AddCurrencyWindow from './addCurrencyWindow';

export default React.memo(function EditWindow({ close, callback, currenciesArr, availableBets = [] }) {

  const defaultFormData = useMemo(() => ({
    minBet: availableBets['EUR']?.[0] || 0,
    maxBet: availableBets['EUR']?.at(-1) || 0,
    betsCount: Object.values(availableBets || {}).reduce((prev, bets) => bets.length > prev ? bets.length : prev, 4),
    generatorFunctionDegree: 2,
    currencies: currenciesArr.join(', '),
    currenciesArr: currenciesArr,
    availableBets: availableBets
  }), [currenciesArr, availableBets]);

  const dispatch = useDispatch();
  const rates = useSelector(partnerConfigSelectors.getRates);
  const [formData, setFormData] = useState(defaultFormData);
  const [errorsBets, setErrorsBets] = useState({});
  const [newCurrenciesArr, setNewCurrenciesArr] = useState([]);
  const MAX_BETS_COUNT = useRef(20);
  const [showAddCurrencyWindow, setShowAddCurrencyWindow] = useState(false);

  useEffect(() => {
    setErrorsBets({});
  }, [formData.availableBets]);

  const openAddCurrencyWindow = () => {
    setShowAddCurrencyWindow(true);
  };

  const closeAddCurrencyWindow = () => {
    setShowAddCurrencyWindow(false);
  };

  const onChangeCurrency = (e) => {
    const currenciesArr = e.target.value.replace(/\s/g, '').split(',').filter(currency=>currency).map(currency => currency.toUpperCase());
    setFormData(prev => ({
      ...prev,
      currencies: e.target.value.toUpperCase(),
      currenciesArr
    }));
  };

  const onChangeMinBet = useCallback((e) => {
    setFormData(prev => ({...prev, minBet: e.target.value}));
  }, [setFormData]);

  const onChangeMaxBet = useCallback((e) => {
    setFormData(prev => ({...prev, maxBet: e.target.value}));
  }, [setFormData]);

  const onChangeBetsCount = (e) => {
    setFormData(prev => ({...prev, betsCount: Number(e.target.value)}));
  };

  const onChangeGeneratorFunctionDegree = useCallback((e) => {
    setFormData(prev => ({...prev, generatorFunctionDegree: e.target.value}));
  }, [setFormData]);

  const onClickAllCurrencies = useCallback((e) => {
    setFormData(prev => {
      const currenciesArr = Object.keys(prev.availableBets);
      return ({
        ...prev,
        currencies: currenciesArr.join(', '),
        currenciesArr
      })
    });
    }, [setFormData]);

  const onSortCurrencies = useCallback((e) => {
    setFormData(prev => {
      const currenciesArr = [ ...prev.currenciesArr ].sort((a, b) => a === 'EUR' ? -1 : b === 'EUR' ? 1 : a.toLowerCase().localeCompare(b.toLowerCase()))
      return ({
        ...prev,
        currencies: currenciesArr.join(', '),
        currenciesArr
      })
    });
    }, [setFormData]);

  const onChangeAvailableBet = (availableBet, currency, betIndex, isBlur = false) => {
    setFormData(prev => {
      const prevAvailableBets = prev.availableBets;
      const availableBetsByCurrency = Array.isArray(prevAvailableBets[currency]) ? [...prevAvailableBets[currency]] : [];
      availableBetsByCurrency[betIndex] = availableBet;
      if (isBlur && availableBet === '' && availableBetsByCurrency.length - 1 === betIndex) {
        while (availableBetsByCurrency.length > 0 && availableBetsByCurrency[availableBetsByCurrency.length - 1] === '') {
          availableBetsByCurrency.pop();
        }
      }
      return {
        ...prev,
        availableBets: {
          ...prevAvailableBets,
          [currency]: availableBetsByCurrency
        }
      }
    });
  };

  const onBlurAvailableBet = (availableBet, currency, betIndex) => {
    onChangeAvailableBet(availableBet === '' ? '' : +availableBet, currency, betIndex, true)
  }

  const generateAvailableBetsToEur = useCallback((currencies, EURBets = formData.availableBets['EUR']) => {
    if (!EURBets) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'EUR available bets must be specified'));
      return;
    }

    const result = {};
    if (!rates) return result;

    const unexistRatesForCurrencies = [];
    for (let currency of currencies) {
      if (currency === 'EUR') continue;
      let rate = rates[currency.toUpperCase()];
      if (currency === 'dog') {
        rate = rates['DOGE'];
      }
      if (!rate) {
        console.warn('Can`t find rate for currency:', currency);
        unexistRatesForCurrencies.push(currency);
        delete result[currency];
        continue;
      }
      const betsArray = EURBets.map(bet => bet * rate);
      if (betsArray.length > 0) result[currency.toUpperCase()] = roundBets(betsArray);
    }

    if (unexistRatesForCurrencies.length > 0) dispatch(modalActions.showModal(modalTypes.ERROR, `Can\`t find rate for currency: ${unexistRatesForCurrencies.join(', ')}`));
    return result;
  }, [dispatch, rates, formData]);

  const tryToAddCurrencies = useCallback((currencies) => {
    const alreadyExistList = [];
    const newList = [];

    for (let currency of currencies) {
      if (formData.availableBets.hasOwnProperty(currency)) {
        alreadyExistList.push(currency);
      } else {
        newList.push(currency);
      }
    }
    if (newList.length) {
      const emptyBetsList = newList.reduce((prev, currency) => {
        prev[currency] = [];
        return prev;
      }, {})
      const newBets = formData.availableBets['EUR'] ? generateAvailableBetsToEur(newList, formData.availableBets['EUR']) : emptyBetsList;
      const newCurrenciesList = Object.keys(newBets);
      setNewCurrenciesArr(prev => [ ...prev, ...newCurrenciesList.filter(currency => !defaultFormData.availableBets.hasOwnProperty(currency)) ]);
      setFormData(prev => ({
        ...prev,
        currencies: [ ...prev.currenciesArr, ...newCurrenciesList].join(', '),
        currenciesArr: [ ...prev.currenciesArr, ...newCurrenciesList],
        availableBets: { ...prev.availableBets,  ...newBets }
      }));
    }

    if (alreadyExistList.length) {
      dispatch(modalActions.showModal(modalTypes.ERROR, `${alreadyExistList.join(', ')} already exist in available bets list`));
    }
  }, [setFormData, dispatch, generateAvailableBetsToEur, formData.availableBets, defaultFormData.availableBets]);


  const onClickReset = useCallback(() => {
    setFormData(prev => ({
      ...prev,
        currencies: defaultFormData.currencies,
        currenciesArr: defaultFormData.currenciesArr,
        availableBets: defaultFormData.availableBets
    }));
    setNewCurrenciesArr([]);
  }, [setFormData, defaultFormData]);

  const onClickResetSingleCurrency = useCallback((currency) => {
    setFormData(prev => {
      const currenciesArr = prev.currenciesArr.includes(currency) ? prev.currenciesArr: [ ...prev.currenciesArr, currency ];
      return ({
        ...prev,
        currencies: currenciesArr.join(', '),
        currenciesArr,
        availableBets: {
          ...prev.availableBets,
          [currency]: defaultFormData.availableBets[currency]
        }
      });
    })
  }, [setFormData, defaultFormData]);

  const onShowChangedBets = useCallback(() => {
    let currencyByChangedBets = Object.keys(defaultFormData.availableBets).filter(currency => {
      return formData.availableBets[currency] && (defaultFormData.availableBets[currency].length !== formData.availableBets[currency]?.length || defaultFormData.availableBets[currency].some((bet, betIndex) => +bet !== formData?.availableBets[currency][betIndex]));
    })
    currencyByChangedBets = [ ...currencyByChangedBets, ...newCurrenciesArr ];
    setFormData(prev => ({
      ...prev,
      currenciesArr: currencyByChangedBets,
      currencies: currencyByChangedBets.join(', '),
    }));
  }, [formData, defaultFormData, setFormData, newCurrenciesArr]);

  const onDeleteBets = useCallback((currency) => {
    setFormData(prev => {
      const newCurrenciesList = prev.currenciesArr.filter(cur => cur !== currency);
      const newBets = Object.keys(prev.availableBets).reduce((obj, cur) => {
        if (cur !== currency) obj[cur] = prev.availableBets[cur];
        return obj;
      }, {});
      delete newBets[currency];
      return ({
        ...prev,
        availableBets: newBets,
        currenciesArr: newCurrenciesList,
        currencies: newCurrenciesList.join(', '),
      });
    });
  }, [setFormData]);

  const onDeleteAllBets = useCallback((currency) => {
    setFormData(prev => ({
      ...prev,
      availableBets: {},
      currenciesArr: [],
      currencies: '',
    }));
    setNewCurrenciesArr([]);
  }, [setFormData]);

  const validate = useCallback((availableBets) => {
    let isValid = true;
    const errorsBets = {};
    Object.keys(availableBets).forEach(currency => {
      const bets = availableBets[currency];
      if (bets.length < 4) {
        errorsBets[currency] = bets.map((bet, index) => index);
        isValid = false;
        return;
      }
      bets.forEach((bet, betIndex) => {
        if (!bet) {
          if (!errorsBets[currency]) errorsBets[currency] = [];
          errorsBets[currency].push(betIndex);
          isValid = false;
        }
      })
    });
    if (!isValid) {
      setErrorsBets(errorsBets)
    }
    return isValid;
  }, []);

  const onUpdate = useCallback(() => {
    const isValid = validate(formData.availableBets);
    if (!isValid) return;
    callback(formData.availableBets);
    close();
  }, [formData, callback, close, validate]);

  const maxBetsCount = useMemo(()=>{
    return Object.values(formData.availableBets).reduce((prev, bets) => bets.length > prev ? bets.length : prev, Math.max(defaultFormData.betsCount, formData.betsCount));
  }, [formData, defaultFormData]);

  const addBetsCount = useCallback(() => {
    setFormData(prev => ({
      ...prev,
      betsCount: maxBetsCount + 1
    }))
  }, [setFormData, maxBetsCount]);

  const currenciesForView = useMemo(()=> {
    const currencies = formData.currenciesArr ? formData.currenciesArr.filter(currency => formData.availableBets[currency]) : [];
    if (!currencies.includes('EUR') && formData.availableBets['EUR']) {
      currencies.push('EUR')
    }
    const currenciesForView = [];
    currencies.forEach(function(item) { //removeDuplicates
      if (currenciesForView.indexOf(item) === -1) {
        currenciesForView.push(item);
      }
    });
    currenciesForView.sort(currency => currency === 'EUR' ? -1 : 0)
    return currenciesForView
    }, [formData]);

  const tryToGenerate = useCallback((currencies, EURBets = formData.availableBets['EUR']) => {

    if (currencies.length) {
      const bets = generateAvailableBetsToEur(currencies, EURBets);
      setFormData(prev => {
        const availableBets = { ...prev.availableBets, ...bets };
        if (currenciesForView.includes('EUR') && !showEURLikeDisabled) availableBets['EUR'] = EURBets;
        return ({
          ...prev,
          availableBets
        })
      });
    }
  }, [setFormData, generateAvailableBetsToEur, formData.availableBets, currenciesForView]);

  const generateAvailableBets = useCallback(() => {
    const EURBets = generateBets(+formData.minBet, +formData.maxBet, formData.betsCount, formData.generatorFunctionDegree, 'EUR');
    tryToGenerate(currenciesForView, EURBets);
  }, [tryToGenerate, formData]);

  const onClickUpdateFromEUR = useCallback(() => {
    tryToGenerate(currenciesForView);
  }, [tryToGenerate, currenciesForView]);

  const removedCurrencies = useMemo(()=> {
    return Object.keys(defaultFormData.availableBets).filter(currencies => !formData.availableBets[currencies]);
  }, [defaultFormData, formData]);

  const isDirty = useMemo(()=>{
    const currencyByChangedBets = Object.keys(defaultFormData.availableBets).filter(currency => {
      return formData.availableBets[currency] && (defaultFormData.availableBets[currency].length !== formData.availableBets[currency]?.length || defaultFormData.availableBets[currency].some((bet, betIndex) => +bet !== formData?.availableBets[currency][betIndex]));
    });
    currencyByChangedBets.push(...newCurrenciesArr);
    return (currencyByChangedBets.length > 0 || removedCurrencies.length > 0)
      && (currencyByChangedBets.some(currency => !formData.currenciesArr.includes(currency))
        || formData.currenciesArr.some(currency => !currencyByChangedBets.includes(currency))) ;
  }, [formData, defaultFormData, newCurrenciesArr, removedCurrencies.length]);

  const showEURLikeDisabled = useMemo(()=> {
    return formData.availableBets['EUR'] && !formData.currenciesArr.includes('EUR')
  }, [formData.availableBets, formData.currenciesArr]);

  const onKeyDown = useCallback((e) => {
    if (e.key !== 'Enter' || showAddCurrencyWindow) return;
    if (isDirty) {
      onShowChangedBets();
    } else {
      onUpdate();
    }
  }, [isDirty, onShowChangedBets, onUpdate, showAddCurrencyWindow])


  return (
    <BaseModal visible={true} onClose={close} showCloseButton onKeyDown={onKeyDown}>
      <div className={'modal modal_xxl operators_configs'}>

        <div className="modal_body edit_modal">
          <div className={'modal_header'}>
            Edit Available Bets
          </div>
          <div className={'input_form'}>
            <span className={'input_form_title__size_fixed'}>minBet</span>
            <input type={'number'} value={formData.minBet} onChange={onChangeMinBet}
            />
            <Info info={''}/>
          </div>
          <div className={'input_form'}>
            <span className={'input_form_title__size_fixed'}>maxBet</span>
            <input type={'number'} value={formData.maxBet} onChange={onChangeMaxBet}
            />
            <Info info={''}/>
          </div>
          <div className={'input_form'}>
            <span className={'input_form_title__size_fixed'}>betsCount</span>
            <input type={'number'} value={formData.betsCount || ''} onChange={onChangeBetsCount}
            />
            <Info info={'Max count: 20'} visibility={formData.betsCount > MAX_BETS_COUNT.current}/>
          </div>
          <div className={'input_form'}>
            <span className={'input_form_title__size_fixed'}>generatorFunctionDegree</span>
            <input type={'number'} value={formData.generatorFunctionDegree} onChange={onChangeGeneratorFunctionDegree}
            />
            <Info info={''}/>
          </div>
          <AddCurrencyWindow visible={showAddCurrencyWindow} close={closeAddCurrencyWindow}
                             callback={tryToAddCurrencies}/>
          <div className={'input_form'}>
            <span className={'input_form_title'}>currencies</span>
            <input className={'medium_input'}
                   type={'string'}
                   value={formData.currencies}
                   onChange={onChangeCurrency}
             />
            <button className={'button'} onClick={onClickAllCurrencies} disabled={Object.keys(formData.availableBets).length === currenciesForView.length && !showEURLikeDisabled}>ALL</button>
            <button className={'button'} onClick={onSortCurrencies}>Sort</button>
            <Info info={''}/>
          </div>
          <div className={'input_form'}>
            <button className={'button'} onClick={generateAvailableBets}>Generate</button>
            <button className={'button'} onClick={onClickUpdateFromEUR}>Update from EUR</button>
            <button className={'button button_light_blue'}
                    onClick={openAddCurrencyWindow}>Add currency
            </button>
            <button className={'button'} onClick={onDeleteAllBets}>Delete all</button>
            <button className={'button'} onClick={onClickReset}>Reset</button>
          </div>

          <div className="available_bets_bets_block">
            <div>
              <div className={'input_form available_bets_row'}></div>
              {currenciesForView.map(currency =>
                  <div key={currency} className={`input_form available_bets_row ${!defaultFormData.availableBets[currency] ? 'green' : ''}`}>
            <span className={'available_bets_row_currency'}>
              {currency}
            </span>
                  </div>
              )}
              {removedCurrencies.map(currency =>
                  <div key={currency} className={'input_form available_bets_row removed'}>
            <span className={'available_bets_row_currency'}>
              {currency}
            </span>
                  </div>
              )}
            </div>
            <div>
              <div className={'input_form available_bets_row'}></div>
              {currenciesForView.map(currency =>
                <div key={currency} className={`input_form available_bets_row ${!defaultFormData.availableBets[currency] ? 'green' : ''}`}>
                  <button className={'button icon'} onClick={() => onDeleteBets(currency)} disabled={currency === 'EUR' && showEURLikeDisabled}>&times;</button>
                  <button className={'button icon icon__reset'} onClick={() => onClickResetSingleCurrency(currency)} disabled={currency === 'EUR' && showEURLikeDisabled}>&#10227;</button>
                  <button className={'button icon'} onClick={() => tryToGenerate([currency])} disabled={currency === 'EUR' && showEURLikeDisabled}>&euro;</button>

                </div>
              )}
              {removedCurrencies.map(currency =>
                <div key={currency} className={'input_form available_bets_row removed'}>
                  <button className={'button icon'} onClick={() => onDeleteBets(currency)}  disabled={true}>&times;</button>
                  <button className={'button icon icon__reset'} onClick={() => onClickResetSingleCurrency(currency)}>&#10227;</button>
                  <button className={'button icon'} onClick={() => tryToGenerate([currency])}  disabled={true}>&euro;</button>

                </div>
              )}
            </div>
            <div className="available_bets_bets_List">
              <div className={'input_form available_bets_row'}>
                {
                  (new Array(maxBetsCount)).fill().map((_, betIndex) => <div className={'bet_index'} key={'bet_index' + betIndex}>{betIndex}</div>)
                }
              </div>
              {currenciesForView.map(currency =>
                <div key={currency} className={`input_form available_bets_row ${!defaultFormData.availableBets[currency] ? 'green' : ''}`}>
                  {(new Array(maxBetsCount)).fill().map((_, betIndex) => (
                      <input key={currency + betIndex} type={'number'} onWheel={(e) => e.deltaY !== 0 && e.target.blur()}
                             className={errorsBets[currency]?.includes(betIndex) ? 'red_border' : (!formData.availableBets[currency].length || ((formData.availableBets[currency].length - 1 >= betIndex || defaultFormData.availableBets[currency]?.length - 1 >= betIndex) && +formData.availableBets[currency]?.[betIndex] !== defaultFormData.availableBets[currency]?.[betIndex]) ? 'yellow_border' : '') }
                             disabled={currency === 'EUR' && showEURLikeDisabled}
                             value={
                               formData.availableBets[currency]
                               && formData.availableBets[currency][betIndex] !== undefined
                                 ? formData.availableBets[currency][betIndex]
                                 : ''
                             }
                             onChange={(e) => onChangeAvailableBet(e.target.value, currency, betIndex)}
                             onBlur={(e) => onBlurAvailableBet(e.target.value, currency, betIndex)}
                      />
                    )
                  )}
                  <button className={'button icon'} onClick={() => addBetsCount(currency)}>+</button>
                </div>
              )}
              {removedCurrencies.map(currency =>
                <div key={currency} className={'input_form available_bets_row removed'}>
                  {(new Array(maxBetsCount)).fill().map((_, betIndex) => (
                      <input key={currency + betIndex} type={'number'} onWheel={(e) => {
                        if (e.deltaY !== 0) e.target.blur();
                      }}
                             value={
                               defaultFormData.availableBets[currency]
                               && defaultFormData.availableBets[currency][betIndex] !== undefined
                                 ? defaultFormData.availableBets[currency][betIndex]
                                 : ''
                             }
                             readOnly={true}
                      />
                    )
                  )}
                </div>
              )}
            </div>
          </div>
        </div>


        <div className={'modal_controls'}>
          <button className={'button'} onClick={close}>Cancel</button>
          {
            isDirty
            ? <button className={'button'} onClick={onShowChangedBets}>Show changed Bets</button>
            : <button className={'button'} onClick={onUpdate}>Update</button>
          }
        </div>
      </div>
    </BaseModal>
  );
})
