import React, { useRef } from 'react';

export default React.memo(function BaseForm({ submit, onError, children, className = '' }) {
  const formRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = validate();

    if (errors) {
      onError && onError(errors);
    } else {
      submit(e);
    }
  };

  const validate = () => {
    const inputs = formRef.current.elements;
    let hasErrors = false;
    const errors = {};

    for (let input of inputs) {
      if (!input.validity.valid) {
        errors[input.name] = 'Error';
        hasErrors = true;
      }
    }

    return hasErrors ? errors : null;
  };

  return (
    <form ref={formRef} onSubmit={onSubmit} className={`w-full flex flex-col gap-12 ${className}`} noValidate>
      {children}
    </form>
  );
})
