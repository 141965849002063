import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import Info from '../../inputForms/info';
import DropdownLayout from '../../dropdownLayout';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import {useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../redux/partnersConfig/selectors';
import ePartnerConfigFieldName from '../ePartnerConfigFieldName';

export default React.memo(forwardRef(function DisplayRtp({
                                                           config,
                                                           canChangeBlock,
                                                           canOverride,
                                                           setSaveFunctionPool,
                                                           selectedCasino,
                                                           overriddenBlocks,
                                                           setOverriddenBlocks,
                                                           getConfigOrCasinoConfigProperty,
                                                           casinoConfig
                                                         }, ref) {
  const defaultFormData = useMemo(() => ({
    default: '',
    games: {}
  }), []);
  const gameList = useSelector(partnerConfigSelectors.getGameList);
  const [override, setOverride] = useState({});
  const [formData, setFormData] = useState(defaultFormData);

  const saveFunction = useCallback(() => {
    const data = {};
    const rtpData = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_DISPLAY_RTP])) {
      if (formData.default) rtpData.default = formData.default;

      if (Object.keys(override).length) {
        Object.keys(override).forEach(key => {
          if (!rtpData.games && override[key]) rtpData.games = {};
          if (override[key]) rtpData.games[key] = override[key]
        });
      }

      if (Object.keys(rtpData).length) data.displayRtp = rtpData;
    }
    return data;
  }, [selectedCasino, overriddenBlocks, formData, override]);

  const setRtpData = useCallback((data) => {
    setFormData({...defaultFormData, ...data});
    setOverride(data?.games || {});
  }, [defaultFormData, setFormData, setOverride]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_DISPLAY_RTP]: saveFunction,
    }));
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!config) return setRtpData({});
    const displayRtp = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_DISPLAY_RTP);
    setRtpData(displayRtp);
  }, [setRtpData, config, casinoConfig, getConfigOrCasinoConfigProperty]);

  const changeDefault = (e) => {
    setFormData(prev => ({...prev, default: e.target.value}));
  };

  const overrideGameRtp = (e) => {
    setOverride(prev => ({...prev, [e.target.dataset.game]: e.target.value}));
  };

  const toggleOverrideRtp = (game) => () => {
    canChangeBlock && setOverride(prev => {
      if (Object.keys(prev).includes(game)) {
        const entries = Object.entries(prev);
        return Object.fromEntries(entries.filter(el => el[0] !== game));
      } else {
        return {...prev, [game]: defaultFormData.default}
      }
    });
  };

  const checkOverride = () => {
    const fieldData = !overriddenBlocks[ePartnerConfigFieldName.EPCFN_DISPLAY_RTP] ? config && config[ePartnerConfigFieldName.EPCFN_DISPLAY_RTP] : getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_DISPLAY_RTP);
    setRtpData(fieldData);
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_DISPLAY_RTP]: !prev[ePartnerConfigFieldName.EPCFN_DISPLAY_RTP]
    }));
  };

  const reset = () => {
    const displayRtp = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_DISPLAY_RTP);
    setRtpData(displayRtp);
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      {!!selectedCasino && canOverride &&
        <div className={'partners_config_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_DISPLAY_RTP]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_DISPLAY_RTP}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        </div>}
      <span className={'partners_config_body_form_box_title'}>Display RTP</span>
      <div className={'input_form'}>
        <span className={'input_form_title'}>Default</span>
        <input type={'text'} disabled={!canChangeBlock}
               value={formData.default}
               onChange={changeDefault}
        />
        <Info info={''}/>
      </div>

      <DropdownLayout title={'Games'}>
        <div className={'dropdown_body'}>
          {gameList && gameList.map(game => {
              const overridden = Object.keys(override).includes(game.bundle);

              return <div className={'input_form input_form_grid_3_label_left'} key={game.bundle}>
                <AddCheckboxLayout title={'override'} setChecked={toggleOverrideRtp(game.bundle)}
                                   checked={overridden}
                                   checkboxId={`${game.bundle}_rtp_override_checkbox`}>
                  <span>{game.title}</span>
                  <input type={'text'} disabled={!overridden || !canChangeBlock}
                         value={overridden ? override[game.bundle] : (formData.games[game.bundle] || '')}
                         onChange={overrideGameRtp}
                         data-game={game.bundle}
                  />
                </AddCheckboxLayout>
              </div>
            }
          )}
        </div>
      </DropdownLayout>
    </div>
  );
}));
