import React, { useEffect, useState } from 'react';

export default React.memo(function RangeFilter({ title, fieldName, callback, value }) {
  const [fields, setFields] = useState({
    min: '',
    max: '',
  });

  useEffect(() => {
    setFields(value);
  }, [value]);

  const onChange = (e) => {
    setFields({
      ...fields,
      [e.currentTarget.dataset.field]: e.currentTarget.value
    })
  };

  const onBlur = () => {
    callback(prev => ({
      ...prev,
      [fieldName]: fields
    }))
  };

  return (
    <div className={'filter'}>
      <span className={'filter_title'}>{title}</span>
      <div className={'filter_inputs'}>
        <input value={fields.min} onChange={onChange} data-field={'min'} onBlur={onBlur} autoComplete={'new-password'}/>
        <input value={fields.max} onChange={onChange} data-field={'max'} onBlur={onBlur} autoComplete={'new-password'}/>
      </div>
    </div>
  );
})
