import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import eOperatorsConfigsFieldName from '../eOperatorsConfigsFieldName';

export default React.memo(forwardRef(function RoundDuration({
                                                              configForView,
                                                              canChangeBlock,
                                                              canOverride,
                                                              setSaveFunctionPool,
                                                              overriddenBlocks,
                                                              toggleOverriddenField,
                                                              setValueToConfigForView
                                                            }, ref) {
  const defaultValue = '';
  const [roundDuration, setRoundDuration] = useState(defaultValue);

  const saveFunction = useCallback(() => {
    let data = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] === configForView.type) {
        data[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] = parseInt(roundDuration) || roundDuration || 0;
    }

    return data;
  }, [overriddenBlocks, roundDuration, configForView.type, configForView.isGlobalConfig]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] : defaultValue;
    setRoundDuration(value);
  }, [configForView.config, setRoundDuration, defaultValue]);

  const changeInterval = useCallback((e) => {
    setRoundDuration(e.target.value);
  }, [setRoundDuration]);

  const onBlurText = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION, e.target.value);
  }, [setValueToConfigForView, configForView.type]);

  const checkOverride = useCallback(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] : defaultValue;
    setRoundDuration(value);
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION);
  }, [setRoundDuration, toggleOverriddenField, configForView, defaultValue]);

  const reset = useCallback(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] : defaultValue;
    setRoundDuration(value);
  }, [configForView.config, defaultValue, setRoundDuration]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Round duration</span>
        <div className={'operators_configs_override'}>
        <input type={'number'} value={roundDuration} onChange={changeInterval} onBlur={onBlurText}
               disabled={!canChangeBlock}/>
          <Info info={''}/>
          {!configForView.isGlobalConfig && canOverride &&
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION]}
          />
        }
        </div>
      </div>
    </div>
  );
}));
