import React, { useEffect, useState } from 'react';

export default React.memo(function TextFilter({ title, fieldName, callback, value, number }) {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const onChange = (e) => {
    setInputValue((number && e.currentTarget.value) ? Number(e.currentTarget.value) : e.currentTarget.value);
  };

  const onBlur = () => {
    callback(prev => ({
      ...prev,
      [fieldName]: number ? inputValue : inputValue.replaceAll(' ', '')
    }));
  };

  return (
    <div className={'filter'}>
      <span className={'filter_title'}>{title}</span>
      <div className={'filter_inputs'}>
        <input value={inputValue} onChange={onChange} onBlur={onBlur} type={number ? 'number' : 'text'}
               autoComplete={'new-password'} />
      </div>
    </div>
  );
})
