import React from 'react';
import NoData from '../noDataPlaceholder';
import { useHistory } from 'react-router-dom';

export default React.memo(function NotFound() {
  const history = useHistory();

  const back = () => {
    history.goBack();
  };

  return (
    <div className={'page centered column'}>
      <NoData />
      <span className={'color_grey'} style={{ fontSize: '3rem' }}>
          404
        </span>
      <button className={'button'} onClick={back}>
        Back
      </button>
    </div>
  );
})
