import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import ePartnerConfigFieldName from '../ePartnerConfigFieldName';

export default React.memo(forwardRef(function Session({
                                                        config,
                                                        canChangeBlock,
                                                        canOverride,
                                                        setSaveFunctionPool,
                                                        selectedCasino,
                                                        overriddenBlocks,
                                                        setOverriddenBlocks,
                                                        getConfigOrCasinoConfigProperty
                                                      }, ref) {
  const defaultValues = useMemo(() => ({
    checkStatus: false,
    checkStatusInterval: 300,
  }), []);
  const [formData, setFormData] = useState(defaultValues);

  const saveFunction = useCallback(() => {
    let data = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_SESSION])) {
      data[ePartnerConfigFieldName.EPCFN_SESSION] = {
        checkStatus: formData.checkStatus,
      };

      if (data[ePartnerConfigFieldName.EPCFN_SESSION].checkStatus) {
        data[ePartnerConfigFieldName.EPCFN_SESSION].checkStatusInterval = parseInt(formData.checkStatusInterval) || defaultValues.checkStatusInterval;
      }
    }

    return data;
  }, [selectedCasino, overriddenBlocks, formData, defaultValues]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_SESSION]: saveFunction,
    }))
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!config) return;
    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_SESSION);
    setFormData({
      checkStatus: configData && configData.hasOwnProperty('checkStatus') ? configData.checkStatus : defaultValues.checkStatus,
      checkStatusInterval: configData && configData.hasOwnProperty('checkStatusInterval') ? configData.checkStatusInterval : defaultValues.checkStatusInterval,
    });
  }, [config, setFormData, defaultValues, getConfigOrCasinoConfigProperty]);

  const toggleCheckStatus = useCallback((value) => {
    setFormData(prev => ({...prev, checkStatus: value}));
  }, [setFormData]);

  const changeInterval = useCallback((e) => {
    setFormData(prev => ({...prev, checkStatusInterval: e.target.value}));
  }, [setFormData]);

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_SESSION]: !prev[ePartnerConfigFieldName.EPCFN_SESSION],
    }));

    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_SESSION);
    setFormData({
      checkStatus: configData && configData.hasOwnProperty('checkStatus') ? configData.checkStatus : defaultValues.checkStatus,
      checkStatusInterval: configData && configData.hasOwnProperty('checkStatusInterval') ? configData.checkStatusInterval : defaultValues.checkStatusInterval,
    });
  };

  const reset = () => {
    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_SESSION);
    setFormData({
      checkStatus: configData && configData.hasOwnProperty('checkStatus') ? configData.checkStatus : defaultValues.checkStatus,
      checkStatusInterval: configData && configData.hasOwnProperty('checkStatusInterval') ? configData.checkStatusInterval : defaultValues.checkStatusInterval,
    });
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      {!!selectedCasino && canOverride &&
        <div className={'partners_config_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_SESSION]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_SESSION}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        </div>}

      <span className={'partners_config_body_form_box_title'}>Session</span>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Check status</span>
        <Checkbox checked={!!formData.checkStatus} setChecked={toggleCheckStatus}
                  id={`${ePartnerConfigFieldName.EPCFN_SESSION}_status_check`} disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Interval</span>
        <input type={'number'} value={formData.checkStatusInterval} onChange={changeInterval}
               disabled={!canChangeBlock || !formData.checkStatus}/>
        <Info info={''}/>
      </div>
    </div>
  );
}));
