import React, {useContext, useEffect, useMemo, useState} from "react";
import {useUrlParamsContext} from "./urlParamsContext";
import {managerRest} from "../index";

const openRouteContext = React.createContext(null);

const useOpenRouteContextModel = () => {
  const [sessionParams, setSessionParams] = useState(null);
  const [ isFetching, setFetching ] = useState(true);
  const [ urlParams, setUrlParams ] = useUrlParamsContext();

  useEffect(() => {
    if (!urlParams.hash) return;

    let mounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getSessionParams = async () => {
      setFetching(true);
      const params = await managerRest.getSessionParams(urlParams.hash, { signal });
      if (!mounted) return;

      setSessionParams(params);
      setFetching(false);
    };

    getSessionParams();

    return () => {
      mounted = false;
      abortController.abort();
    }
  }, [urlParams.hash]);
  
  return useMemo(() => ({
    urlParams,
    setUrlParams,
    sessionParams,
    isFetching,
  }), [urlParams, setUrlParams, sessionParams, isFetching]);
}

export default React.memo(function ({children}) {
  const context = useOpenRouteContextModel();
  
  return <openRouteContext.Provider value={context}>
    {children}
  </openRouteContext.Provider>
});

export const useOpenRouteContext = () => {
  return useContext(openRouteContext);
}