import ReactDOM from 'react-dom';
import { useMemo, useRef } from 'react';
import { useTriggerBounds, getTransform, supportedPositioning } from '../../utils/hooks/useTriggerBounds';
import { useClickOutside } from '../../utils/hooks/useClickOutside';
import { useScrollListener } from '../../utils/hooks/useScrollListener';

export default function ContextMenu({
                                      children,
                                      trigger,
                                      close,
                                      position = supportedPositioning.BOTTOM_LEFT,
                                      offsets,
                                      className = ''
                                    }) {
  const selfRef = useRef(null);
  const triggerBounds = useTriggerBounds(trigger, true);
  const transform = useMemo(() => getTransform(triggerBounds, position), [triggerBounds, position]);
  useClickOutside(selfRef.current, trigger, close, true);

  const scrollListener = (e) => {
    if (e.srcElement === selfRef.current) return;
    close();
  }

  useScrollListener(scrollListener, true);

  return ReactDOM.createPortal(
    <div ref={selfRef}
         className={`fixed z-[200] bg-white overflow-x-hidden overflow-y-auto shadow py-2 ${className}`}
         style={transform ? { top: offsets?.top || 0, left: offsets?.left || 0, transform } : { display: 'none' }}>
      <ul className={'flex flex-col'}>
        {children}
      </ul>
    </div>
    , document.getElementById('root'));
}

function ContextMenuItem({ children, className = '' }) {
  return (
    <li className={className}>
      {children}
    </li>
  );
}

ContextMenu.Item = ContextMenuItem;
ContextMenu.positions = supportedPositioning;
