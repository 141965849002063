import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import eOperatorsConfigsFieldName from '../eOperatorsConfigsFieldName';

export default React.memo(forwardRef(function Rtp({
                                                    configForView,
                                                    canChangeBlock,
                                                    canOverride,
                                                    setSaveFunctionPool,
                                                    overriddenBlocks,
                                                    toggleOverriddenField,
                                                    setValueToConfigForView
                                                  }, ref) {
  const defaultFormData = useMemo(() => 'default', []);
  const [value, setValue] = useState(defaultFormData);

  const saveFunction = useCallback(() => {
    const data = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP] === configForView.type) {
      data.customRtp = {
        default: parseInt(value) || value || defaultFormData
      };
    }
    return data;
  }, [overriddenBlocks, value, configForView.type, configForView.isGlobalConfig]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP] : { default: defaultFormData };
    setValue(value?.default);
    }, [configForView.config, setValue, defaultFormData]);

  const changeDefault = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP, { default: e.target.value });
  }, [setValueToConfigForView, configForView.type]);

  const checkOverride = useCallback(() => {
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP);
  }, [toggleOverriddenField, configForView]);


  const reset = useCallback(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP] : { default: defaultFormData };
    setValue(value?.default);
  }, [configForView.config, setValue, defaultFormData]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>
      <div className={'input_form'}>
      <span className={'operators_configs_body_form_box_title'}>Custom RTP</span>
        <div className={'operators_configs_override'}>
          <select value={value} onChange={changeDefault} disabled={!canChangeBlock}>
            <option value={'default'}>default</option>
            <option value={75}>75</option>
            <option value={80}>80</option>
            <option value={85}>85</option>
            <option value={90}>90</option>
            <option value={94}>94</option>
            <option value={105}>105</option>
          </select>
          <Info info={''}/>
          {!configForView.isGlobalConfig && canOverride &&
            <AddCheckboxLayout title={'override'}
                               checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP] === configForView.type}
                               checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP}_override_checkbox`}
                               setChecked={checkOverride}
                               disabled={!canOverride}
                               additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP]}

            />}
        </div>
      </div>
    </div>
  );
}));
