export function checkIsNumber(num) {
  return typeof num === 'number' && !isNaN(num);
}

export function checkIsFloat(num) {
  return checkIsNumber(num) && String(num).indexOf('.') > -1;
}

export function checkIsNonNegativeNumber(num) {
  return checkIsNumber(num) && (num * 1) >= 0
}

export function checkIsArrayOfNonNegativeNumbers(arr) {
  return arr.every(num => checkIsNonNegativeNumber(num));
}
