import React from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand_arrow.svg';

export default function SidebarLayout({ children, isOpen, open, close, className = '' }) {
  return ReactDOM.createPortal(
    <div className={`fixed inset-0 pointer-events-none z-50 ${className}`}>
      <div className={`absolute inset-0 transition-colors ${isOpen ? 'bg-black/20 pointer-events-auto z-10' : 'pointer-events-none'}`}
           onClick={close} />
      <aside className={`absolute top-0 right-0 z-20 h-screen bg-white shadow p-5 pointer-events-auto transition-transform duration-300 ${isOpen ? 'translate-x-0' : 'translate-x-[calc(100%-theme(space.3))]'}`}>
        <button onClick={isOpen ? close : open} className={'absolute top-5 left-0 -translate-x-1/2 bg-amber-200 p-2 flex items-center justify-center rounded-full shadow'}>
          <ExpandIcon className={`size-4 rotate-90 [&>path]:fill-black transition-transform ${isOpen ? '-scale-100' : ''}`} />
        </button>
        <div className={'h-full flex flex-col'}>
          {children}
        </div>
      </aside>
    </div>,
    document.getElementById('root')
  )
}
