import React from 'react';
import Checkbox from './checkbox';
import ePartnerConfigFieldName from '../operatorsConfigs/eOperatorsConfigsFieldName';

export default React.memo(function AddCheckboxLayout({ title, titlePosition = 'right', childrenPosition = 'left', setChecked, checked, checkboxId, children, disabled, additionalInfo = ''  }) {
  return (
    <>
      {childrenPosition === 'left' && children}
      <div className={`add_checkbox_layout add_checkbox_layout_${childrenPosition}`}>
        {additionalInfo && titlePosition === 'left' && <span className={'add_checkbox_layout_additional_info'}>{additionalInfo}</span>}
        {titlePosition === 'left' && <span className={'add_checkbox_layout_title'}>{title}</span>}
        <Checkbox setChecked={setChecked} checked={checked} id={checkboxId} disabled={disabled}/>
        {titlePosition === 'right' && <span className={'add_checkbox_layout_title'}>{title}</span>}
        {additionalInfo && titlePosition === 'right' && <span className={'add_checkbox_layout_additional_info'}>{additionalInfo}</span>}
      </div>
      {childrenPosition === 'right' && children}
    </>
  );
})
