export const globalLoadingFetchTypes = {
  AUTH: 'AUTH',
  LOGIN: 'LOGIN',
  ROUNDS: 'ROUNDS',
  OPTIONS: 'OPTIONS',
  OPERATORS: 'OPERATORS',
  CASINO: 'CASINO',
  PARTNER_CONFIG: 'PARTNER_CONFIG',
  DOWNLOAD: 'DOWNLOAD'
};
