import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import eOperatorsConfigsFieldName from '../eOperatorsConfigsFieldName';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../redux/app/selectors';

export default React.memo(forwardRef(function CertificationName({
                                                                  configForView,
                                                                  canChangeBlock,
                                                                  canOverride,
                                                                  setSaveFunctionPool,
                                                                  overriddenBlocks,
                                                                  toggleOverriddenField,
                                                                  setValueToConfigForView
                                                                }, ref) {
  const defaultValue = 'default';
  const certifications = useSelector(appSelectors.getCertifications);
  const [certificationName, setCertificationName] = useState(defaultValue);

  const saveFunction = useCallback(() => {
    let data = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME] === configForView.type) {


      if (certificationName) {
        data[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME] = certificationName;
      }
    }

    return data;
  }, [overriddenBlocks, certificationName, configForView.type, configForView.isGlobalConfig]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME] : defaultValue;
    setCertificationName(value);
  }, [configForView.config, setCertificationName, defaultValue]);

  const changeInterval = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME, e.target.value);
  }, [setValueToConfigForView, configForView.type]);

  const checkOverride = useCallback(() => {
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME);
  }, [toggleOverriddenField, configForView.type]);

  const reset = useCallback(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME] : defaultValue;
    setCertificationName(value);
  }, [configForView.config, defaultValue, setCertificationName]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Certification name</span>

        <div className={'operators_configs_override'}>
          <select value={certificationName} onChange={changeInterval} disabled={!canChangeBlock}>
            {certifications.map(({ title, name }) =>
              <option key={name} value={name}>{title}</option>
            )}
          </select>
          <Info info={''}/>
          {!configForView.isGlobalConfig && canOverride &&
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME]}
          />
        }
        </div>
      </div>
    </div>
  );
}));
