import React from 'react';
import BaseModal from './baseModal';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '../../redux/modals/selectors';
import { modalTypes } from '../../redux/modals/modalTypes';
import { modalActions } from '../../redux/modals/reducer';

export default React.memo(function ErrorModal() {
  const modal = useSelector(getModalData(modalTypes.ERROR));
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(modalActions.closeModal(modalTypes.ERROR));
  };

  const message = modal.data?.[0];

  return (
    <BaseModal visible={modal.visible} onClose={onSubmit} showCloseButton showOnTop>
      <div className={'modal'}>
        <div className={'modal_header'}>
          ERROR
        </div>

        <div className={'modal_body'}>
          <div className={'modal_body_message'}>
            { message }
          </div>
        </div>

        <div className={'modal_controls'}>
          <span onClick={onSubmit} className={'button'}>Ok</span>
        </div>
      </div>
    </BaseModal>
  );
})
