import React, { useCallback, useEffect, useRef, useState } from 'react';
import Filters from './filters';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../redux/app/selectors';
import { userSelectors } from '../../redux/user/selectors';
import RoundsSection from './roundsSection';
import { managerRest } from '../../index';
import Paginator from '../paginator';

export default React.memo(function Rounds() {
  const rounds = useSelector(appSelectors.getRounds);
  const isAuth = useSelector(userSelectors.getIsAuth);
  const scrollRef = useRef(null);
  const filtersRef = useRef(null);
  const [page, setPage] = useState(1);

  const getRounds = (page, filter) => {
    console.log('fetch')
    void managerRest.getRounds(page, filter);
  };

  useEffect(() => {
    scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
  }, [page]);

  useEffect(() => {
    if (!isAuth) return;
    getRounds(1, filtersRef.current.getFilter());
  }, [isAuth]);

  const onSearch = useCallback((filters) => {
    setPage(1);
    getRounds(1, filters);
  }, []);

  const onDownload = useCallback((filters) => {
    managerRest.downloadRounds(filters);
  }, []);

  const changePage = (page) => {
    setPage(page);
    getRounds(page, filtersRef.current.getFilter());
  }

  return (
    <div className={'page rounds_page'}>
      <div>
        <span className={'page_title'}>Transactions</span>
        <Filters onSearch={onSearch} onDownload={onDownload} ref={filtersRef} />
      </div>

      <div ref={scrollRef} className={'transactions_page_body'}>
        <RoundsSection title={'Operational  transactions'} rounds={rounds?.new} />
        <RoundsSection title={'Old transactions'} rounds={rounds?.old} />
      </div>

      <Paginator page={page} setPage={changePage} hasMore={rounds?.hasMore} />

    </div>
  );
})
