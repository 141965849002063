import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import DropdownRow from './dropdownRow';
import { useDispatch, useSelector } from 'react-redux';
import { appSelectors } from '../../redux/app/selectors';
import { managerRest } from '../../index';
import { appActions } from '../../redux/app/reducer';
import {createId} from "../../utils/transactionHelpers";
import LoadingIndicator from '../loading/indicator';

export default React.memo(function TransactionsDropdown({ visible, game, result, id, date, region }) {
  const txs = useSelector(appSelectors.getTxs(id, region));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef();

  useEffect(() => {
    return () => {
      if (!txs) return;
      dispatch(appActions.removeTxs(createId(id, region)));
    }
  }, [id, region, dispatch, txs]);

  const getTxs = useCallback(async () => {
    setLoading(true);
    await managerRest.getTransactions(id, region, moment(date).utc(false));
    setLoading(false);
  }, [setLoading, date, id, region]);

  useEffect(() => {
    if (!visible || txs) return;

    getTxs();
  }, [visible, getTxs, txs]);

  return (
    <div ref={dropdownRef}
         className={`transaction_dropdown ${visible ? 'transaction_dropdown_show' : 'transaction_dropdown_hide'}`}>

      {loading && visible && <div className={`transaction_dropdown transaction_dropdown_show`}>
        <span className={'transaction_dropdown_preloader'}>
         <LoadingIndicator />
       </span>
        </div>}

      {!loading && visible && <div className={`transaction_dropdown transaction_dropdown_show`}>
          {!!txs?.length && <div className={'transaction_dropdown_head'}>
            <span>id</span>
            <span>type</span>
            <span>bet amount</span>
            <span>win amount</span>
            <span>operator tx id</span>
            <span>provider tx id</span>
            <span>provider ref tx id</span>
            <span>success</span>
            <span>date</span>
            <span>info</span>
            <span>result</span>
          </div>}
          <div className={'transaction_dropdown_body'}>
            {!!txs?.length ? txs.map((tx, i) =>
              <DropdownRow key={tx.id} transaction={tx} lightUp={i % 2 === 0} game={game} resultVersion={result?.version}
                           result={result?.transactions.find(transaction => String(transaction.provider_tx) === String(tx.provider_tx))} />
            ) : <span className={'centered transaction_dropdown_body_row_light_up'}>No data</span>}
          </div>
        </div>}

    </div>
  );
})
