import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Checkbox from '../../../inputForms/checkbox';
import Info from '../../../inputForms/info';
import AddCheckboxLayout from '../../../inputForms/addCheckboxLayout';
import {useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../../redux/partnersConfig/selectors';
import SearchSelectInput from '../../../../uiKit/inputs/searchSelectInput';
import ePartnerConfigFieldName from '../../ePartnerConfigFieldName';
import BetLevels from './betLevels/betLevels';
import DropdownLayout from '../../../dropdownLayout';

export default React.memo(forwardRef(function FreeBets({
                                                         config,
                                                         canChangeBlock,
                                                         canOverride,
                                                         setSaveFunctionPool,
                                                         selectedCasino,
                                                         overriddenBlocks,
                                                         setOverriddenBlocks,
                                                         getConfigOrCasinoConfig
                                                       }, ref) {
  const rates = useSelector(partnerConfigSelectors.getRates);
  const defaultFormData = useMemo(() => ({
    isNeedFreebetIdInRequest: false,
    currency: '',
    betMaxAmount: {
      currency: '',
      value: 0
    },
    bet_levels: null,
  }), []);
  const [formData, setFormData] = useState(defaultFormData);
  const [availableBets, setAvailableBets] = useState({});
  const betLevelsRef = useRef();
  const saveFunction = useCallback(() => {
    const data = {};
    const freeBetsData = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_FREE_BETS])) {
      if (formData.betMaxAmount.currency || +formData.betMaxAmount.value) {
        freeBetsData.betMaxAmount = {
          currency: formData.betMaxAmount.currency.toLowerCase(),
          value: +formData.betMaxAmount.value
        };
      }

      if (formData.currency) {
        freeBetsData.currency = formData.currency;
      }

      let betLevels;
      const availableBetsKey = Object.keys(availableBets);
      if (availableBetsKey.length) betLevels = availableBetsKey.reduce((prev, currency) => {
        prev[currency.toLowerCase()] = availableBets[currency];
        return prev;
      }, {});

      if (betLevels) {
        freeBetsData.bet_levels = betLevels;
      }

      freeBetsData.isNeedFreebetIdInRequest = formData.isNeedFreebetIdInRequest;
      if (Object.keys(freeBetsData).length) data[ePartnerConfigFieldName.EPCFN_FREE_BETS] = freeBetsData;
    }
    return data;
  }, [formData, selectedCasino, overriddenBlocks, availableBets]);

  const setConfigDataToFormData = useCallback((overriddenBlocks) => {
    if (!config) {
      setFormData(defaultFormData);
      return;
    }

    const configOrCasinoConfig = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_FREE_BETS]);
    const freeBets = overriddenBlocks ? configOrCasinoConfig[ePartnerConfigFieldName.EPCFN_FREE_BETS] : config[ePartnerConfigFieldName.EPCFN_FREE_BETS];

    setFormData({
      ...freeBets,
      betMaxAmount: {
        value: freeBets?.betMaxAmount?.value || 0,
        currency: freeBets?.betMaxAmount?.currency?.toUpperCase() || ''
      }
    });
  }, [config, setFormData, getConfigOrCasinoConfig, defaultFormData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_FREE_BETS]: saveFunction,
    }));
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    setConfigDataToFormData(canChangeBlock)
  }, [canChangeBlock, setConfigDataToFormData]);

  const setAvailableBetsData = useCallback((config) => {
    if (!config) {
      setAvailableBets({});
      return;
    }

    const newAvailableBets = config
      ? Object.keys(config).reduce((prev, currency) => {
        prev[currency.toUpperCase()] = config[currency];
        return prev
      }, {})
      : {};

    setAvailableBets(newAvailableBets);
  }, [setAvailableBets]);

  useEffect(() => {
    if (!rates) return;
    setAvailableBetsData(formData?.bet_levels);
  }, [formData?.bet_levels, rates, setAvailableBets, setAvailableBetsData]);

  const toggleNeedRequest = useCallback((value) => {
    setFormData(prev => ({...prev, isNeedFreebetIdInRequest: value}));
  }, [setFormData]);

  const changeMaxBet = (e) => {
    setFormData(prev => ({...prev, betMaxAmount: {...prev.betMaxAmount, value: e.target.value}}));
  };

  const changeMaxBetCurrency = (e) => {
    setFormData(prev => ({
      ...prev,
      betMaxAmount: {...prev.betMaxAmount, currency: e.target.value}
    }));
  };

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_FREE_BETS]: !prev[ePartnerConfigFieldName.EPCFN_FREE_BETS],
    }));
    setConfigDataToFormData(!overriddenBlocks[ePartnerConfigFieldName.EPCFN_FREE_BETS])
  };

  const reset = () => {
    setConfigDataToFormData(canChangeBlock);
    setAvailableBetsData(formData?.bet_levels);
    betLevelsRef.current?.reset();
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      {!!selectedCasino && canOverride &&
        <div className={'partners_config_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_FREE_BETS]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_FREE_BETS}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        </div>}
      <span className={'partners_config_body_form_box_title'}>Freebets</span>
      <div className={'input_form'}>
        <span className={'input_form_title'}>Need freebet id in request</span>
        <Checkbox checked={!!formData.isNeedFreebetIdInRequest} setChecked={toggleNeedRequest}
                  id={'is_need_freebet_id_in_request'} disabled={!canChangeBlock}/>
        <Info info={'For direct integrations only'} visibility={formData.isNeedFreebetIdInRequest}/>
      </div>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Max bet amount</span>
        <input type={'number'} value={formData.betMaxAmount ? formData.betMaxAmount.value : ''} onChange={changeMaxBet}
               disabled={!canChangeBlock}/>
        <SearchSelectInput
          selected={formData.betMaxAmount && formData.betMaxAmount.currency ? formData.betMaxAmount.currency.toUpperCase() : ''}
          options={rates ? Object.keys(rates) : []} inputProps={{className: 'input_small_width'}}
          onSelect={changeMaxBetCurrency}
          disabled={!canChangeBlock}/>

        <Info info={''}/>
      </div>
      <DropdownLayout title={'Bet Levels'}>
        <div className={'dropdown_body'}>
          <BetLevels ref={betLevelsRef} config={config} canChangeBlock={canChangeBlock} betLevelsData={formData.bet_levels}
                     availableBets={availableBets} setAvailableBets={setAvailableBets}/>
        </div>
      </DropdownLayout>
    </div>
  );
}));
