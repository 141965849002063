import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import DropdownLayout from '../../dropdownLayout';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import {useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../redux/partnersConfig/selectors';
import SearchSelectInput from '../../../uiKit/inputs/searchSelectInput';
import ePartnerConfigFieldName from '../ePartnerConfigFieldName';

export default React.memo(forwardRef(function WinLimits({
                                                          config,
                                                          canChangeBlock,
                                                          canOverride,
                                                          setSaveFunctionPool,
                                                          selectedCasino,
                                                          overriddenBlocks,
                                                          setOverriddenBlocks,
                                                          getConfigOrCasinoConfig
                                                        }, ref) {
  const rates = useSelector(partnerConfigSelectors.getRates);
  const defaultFormData = useMemo(() => ({
    showMaxWinOnStart: false,
    default: {
      currency: '',
      value: 0
    },
    currencies: {}
  }), []);
  const [override, setOverride] = useState({});
  const [formData, setFormData] = useState(defaultFormData);

  const saveFunction = useCallback(() => {
    const data = {};
    const maxWinLimitData = {};
    if ((!selectedCasino) || (selectedCasino && (overriddenBlocks[ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT] || overriddenBlocks[ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START]))) {
      if (formData.default.currency || +formData.default.value) {
        maxWinLimitData.default = {currency: formData.default.currency.toLowerCase(), value: +formData.default.value};
      }

      if (override && Object.keys(override).length) {
        maxWinLimitData.currencies = override;
      }

      data.showMaxWinOnStart = formData.showMaxWinOnStart;
      if (Object.keys(maxWinLimitData).length) data.maxWinLimit = maxWinLimitData;
    }
    return data;
  }, [formData, override, selectedCasino, overriddenBlocks]);

  const updateCurrencies = useCallback((value, defaultCurrency) => {
    let currencies = {};
    if (defaultCurrency && value >= 0) {
      currencies = rates && Object.keys(rates).reduce((prev, currency) => {
        prev[currency.toLowerCase()] = value * rates[currency] / rates[defaultCurrency];
        return prev;
      }, {})
    }

    setFormData(prev => ({...prev, currencies}))
  }, [setFormData, rates]);

  const setConfigDataToFormData = useCallback((overriddenBlocks) => {
    if (!config) {
      setFormData(defaultFormData);
      setOverride({});
      return;
    }

    const configOrCasinoConfig = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT, ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START]);
    const maxWinLimit = overriddenBlocks ? configOrCasinoConfig[ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT] : config[ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT];
    let showMaxWinOnStart = overriddenBlocks ? configOrCasinoConfig[ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START] : config[ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START];
    showMaxWinOnStart = showMaxWinOnStart === undefined ? false : showMaxWinOnStart;

    setFormData({
      [ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START]: showMaxWinOnStart,
      ...maxWinLimit,
      default: {value: maxWinLimit?.default?.value || 0, currency: maxWinLimit?.default?.currency.toUpperCase() || ''}
    });
    if (maxWinLimit?.default?.value >= 0 && maxWinLimit?.default?.currency) updateCurrencies(maxWinLimit?.default?.value, maxWinLimit?.default?.currency.toUpperCase());
    setOverride((maxWinLimit && maxWinLimit.currencies) || {});
  }, [config, setFormData, getConfigOrCasinoConfig, setOverride, updateCurrencies, defaultFormData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT]: saveFunction,
    }));
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    setConfigDataToFormData(canChangeBlock)
  }, [canChangeBlock, setConfigDataToFormData]);

  const toggleShowMaxWinOnStart = useCallback((value) => {
    setFormData(prev => ({...prev, showMaxWinOnStart: value}));
  }, [setFormData]);

  const changeMaxWinLimit = (e) => {
    setFormData(prev => ({...prev, default: {...prev.default, value: e.target.value}}));
    updateCurrencies(e.target.value, formData.default?.currency);
  };

  const changeLimitCurrency = (e) => {
    setFormData(prev => ({
      ...prev,
      default: {...prev.default, currency: e.target.value}
    }));
    updateCurrencies(formData.default?.value, e.target.value);
  };

  const overrideMaxWinByCurrency = (e) => {
    setOverride(prev => ({...prev, [e.target.dataset.currency]: e.target.value}));
  };

  const blurMaxWinByCurrency = (e) => {
    setOverride(prev => ({
      ...prev,
      [e.target.dataset.currency]: +prev[e.target.dataset.currency] || 0
    }));
  };

  const toggleOverrideCurrency = (currency) => () => {
    setOverride(prev => {
      if (Object.keys(prev).includes(currency)) {
        const entries = Object.entries(prev);
        return Object.fromEntries(entries.filter(el => el[0] !== currency));
      } else {
        return {...prev, [currency]: (formData.currencies && formData.currencies[currency]) || 0}
      }
    });
  };

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT]: !prev[ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT],
      [ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START]: !prev[ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START]
    }));
    setConfigDataToFormData(!overriddenBlocks[ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT] && !overriddenBlocks[ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START])
  };

  const reset = () => {
    setConfigDataToFormData(canChangeBlock);
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      {!!selectedCasino && canOverride &&
        <div className={'partners_config_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT || !!overriddenBlocks[ePartnerConfigFieldName.EPCFN_SHOW_MAX_WIN_ON_START]]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_MAX_WIN_LIMIT}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        </div>}
      <span className={'partners_config_body_form_box_title'}>Win limits</span>
      <div className={'input_form'}>
        <span className={'input_form_title'}>Show max win on start</span>
        <Checkbox checked={!!formData.showMaxWinOnStart} setChecked={toggleShowMaxWinOnStart}
                  id={'show_max_win_on_start'} disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Max win limit</span>
        <input type={'number'} value={formData.default ? formData.default.value : ''} onChange={changeMaxWinLimit}
               disabled={!canChangeBlock}/>
        <SearchSelectInput
          selected={formData.default && formData.default.currency ? formData.default.currency.toUpperCase() : ''}
          options={rates ? Object.keys(rates) : []} inputProps={{className: 'input_small_width'}}
          onSelect={changeLimitCurrency}
          disabled={!canChangeBlock}/>
        {/*<select value={formData.default.currency.toUpperCase()} onChange={changeLimitCurrency}>*/}
        {/*  <option value={''} />*/}
        {/*  {rates && Object.keys(rates).map(currency =>*/}
        {/*    <option key={currency} value={currency}>*/}
        {/*      {currency}*/}
        {/*    </option>)}*/}
        {/*</select>*/}

        <Info info={''}/>
      </div>

      <DropdownLayout title={'Currencies'}>
        <div className={'dropdown_body'}>
          {rates && Object.keys(rates).map(currency => {
              currency = currency.toLowerCase();
              const overrided = Object.keys(override).includes(currency);
              return <div className={'input_form input_form_grid_3_label_right'} key={currency}>
                <AddCheckboxLayout title={'override'} setChecked={toggleOverrideCurrency(currency)}
                                   checked={overrided}
                                   checkboxId={`${currency}_max_win_override_checkbox`}>
                  <input type={'number'} disabled={!overrided || !canChangeBlock}
                         value={overrided ? override[currency] : formData.currencies && formData.currencies[currency] ? formData.currencies[currency] : ''}
                         onChange={overrideMaxWinByCurrency} data-currency={currency}
                         onBlur={blurMaxWinByCurrency}
                  />
                  <span className={'input_form_subtitle'}>{currency.toUpperCase()}</span>
                </AddCheckboxLayout>
              </div>
            }
          )}
        </div>
      </DropdownLayout>
    </div>
  );
}));
