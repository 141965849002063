import React from 'react';
import { NavLink } from 'react-router-dom';
import { modalActions } from '../../redux/modals/reducer';
import { modalTypes } from '../../redux/modals/modalTypes';
import { useDispatch } from 'react-redux';
import { RolesCheckLayout, SuperAdminCheckLayout } from '../../utils/rolesCheck';
import eUserRoles from '../../enums/eUserRoles';
import { eStorageKeys } from '../../enums/eStorageKeys';

export default React.memo(function MainNav() {
  const dispatch = useDispatch();

  const openUserManager = () => {
    dispatch(modalActions.showModal(modalTypes.USER_MANAGER));
  };

  const goToAnalytics = () => {
    window.location.assign('/analytics');
  };

  const handleLogout = () => {
    localStorage.removeItem(eStorageKeys.ESTK_AUTH);
    window.location.reload();
  }

  return (
    <div className={'super_admin_menu'}>
      <RolesCheckLayout Roles={[eUserRoles.EUR_ADMIN, eUserRoles.EUR_SUPPORT, eUserRoles.EUR_INTEGRATION_MANAGER]}>
        <NavLink to={'/'} exact activeClassName={'!text-blue-500'}
                 className={'super_admin_menu_button hover:text-blue-300'}>Rounds</NavLink>
        <NavLink to={'/partners-config'} activeClassName={'!text-blue-500'}
                 className={'super_admin_menu_button hover:text-blue-300'}>Partner config</NavLink>
        <NavLink to={'/operators-configs'} activeClassName={'!text-blue-500'}
                 className={'super_admin_menu_button hover:text-blue-300'}>Operators Configs</NavLink>
      </RolesCheckLayout>

      <RolesCheckLayout
        Roles={[eUserRoles.EUR_ADMIN, eUserRoles.EUR_PARTNER, eUserRoles.EUR_SUPPORT, eUserRoles.EUR_GAME_CO_OWNER, eUserRoles.EUR_INTEGRATION_MANAGER, eUserRoles.EUR_HEAD_OF_SLOTS]}>
        <button onClick={goToAnalytics} className={'super_admin_menu_button hover:text-blue-300'}>Analytics</button>
      </RolesCheckLayout>

      <SuperAdminCheckLayout>
        <button onClick={openUserManager}
                className={'super_admin_menu_button hover:text-blue-300'}>
          Manage users
        </button>
      </SuperAdminCheckLayout>

      <button onClick={handleLogout}
        className={'mt-10 px-2 py-1.5 text-white/80 border border-white/80 rounded-xl hover:text-white hover:border-white'}
        style={{ borderStyle: 'solid' }}>
        Logout
      </button>
    </div>
  );
});
