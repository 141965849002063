import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';

export default React.memo(forwardRef(function SingleStringField({
                                                              config,
                                                              canChangeBlock,
                                                              canOverride,
                                                              setSaveFunctionPool,
                                                              selectedCasino,
                                                              overriddenBlocks,
                                                              setOverriddenBlocks,
                                                              getConfigOrCasinoConfigProperty,
                                                              fieldName,
                                                              title
                                                            }, ref) {
  const defaultValue = '';
  const [textData, setTextData] = useState(defaultValue);

  const saveFunction = useCallback(() => {
    let data = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[fieldName])) {


      if (textData) {
        data[fieldName] = textData;
      }
    }

    return data;
  }, [selectedCasino, overriddenBlocks, textData, defaultValue]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [fieldName]: saveFunction,
    }))
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!config) return;
    const configData = getConfigOrCasinoConfigProperty(fieldName);
    setTextData(configData || configData === '' ? configData : defaultValue);
  }, [config, setTextData, defaultValue, getConfigOrCasinoConfigProperty]);

  const changeInterval = useCallback((e) => {
    setTextData(e.target.value);
  }, [setTextData]);

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));

    const configData = getConfigOrCasinoConfigProperty(fieldName);
    setTextData(configData || configData === '' ? configData : defaultValue);
  };

  const reset = () => {
    const configData = getConfigOrCasinoConfigProperty(fieldName);
    setTextData(configData || configData === '' ? configData : defaultValue);
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>

      <div className={'input_form'}>
        <span className={'input_form_title'}>{title}</span>
        <input type={'text'} className={'medium_input'} value={textData} onChange={changeInterval}
               disabled={!canChangeBlock}/>
        {!!selectedCasino && canOverride &&
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[fieldName]}
                             checkboxId={`${fieldName}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        }
        <Info info={''}/>
      </div>
    </div>
  );
}));
