import React, { useState } from 'react';
import { ReactComponent as Arrow } from '../../assets/icons/triangle_arrow.svg';

export default React.memo(function DropdownLayout({ title, children, onClick = () => {}, ...props }) {
  const [visible, setVisible] = useState(false);

  const toggleVisibility = () => {
    setVisible(prev => !prev);
    onClick();
  };

  return (
    <div className={'dropdown_layout'} {...props}>
      <span className={`dropdown_layout_title ${visible ? 'dropdown_layout_title_open' : ''}`}
            onClick={toggleVisibility}>
        <Arrow />
        {title}
      </span>
      {visible && children}
    </div>
  );
})
