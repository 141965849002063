import React from 'react';
import Block from './block';
import NoDataPlaceholder from '../noDataPlaceholder';

export default React.memo(function RoundsSection({ title, rounds }) {
  return !rounds ? null : (
    <div>
      <span className={'page_title'}>{title}</span>
      <div className={'transactions'}>

        {rounds.length ? rounds.map(round =>
          <Block key={round.id} round={round} />
        ) : <NoDataPlaceholder /> }

      </div>
    </div>
  );
})
