import React, { useCallback, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { modalTypes } from '../../../redux/modals/modalTypes';
import { modalActions } from '../../../redux/modals/reducer';
import { managerRest } from '../../../index';
import useUserManagerModel, { defaultPermissions } from './model';
import SearchSelectFilter from '../../filters/searchSelectFilter';

const initialForm = {
  username: '',
  role: '',
  permissions: defaultPermissions
};

export default React.memo(forwardRef(function EditUser({ onDeleteUser }, ref) {
  const dispatch = useDispatch();
  const {
    roles,
    users,
    setUsers,
    formData,
    setFormData,
    getUsers,
    changeRole,
    selectUser,
    getInputByKey,
  } = useUserManagerModel(initialForm);

  const reset = () => {
    setFormData(initialForm);
    setUsers(null);
  };

  useEffect(() => {
    if (users) return;
    void getUsers();
  }, [users, getUsers]);

  const submit = useCallback(async () => {
    if (formData.permissions.operators.length && formData.permissions.operators.some(({ id }) => id === '')) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Operator should be specified.'));
      return false;
    }

    const requestData = {
      ...formData,
      role: Number(formData.role),
    };

    return await managerRest.updateUser(requestData);
  }, [dispatch, formData]);

  const deleteUser = useCallback(async () => {
    const requestData = {
      ...formData,
    };
    return await managerRest.deleteUser(requestData);
  },[formData]);


  const getActiveUserName = useCallback(() => {
    return formData.username;
  },[formData]);

  const usernames = useMemo(() => {
    if (!users) return [];
    return users.map(({username}) => username)
  }, [users])

  useImperativeHandle(ref, () => ({
    submit,
    reset,
    deleteUser,
    getActiveUserName
  }));

  return (
    <div className={'modal_body'}>

      <div className={'form_block'}>
        <SearchSelectFilter title={'User'} fieldName={'username'} options={usernames} callback={selectUser} placeholder={'Username'}/>
      </div>

      {formData.username && <div className={'form_block'}>
        <label>Role:</label>
        <select value={formData.role} onChange={changeRole}>
          <option value={''} />
          {roles.length && roles.map(role =>
            <option key={role.id} value={role.id}>{role.title}</option>
          )}
        </select>
      </div>}

      {formData.username && <div className={'form_block'}>
        <label>Permissions:</label>

        <div className={'w-3/5 p-2 border border-gray-200 rounded'}>
          <div className={'form_block'}>
            <label className={'!w-full'}>Operators:</label>
            {getInputByKey('operators')}
          </div>
          <div className={'form_block'}>
            <label className={'!w-full'}>Games:</label>
            {getInputByKey('games')}
          </div>
        </div>
      </div>}

      {formData.username && <div className={'form_block'}>
        <div className="w-3/5 flex justify-end">
          <button onClick={onDeleteUser} className={'bg-red-300 text-white rounded-md px-1 py-0.5 hover:bg-red-400'}>Delete User</button>
        </div>
      </div>}

    </div>
  );
}));
