import React, { useEffect, useState } from 'react';

export default React.memo(function SelectFilter({ title, fieldName, options, callback, value }) {
  const [selectValue, setSelectValue] = useState('');

  useEffect(() => {
    setSelectValue(value);
  }, [value]);

  const onChange = (e) => {
    setSelectValue(e.currentTarget.value);
  };

  const onBlur = () => {
    callback(prev => ({
      ...prev,
      [fieldName]: selectValue
    }))
  };

  return (
    <div className={'filter'}>
      <span className={'filter_title'}>{title}</span>
      <div className={'filter_inputs'}>
        <select value={selectValue} onChange={onChange} onBlur={onBlur}>
          <option value={''}>All</option>
          {options.map(el =>
            <option key={el} value={el}>
              {el}
            </option>
          )}
        </select>
      </div>
    </div>
  );
})
