import React, { useRef, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as Diff from 'diff';
import BaseModal from './baseModal';
import { modalActions } from '../../redux/modals/reducer';
import { modalTypes } from '../../redux/modals/modalTypes';
import { getModalData } from '../../redux/modals/selectors';

export default React.memo(function JsonCompareModal() {
  const dispatch = useDispatch();
  const modal = useSelector(getModalData(modalTypes.COMPARE_JSON));
  const diff = useRef([]);
  let diffOffset = 10;

  const data = useMemo(() => modal.data || {}, [modal.data]);

  const close = useCallback(() => {
    if (data.onClose) data.onClose();
    diff.current = [];
    dispatch(modalActions.closeModal(modalTypes.COMPARE_JSON));
  }, [dispatch]);

  const callback = useCallback(() => {
    if (!data.callback || data.callback.__proto__ !== Function.prototype) {
      console.error('You should provide callback function for comparison');
      return;
    }

    data.callback();
    close();
  }, [data, close]);

  if (modal.visible && data.newJson && !diff.current.length) {

    diff.current = Diff.diffJson(data.prevJson || '', data.newJson);

    if (diff.current.length === 1 && !diff.current[0].added && !diff.current[0].removed) {
      callback();
      close();
    }

    diff.current.forEach((el, i) => {
      if (!el.hasOwnProperty('added') && !el.hasOwnProperty('removed') && el.count > diffOffset) {
        let stringsArray = el.value.split('\n');
        let start = i === 0 ? '' : stringsArray.slice(0, diffOffset).join('\n');
        let end = i === diff.current.length - 1 ? '' : stringsArray.slice(stringsArray.length - diffOffset - 1).join('\n');

        el.value = [start, <div key={i} className={'compare_json_placeholder'}>...</div>, end];
      }
    });

    diff.current = diff.current.map((part, i) => {
      return (
        <span key={i} className={`compare_json_item ${part.added ? 'compare_json_added' : part.removed ? 'compare_json_removed' : ''}`}>
            {part.value}
          </span>
      )
    });
  }

  return (
    <BaseModal visible={modal.visible} onClose={close} showCloseButton>
      <div className={'modal modal_l'}>
        <span className={'modal_header'}>
          COMPARE {data.title && data.title}
        </span>

        <div className={'modal_body compare_json'}>
          {diff.current}
        </div>

        <div className={'modal_controls'}>
          <span onClick={close} className={'button'}>Cancel</span>
          <span onClick={callback} className={'button'}>Save</span>
        </div>

      </div>
    </BaseModal>
  );
})
