import React, { useCallback, useEffect, useState } from 'react';
import BaseModal from './baseModal';
import { useSelector } from 'react-redux';
import { getModalData } from '../../redux/modals/selectors';
import { modalTypes } from '../../redux/modals/modalTypes';
import { managerRest } from '../../index';

export default React.memo(function LoginModal() {
  const modal = useSelector(getModalData(modalTypes.LOGIN));
  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
  });

  const onSubmit = useCallback(() => {
    managerRest.login(loginForm)
  }, [loginForm]);

  const onKeyDown = useCallback((e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  }, [onSubmit]);

  useEffect(() => {
    if (modal.visible) {
      document.addEventListener('keydown', onKeyDown);
    } else {
      document.removeEventListener('keydown', onKeyDown);
    }

    return() => document.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown, modal.visible]);

  const onInputChange = (e) => {
    setLoginForm({
      ...loginForm,
      [e.currentTarget.dataset.field]: e.currentTarget.value
    });
  };

  return (
    <BaseModal visible={modal.visible}>
      <div className={'modal modal_s'}>
        <div className={'modal_header'}>
          LOGIN
        </div>

        <div className={'modal_body'}>
          <div className={'form_block'}>
            <label>Username:</label>
            <input value={loginForm.username} onChange={onInputChange} data-field={'username'} type={'email'} autoComplete={'username'}/>
          </div>

          <div className={'form_block'}>
            <label>Password:</label>
            <input value={loginForm.password} onChange={onInputChange} data-field={'password'} type={'password'} autoComplete={'current-password'}/>
          </div>
        </div>

        <div className={'modal_controls'}>
          <span onClick={onSubmit} className={'button'}>Submit</span>
        </div>
      </div>
    </BaseModal>
  );
})
