import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import Checkbox from '../../../inputForms/checkbox';
import Info from '../../../inputForms/info';
import AddCheckboxLayout from '../../../inputForms/addCheckboxLayout';
import {useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../../redux/partnersConfig/selectors';
import SearchSelectInput from '../../../../uiKit/inputs/searchSelectInput';
import eOperatorsConfigsFieldName from '../../eOperatorsConfigsFieldName';
import BetLevels from './betLevels/betLevels';
import DropdownLayout from '../../../dropdownLayout';

export default React.memo(forwardRef(function FreeBets({
                                                         configForView,
                                                         canChangeBlock,
                                                         canOverride,
                                                         setSaveFunctionPool,
                                                         overriddenBlocks,
                                                         toggleOverriddenField,
                                                         setValueToConfigForView
                                                       }, ref) {
  const rates = useSelector(partnerConfigSelectors.getRates);
  const defaultFormData = useMemo(() => ({
    isNeedFreebetIdInRequest: false,
    betMaxAmount: {
      currency: '',
      value: 0
    },
    bet_levels: null,
  }), []);
  const [formData, setFormData] = useState(defaultFormData);
  const betLevelsRef = useRef();

  const saveFunction = useCallback(() => {
    const data = {};
    const freeBetsData = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_FREE_BETS] === configForView.type) {
      if (formData.betMaxAmount.currency || +formData.betMaxAmount.value) {
        freeBetsData.betMaxAmount = {
          currency: formData.betMaxAmount.currency.toLowerCase(),
          value: +formData.betMaxAmount.value
        };
      }

      if (formData.bet_levels) {
        let betLevels;
        const availableBetsKey = Object.keys(formData.bet_levels);
        if (availableBetsKey.length) betLevels = availableBetsKey.reduce((prev, currency) => {
          prev[currency.toLowerCase()] = formData.bet_levels[currency];
          return prev;
        }, {});
        freeBetsData.bet_levels = betLevels;
      }

      freeBetsData.isNeedFreebetIdInRequest = formData.isNeedFreebetIdInRequest;
      if (Object.keys(freeBetsData).length) data[eOperatorsConfigsFieldName.EPCFN_FREE_BETS] = freeBetsData;
    }
    return data;
  }, [formData, overriddenBlocks, configForView.type, configForView.isGlobalConfig]);

  const setConfigDataToFormData = useCallback(() => {
    const freeBets = configForView.config[eOperatorsConfigsFieldName.EPCFN_FREE_BETS];

    let bet_levels = freeBets?.bet_levels || defaultFormData.bet_levels;
    bet_levels = bet_levels
      ? Object.keys(bet_levels).reduce((prev, currency) => {
        prev[currency.toUpperCase()] = bet_levels[currency];
        return prev
      }, {})
      : {};

    setFormData({
      isNeedFreebetIdInRequest: freeBets?.isNeedFreebetIdInRequest || defaultFormData.isNeedFreebetIdInRequest,
      betMaxAmount: {
        value: freeBets?.betMaxAmount?.value || defaultFormData.betMaxAmount.value,
        currency: freeBets?.betMaxAmount?.currency?.toUpperCase() || defaultFormData.betMaxAmount.currency
      },
      bet_levels,
    });
  }, [configForView.config, setFormData, defaultFormData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_FREE_BETS]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    if (!rates) return;
    setConfigDataToFormData();
  }, [setConfigDataToFormData, configForView.config, rates]);

  const setAvailableBets = useCallback((value) => {
      if (typeof value === 'function') value = value(formData.bet_levels);

    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_FREE_BETS, {
      ...formData,
      bet_levels: value
    });
  }, [setValueToConfigForView, configForView.type, formData]);

  const toggleNeedRequest = useCallback((value) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_FREE_BETS, {...formData, isNeedFreebetIdInRequest: value});
  }, [setValueToConfigForView, configForView.type, formData]);

  const changeMaxBet = useCallback((e) => {
    setFormData(prev => ({...prev, betMaxAmount: {...prev.betMaxAmount, value: e.target.value}}));
  }, [setFormData]);

  const onBlurMaxBet = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_FREE_BETS, {...formData, betMaxAmount: {...formData.betMaxAmount, value: e.target.value}});
  }, [setValueToConfigForView, configForView.type, formData]);

  const changeMaxBetCurrency = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_FREE_BETS, {...formData, betMaxAmount: {...formData.betMaxAmount, currency: e.target.value.toLowerCase()}});
  }, [setValueToConfigForView, configForView.type, formData]);

  const checkOverride = useCallback(() => {
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_FREE_BETS);
  }, [toggleOverriddenField, configForView]);

  const reset = useCallback(() => {
    setConfigDataToFormData()
  }, [setConfigDataToFormData]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box free_bets'} data-disabled={!canChangeBlock}>
      {!configForView.isGlobalConfig && canOverride &&
        <div className={'operators_configs_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_FREE_BETS] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_FREE_BETS}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_FREE_BETS]}
          />
        </div>}
      <span className={'operators_configs_body_form_box_title'}>Freebets</span>
      <div className={'input_form'}>
        <span className={'input_form_title'}>Need freebet id in request</span>
        <Checkbox checked={!!formData.isNeedFreebetIdInRequest} setChecked={toggleNeedRequest}
                  id={`${configForView.type}_is_need_freebet_id_in_request`} disabled={!canChangeBlock}/>
        <Info info={'For direct integrations only'} visibility={formData.isNeedFreebetIdInRequest}/>
      </div>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Max bet amount</span>
        <input type={'number'} value={formData.betMaxAmount ? formData.betMaxAmount.value : ''} onChange={changeMaxBet} onBlur={onBlurMaxBet}
               disabled={!canChangeBlock}/>
        <SearchSelectInput
          selected={formData.betMaxAmount && formData.betMaxAmount.currency ? formData.betMaxAmount.currency.toUpperCase() : ''}
          options={rates ? Object.keys(rates) : []} inputProps={{className: 'input_small_width'}}
          onSelect={changeMaxBetCurrency}
          disabled={!canChangeBlock}/>

        <Info info={''}/>
      </div>
      <DropdownLayout title={'Bet Levels'}>
        <div className={'dropdown_body'}>
          <BetLevels ref={betLevelsRef} config={configForView} canChangeBlock={canChangeBlock} betLevelsData={formData.bet_levels}
                     availableBets={formData.bet_levels} setAvailableBets={setAvailableBets}/>
        </div>
      </DropdownLayout>
    </div>
  );
}));
