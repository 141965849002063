import React, {useCallback, useContext, useMemo} from "react";
import {withRouter} from "react-router-dom";
import * as queryString from "querystring-es3";

const urlParamsContext = React.createContext(null);

export default withRouter(React.memo(function ({history, location, children}) {

  const urlParams = useMemo(() => queryString.parse(location.search.substring(1)), [location]);

  const setUrlParams = useCallback((next) => {
    const nextParams = typeof next === 'function' ? next(urlParams) : next;

    history.push({
      search: queryString.stringify(nextParams),
    });
    }, [history, urlParams]);

  const context = useMemo(() => ([
    urlParams, setUrlParams
  ]), [urlParams, setUrlParams]);

  return <urlParamsContext.Provider value={context}>
    {children}
  </urlParamsContext.Provider>
}))

export const useUrlParamsContext = () => {
  return useContext(urlParamsContext);
}
