import {useExternalScript} from "./useExternalScript";
import {useOpenRouteContext} from "../contextProviders/openRouteContext";

const externalScriptsToApplyOperatorIds = [789];

export default function ExternalScriptsHandler({children}) {
  const {sessionParams} = useOpenRouteContext();
  useExternalScript(sessionParams?.operator_id, sessionParams?.operator_id && externalScriptsToApplyOperatorIds.includes(sessionParams.operator_id));
  
  return children;
}