import React, { useEffect } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useRoutingPermissions } from '../../utils/hooks/useRoutingPermissions';
import { useAnalyticsGrantedRoutes } from '../../utils/hooks/useAnalyticsRoutes';

const PermissionRouter = React.memo(function ({ location, children }) {
  const accessGranted = useRoutingPermissions(location);
  const availableAnalyticsRoutes = useAnalyticsGrantedRoutes();

  useEffect(() => {
    if (!accessGranted && availableAnalyticsRoutes.length) window.location.assign('/analytics');
  }, [accessGranted, availableAnalyticsRoutes]);

  if (!accessGranted && availableAnalyticsRoutes.length) {
    return null;
  } else if (!accessGranted) {
    return <Redirect to={'/404'} />
  } else return children;
});

export default withRouter(PermissionRouter);
