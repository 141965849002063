import React, { useState } from 'react';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';

export default React.memo(function Info({ info, visibility }) {
  const [infoVisible, setInfoVisible] = useState(false);

  const showInfo = () => {
    setInfoVisible(true);
  };

  const hideInfo = () => {
    setInfoVisible(false);
  };
  return (
    <span className={'input_form_info'}>
      <InfoIcon onMouseEnter={showInfo} onMouseLeave={hideInfo} />

      {(infoVisible || visibility) &&
      <div className={'input_form_info_block'}>
        <span>
          {info}
        </span>
      </div>}
    </span>
  );
})
