import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';

import Rounds from '../components/rounds';
import PartnersConfig from '../components/partnersConfig';
import OperatorsConfigs from '../components/operatorsConfigs';
import Loading from '../components/loading';
import LoginModal from '../components/modals/login';
import ConfirmModal from '../components/modals/confirm';
import ErrorModal from '../components/modals/error';
import MessageModal from '../components/modals/message';
import TransactionModal from '../components/modals/transaction';
import InfoModal from '../components/modals/info'
import UsersManagerModal from '../components/modals/users/usersManager';
import CloseRoundModal from '../components/modals/closeRound';
import CompareJsonModal from '../components/modals/compareJson';
import MainNav from '../components/mainNav';
import PermissionRouter from '../components/permissionRouters/authPermissionRouter';

import { useSelector } from 'react-redux';
import { userSelectors } from '../redux/user/selectors';
import SidebarLayout from '../uiKit/layouts/sidebarLayout';

export default function RestrictedRoute() {
  const isAuth = useSelector(userSelectors.getIsAuth);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setSidebarOpen(false);
  }, [location]);

  function openSideBar() {
    setSidebarOpen(true);
  }

  function closeSideBar() {
    setSidebarOpen(false);
  }

  return (
    <>
      {!isAuth ? React.Fragment : <div className="app">
        <SidebarLayout isOpen={sidebarOpen} open={openSideBar} close={closeSideBar} className={'[&_aside]:bg-gray-800 [&_aside>button]:!bg-[#ff4500]'}>
          <MainNav />
        </SidebarLayout>

        <PermissionRouter>
          <Switch>
            <Route exact path={'/'} render={() => <Rounds />} />
            <Route exact path={'/partners-config'} render={() => <PartnersConfig />} />
            <Route exact path={'/operators-configs'} render={() => <OperatorsConfigs />} />
          </Switch>
        </PermissionRouter>
      </div>}

      <Loading />
      <ConfirmModal />
      <ErrorModal />
      <MessageModal />
      <LoginModal />
      <CompareJsonModal />
      <UsersManagerModal />
      <CloseRoundModal />
      <TransactionModal />
      <InfoModal />
    </>
  );
}
