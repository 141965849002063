import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useBounds } from '../../utils/hooks/useWindowBounds';
import Title from '../typography/title';

export default React.memo(function SearchSelectFilter({
                                                        selectedValue,
                                                        title,
                                                        callback,
                                                        options,
                                                        onComplete,
                                                        idSuffix,
                                                        isAllAvailable,
                                                        placeholder = '',
                                                        className = '',
                                                        containerClassName = '',
                                                        inputClassName = '',
                                                        titleClassName = '',
                                                        dropdownClassName = '',
                                                        optionClassName = '',
                                                        calculateBounds,
                                                        usePortal = false,
                                                        ...props
                                                      }) {
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputInFocus, setInputInFocus] = useState(false);
  const toggleRef = useRef(null);
  const dropDownRef = useRef(null);
  const bounds = useBounds(toggleRef, dropDownRef, showDropdown, calculateBounds);

  const extendedOptions = useMemo(() => {
    return isAllAvailable ? [{
      id: -1,
      value: '',
      label: 'All',
      searchString: 'All',
      Component: options[0]?.Component
    }, ...options] : options;
  }, [options, isAllAvailable]);

  const optionsLabelsMapByValue = useMemo(() => {
    return extendedOptions.reduce((acc, option) => {
      if (!acc.has(option.value)) acc.set(option.value, []);
      acc.get(option.value).push(option.label);
      return acc;
    }, new Map());
  }, [extendedOptions]);

  useEffect(() => {
    setSearchValue(optionsLabelsMapByValue.get(selectedValue)?.join(', ') || '');
  }, [selectedValue, optionsLabelsMapByValue]);

  const hideDropdown = useCallback((e) => {
    if (e.target.closest(`#search_select_input_${idSuffix}`) || e.target.closest(`#search_select_dropdown_${idSuffix}`) || inputInFocus) return;

    setShowDropdown(false);
    setSearchValue(optionsLabelsMapByValue.get(selectedValue)?.join(', ') || '');
    onComplete && onComplete();
  }, [onComplete, setSearchValue, optionsLabelsMapByValue, selectedValue, setShowDropdown, idSuffix, inputInFocus]);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('click', hideDropdown);
    } else {
      document.removeEventListener('click', hideDropdown);
    }

    return () => {
      document.removeEventListener('click', hideDropdown);
    }
  }, [hideDropdown, showDropdown]);

  const filteredOptions = useMemo(() => {
    if (!extendedOptions) return [];
    if (!searchValue) return extendedOptions;

    return extendedOptions.filter(({ searchString }) => searchString.toUpperCase().includes(String(searchValue).toUpperCase()));
  }, [extendedOptions, searchValue]);

  const onInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onInputFocus = () => {
    setSearchValue('');
    setInputInFocus(true);
    setShowDropdown(true);
  };

  const onInputBlur = () => {
    setInputInFocus(false);
  };

  const onSelect = (option) => {
    callback(option.value);
    setSearchValue(optionsLabelsMapByValue.get(option.value)?.join(', ') || '');

    setShowDropdown(false);
    onComplete && onComplete();
  };

  const portalFunc = usePortal ? ReactDOM.createPortal : (node) => node;

  return (
    <div className={`flex flex-col gap-1 ${className}`}>
      {title && <Title className={titleClassName}>{title}</Title>}
      <div className={`w-full relative ${containerClassName}`}>
        <input ref={toggleRef} id={`search_select_input_${idSuffix}`} value={searchValue} onChange={onInputChange}
               className={inputClassName} onFocus={onInputFocus} onBlur={onInputBlur}
               autoComplete={'off'} placeholder={placeholder} {...props} />

        {showDropdown &&
          portalFunc(
            <div ref={dropDownRef} id={`search_select_dropdown_${idSuffix}`}
                 className={`absolute top-[105%] flex flex-col w-full max-h-80 py-4 px-2 bg-white rounded shadow overflow-auto z-10 ${dropdownClassName}`}
                 style={calculateBounds ? (bounds ? {
                   ...bounds,
                   position: 'fixed',
                 } : { display: 'none' }) : {}}>

              {filteredOptions.map(option => {
                return React.createElement(
                  option.Component ?? 'button',
                  {
                    key: option.id,
                    onClick: () => onSelect(option),
                    className: `w-full p-1 hover:bg-gray-300/30 ${optionClassName}`,
                  },
                  option.label
                )
              })}
            </div>,
            document.getElementById('root')
          )
        }
      </div>
    </div>
  );
})
