import React, { useCallback, useEffect, useRef, useState } from 'react';
import BaseModal from '../baseModal';
import Registration from './registration';
import Passwords from './passwords';
import Edit from './edit';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '../../../redux/modals/selectors';
import { modalTypes } from '../../../redux/modals/modalTypes';
import { modalActions } from '../../../redux/modals/reducer';
import { usersManagerModalTypes } from './usersManagerModalTypes';
import { managerRest } from '../../../index';
import Form from '../../forms/base';

import { ReactComponent as Preloader } from '../../../assets/icons/preloader.svg';
import { ReactComponent as CheckMark } from '../../../assets/icons/check-mark.svg';
import EmailForm from '../../forms/email/emailForm';

export default React.memo(function UserManagerModal() {
  const modal = useSelector(getModalData(modalTypes.USER_MANAGER));
  const dispatch = useDispatch();
  const [emailData, setEmailData] = useState(null);
  const [emailFormTimeout, setEmailFormTimeout] = useState(null);
  const [currentModalType, setCurrentModalType] = useState(usersManagerModalTypes.REGISTRATION);
  const [showSuccessTimeout, setShowSuccessTimeout] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const currentScreenRef = useRef(null);

  const showSuccess = useCallback(() => {
    setSuccess(true);
    setShowSuccessTimeout(setTimeout(() => setSuccess(false), 3000));
  }, []);

  const submit = useCallback(async (e) => {
    e.preventDefault();

    setLoading(true);
    const success = await currentScreenRef.current?.submit();
    setLoading(false);
    success && showSuccess();
  }, [showSuccess]);

  const onDeleteUser = useCallback(() => {
    setLoading(true);
    dispatch(modalActions.showModal(modalTypes.CONFIRM, {
      text: `User "${currentScreenRef.current.getActiveUserName()}" will be deleted`,
      buttons: [
        { label: 'Cancel' },
        {
          label: 'OK', callback: async () => {
            let success;
            setLoading(true);
            success = await currentScreenRef.current.deleteUser();
            setLoading(false);
            if (success) {
              showSuccess();
              currentScreenRef.current.reset();
            }
          }
        }
      ],
      zIndex: 1000
    }));
  }, [dispatch, setLoading, showSuccess]);

  const openEmailForm = (data) => {
    setEmailFormTimeout(setTimeout(() => setEmailData(data), 1000));
  }

  const closeEmailForm = () => {
    setEmailData(null);
  }

  const close = () => {
    dispatch(modalActions.closeModal(modalTypes.USER_MANAGER));
    currentScreenRef.current?.reset();
    closeEmailForm();
  };

  const changeView = (e) => {
    const view = e.target.dataset.view;
    setCurrentModalType(view);
  }

  useEffect(() => {
    if (!modal.visible) return;
    managerRest.getOperators();
    managerRest.getCasinos();
    managerRest.getUserRoles();
  }, [modal.visible]);

  useEffect(() => {
    if (modal.visible) return;
    setSuccess(false);
    clearTimeout(showSuccessTimeout);
    clearTimeout(emailFormTimeout);
  }, [modal.visible, showSuccessTimeout, emailFormTimeout]);

  useEffect(() => {
    if (!modal.visible) return;

    currentScreenRef.current?.reset();
    setSuccess(false);
  }, [modal.visible]);

  return (
    <>
      <BaseModal onClose={close} visible={modal.visible} showCloseButton>
        <div className={'modal modal_l users_manager'}>
          <Form onSubmit={submit} className={'w-full h-full grid grid-rows-[auto,_1fr,_auto]'}>
            <div className={'modal_header'}>
              <div className={'users_manager_header py-2 px-24'}>
                <button type={'button'}
                        className={currentModalType === usersManagerModalTypes.REGISTRATION ? 'active' : ''}
                        data-view={usersManagerModalTypes.REGISTRATION} onClick={changeView}>
                  REGISTRATION
                </button>
                <button type={'button'} className={currentModalType === usersManagerModalTypes.EDIT ? 'active' : ''}
                        data-view={usersManagerModalTypes.EDIT} onClick={changeView}>
                  EDIT
                </button>
                <button type={'button'}
                        className={currentModalType === usersManagerModalTypes.PASSWORDS ? 'active' : ''}
                        data-view={usersManagerModalTypes.PASSWORDS} onClick={changeView}>
                  PASSWORDS
                </button>
              </div>
            </div>

            {currentModalType === usersManagerModalTypes.REGISTRATION &&
              <Registration ref={currentScreenRef} openEmailForm={openEmailForm} />}
            {currentModalType === usersManagerModalTypes.EDIT &&
              <Edit ref={currentScreenRef} onDeleteUser={onDeleteUser} openEmailForm={openEmailForm} />}
            {currentModalType === usersManagerModalTypes.PASSWORDS &&
              <Passwords ref={currentScreenRef} openEmailForm={openEmailForm} />}


            <div className={'modal_controls'}>

              {loading &&
                <span className={'relative'}>
                  <Preloader className={'button_preloader'} />
                </span>
              }

              <button disabled={loading || !!emailData} onClick={submit} className={'button'}>
                Submit
              </button>

              {success &&
                <span className={'relative'}>
                  <CheckMark className={'operation_success'} />
                </span>
              }

            </div>
          </Form>
        </div>
      </BaseModal>

      <EmailForm visible={!!emailData} close={closeEmailForm} data={emailData} />
    </>
  );
})
