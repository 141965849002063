import {useEffect} from 'react';
import scripts from './scripts';

const useExternalScript = (operatorId, condition) => {

  useEffect(() => {
    let cleanup;

    if (condition && scripts[`operator_${operatorId}`]) {
     cleanup = scripts[`operator_${operatorId}`]()?.cleanup;
    }

    return () => {
      if (cleanup) cleanup();
    }
  }, [condition, operatorId]);
};

export {
  useExternalScript,
}
