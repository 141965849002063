import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';
import { managerRest } from '../../../index';
import { modalActions } from '../../../redux/modals/reducer';
import { modalTypes } from '../../../redux/modals/modalTypes';
import { passwordRegexp } from '../../../utils/regexp/strings';
import SearchSelectFilter from '../../filters/searchSelectFilter';
import { getEnvironment } from '../../../utils/environment';

export default React.memo(forwardRef(function Passwords({ openEmailForm }, ref) {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const getUsers = useCallback(async () => {
    const data = await managerRest.getUsers();
    setUsers(data);
  }, [setUsers]);

  useEffect(() => {
    if (users.length) return;

    getUsers();
  }, [users, getUsers]);

  const resetUsers = () => {
    setUsers([]);
  };

  const reset = () => {
    setFormData({
      username: '',
      password: '',
    });
    resetUsers();
  };

  const submit = useCallback(async () => {
    if (!formData.username || !formData.password) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'User and new password should be specified.'));
      return false;
    }
    if (formData.password.length < 6) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Password must contain at least 6 characters'));
      return false;
    }

    const success = await managerRest.updateUser(formData);

    if (success) openEmailForm({
      to: formData.username,
      subject: `Onlyplay backoffice ${getEnvironment()} credentials changed`,
      text: `Hello!\n
Your password has been changed.
Here is your updated ${getEnvironment().toUpperCase()} credentials to access backoffice:\n
username: ${formData.username}
password: ${formData.password}
\nBest Regards,
Onlyplay Tech Team`
    });

    return success;
  }, [dispatch, formData, openEmailForm]);

  const changePassword = (e) => {
    if (passwordRegexp.test(e.target.value)) return;

    setFormData(prev => ({
      ...prev,
      password: e.target.value
    }));
  };

  const selectUser = (e) => {
    setFormData(prev => ({
      ...prev,
      username: e.target.value
    }))
  };

  useImperativeHandle(ref, () => ({
    submit,
    reset,
    resetUsers,
  }));

  return (
    <div className={'modal_body centered'}>
      <div className={'form_block'}>
        <SearchSelectFilter title={'User'} fieldName={'username'} options={users} callback={setFormData} placeholder={'Username'}/>
      </div>

      <div className={'form_block'}>
        <label>Password:</label>
        <input value={formData.password} onChange={changePassword} />
      </div>
    </div>
  );
}));
