import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import eOperatorsConfigsFieldName from '../eOperatorsConfigsFieldName';

export default React.memo(forwardRef(function Session({
                                                        configForView,
                                                        canChangeBlock,
                                                        canOverride,
                                                        setSaveFunctionPool,
                                                        overriddenBlocks,
                                                        toggleOverriddenField,
                                                        setValueToConfigForView
                                                      }, ref) {
  const defaultValues = useMemo(() => ({
    checkStatus: false,
    checkStatusInterval: 300,
  }), []);
  const [formData, setFormData] = useState(defaultValues);

  const saveFunction = useCallback(() => {
    let data = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_SESSION] === configForView.type) {
      data[eOperatorsConfigsFieldName.EPCFN_SESSION] = {
        checkStatus: formData.checkStatus,
      };

      if (data[eOperatorsConfigsFieldName.EPCFN_SESSION].checkStatus) {
        data[eOperatorsConfigsFieldName.EPCFN_SESSION].checkStatusInterval = parseInt(formData.checkStatusInterval) || defaultValues.checkStatusInterval;
      }
    }

    return data;
  }, [overriddenBlocks, formData, configForView.type, configForView.isGlobalConfig, defaultValues]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_SESSION]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    const configData = configForView.config[eOperatorsConfigsFieldName.EPCFN_SESSION] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_SESSION] : defaultValues;
    setFormData({
      checkStatus: configData && configData.hasOwnProperty('checkStatus') ? configData.checkStatus : defaultValues.checkStatus,
      checkStatusInterval: configData && configData.hasOwnProperty('checkStatusInterval') ? configData.checkStatusInterval : defaultValues.checkStatusInterval,
    });
  }, [configForView.config, setFormData, defaultValues]);

  const toggleCheckStatus = useCallback((value) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_SESSION,  {...formData, checkStatus: value});
  }, [setValueToConfigForView, configForView.type, formData]);

  const changeInterval = useCallback((e) => {
    setFormData(prev => ({...prev, checkStatusInterval: e.target.value}));
  }, [setFormData]);

  const onBlurText = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_SESSION, {...formData, checkStatusInterval: e.target.value});
  }, [setValueToConfigForView, configForView.type, formData]);

  const checkOverride = useCallback(() => {
    const configData = configForView.config[eOperatorsConfigsFieldName.EPCFN_SESSION] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_SESSION] : defaultValues;
    setFormData({
      checkStatus: configData && configData.hasOwnProperty('checkStatus') ? configData.checkStatus : defaultValues.checkStatus,
      checkStatusInterval: configData && configData.hasOwnProperty('checkStatusInterval') ? configData.checkStatusInterval : defaultValues.checkStatusInterval,
    });
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_SESSION);
  }, [setFormData, toggleOverriddenField, configForView, defaultValues]);

  const reset = useCallback(() => {
    const configData = configForView.config[eOperatorsConfigsFieldName.EPCFN_SESSION] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_SESSION] : defaultValues;
    setFormData({
      checkStatus: configData && configData.hasOwnProperty('checkStatus') ? configData.checkStatus : defaultValues.checkStatus,
      checkStatusInterval: configData && configData.hasOwnProperty('checkStatusInterval') ? configData.checkStatusInterval : defaultValues.checkStatusInterval,
    });
  }, [configForView.config, defaultValues, setFormData]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>
      {!configForView.isGlobalConfig && canOverride &&
        <div className={'operators_configs_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_SESSION] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_SESSION}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_SESSION]}
          />
        </div>}

      <span className={'operators_configs_body_form_box_title'}>Session</span>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Check status</span>
        <Checkbox checked={!!formData.checkStatus} setChecked={toggleCheckStatus}
                  id={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_SESSION}_status_check`} disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Interval</span>
        <input type={'number'} value={formData.checkStatusInterval} onChange={changeInterval}  onBlur={onBlurText}
               disabled={!canChangeBlock || !formData.checkStatus}/>
        <Info info={''}/>
      </div>
    </div>
  );
}));
