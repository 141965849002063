import { useEffect, useState } from 'react';

export const useBounds = (toggleRef, windowRef, showWindow, calculateBounds ) => {
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    if (!showWindow || !calculateBounds) return;
    const position = toggleRef.current.getBoundingClientRect();
    setBounds({ top: position.top + position.height, left: position.left });
  }, [showWindow, calculateBounds, toggleRef]);

  useEffect(() => {
    if (!bounds || !showWindow || !calculateBounds) return;
    const multiSelectHeight = windowRef.current.clientHeight;
    const multiSelectWidth = windowRef.current.clientWidth;
    let top, left;
    if (bounds.top + multiSelectHeight > window.innerHeight) top = window.innerHeight - multiSelectHeight;
    if (bounds.left + multiSelectWidth > window.innerWidth) left = window.innerWidth - multiSelectWidth;
    if (top || left) setBounds({ top: top || bounds.top, left: left || bounds.left });
  }, [bounds, showWindow, calculateBounds, windowRef]);

  return bounds;
};
