const _download = (url, filename) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  a.click();
}

export const download = (content, fileName, contentType) => {
  let file = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(file);
  _download(url, fileName);
  setTimeout(() => URL.revokeObjectURL(url), 1000 * 60);
};

export const downloadCSV = (csv, filename) => {
  const data = 'data:text/csv;charset=utf-8,' + csv;
  const encodedUri = encodeURI(data);
  _download(encodedUri, filename);
}
