import React, { useMemo } from 'react';
import BaseModal from './baseModal';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '../../redux/modals/selectors';
import { modalTypes } from '../../redux/modals/modalTypes';
import { modalActions } from '../../redux/modals/reducer';
import ReactJson from 'react-json-view';

export default React.memo(function InfoModal() {
  const dispatch = useDispatch();
  const modal = useSelector(getModalData(modalTypes.INFO));

  const data = useMemo(() => ({
    provider_request: null,
    operator_request: null,
    operator_response: null,
    provider_response: null,
    ...modal.data,
  }), [modal.data]);

  const closeModal = () => {
    dispatch(modalActions.closeModal(modalTypes.INFO));
  };

  return (
    <BaseModal visible={modal.visible} onClose={closeModal} showCloseButton>
      <div className={'modal modal_l'}>

        <div className={'modal_header'}>
          Transaction info
        </div>

        <div className={'modal_body'}>

          <div className={'info_modal_item'}>
            <div>Provider request</div>
            {data.provider_request &&
            typeof data.provider_request === 'object' ?
              <ReactJson src={data.provider_request} collapsed={2}
                         collapseStringsAfterLength={20} name={'Provider request'} /> : data.provider_request}
          </div>

          <div className={'info_modal_item'}>
            <div>Operator request</div>
            {data.operator_request && typeof data.operator_request === 'object' ?
              <ReactJson src={data.operator_request} collapsed={2}
                         collapseStringsAfterLength={20} name={'Operator request'} /> : data.operator_request}
          </div>

          <div className={'info_modal_item'}>
            <div>Operator response</div>
            {data.operator_response && typeof data.operator_response === 'object' ?
              <ReactJson src={data.operator_response} collapsed={2}
                         collapseStringsAfterLength={20} name={'Operator response'} /> : data.operator_response}
          </div>

          <div className={'info_modal_item'}>
            <div>Provider response</div>
            {data.provider_response && typeof data.provider_response === 'object' ?
              <ReactJson src={data.provider_response} collapsed={2}
                         collapseStringsAfterLength={20} name={'Provider response'} /> : data.provider_response}
          </div>

        </div>
      </div>
    </BaseModal>
  );
})
