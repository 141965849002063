import React, { useMemo } from 'react';
import BaseModal from './baseModal';
import GameResult from '../rounds/result';
import ReactJson from 'react-json-view';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '../../redux/modals/selectors';
import { modalTypes } from '../../redux/modals/modalTypes';
import { modalActions } from '../../redux/modals/reducer';

export default React.memo(function TransactionModal() {
  const modal = useSelector(getModalData(modalTypes.TRANSACTION));
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(modalActions.closeModal(modalTypes.TRANSACTION));
  };

  const result = modal.data?.transaction.result?.transactions[0].result;
  const version = modal.data?.transaction.result?.version;

  return (
    <BaseModal onClose={onClose} visible={modal.visible} showCloseButton>
      <div className={`modal modal_l modal_transaction ${!modal.data?.showJson ? 'preview_full_scale' : ''}`}>
        {modal.data?.transaction &&
        <GameResult game={modal.data.transaction.game_bundle} result={result} version={version} />}
        {modal.data?.showJson &&
        <ReactJson src={modal.data.transaction} name={'Transaction'} collapsed={1} displayDataTypes={false} />}
      </div>
    </BaseModal>
  );
})
