import React from 'react';

export default React.memo(function Checkbox({ checked, setChecked, id, disabled }) {

  const toggle = (e) => {
    setChecked(e.target.checked);
  };

  return (
    <span className={'input_forms_checkbox'}>
      <input type={'checkbox'} id={id} checked={checked} onChange={toggle} disabled={disabled} />
      <label htmlFor={id} />
    </span>
  );
})
