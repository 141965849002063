import React, { useCallback, useMemo, useRef, useState } from 'react';
import ContextMenu from '../contextMenu';
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg';

const { positions: contextMenuPositions } = ContextMenu;

export default function Multiselect({
                                      options,
                                      selected,
                                      select,
                                      deselect,
                                      addOption,
                                      isAllAvailable,
                                      placeholder,
                                      className = '',
                                      contextClassName = ''
                                    }) {
  const [search, setSearch] = useState('');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const inputRef = useRef();

  const extendedOptions = useMemo(() => {
    return isAllAvailable ? [{
      id: -1,
      value: '',
      label: 'All',
      searchString: 'All',
      Component: options[0]?.Component
    }, ...options] : options;
  }, [options, isAllAvailable]);

  const optionsMapByValue = useMemo(() => {
    return extendedOptions.reduce((acc, option) => {
      if (!acc.hasOwnProperty(option.value)) acc[option.value] = option;
      return acc;
    }, {});
  }, [extendedOptions]);

  const selectedOptions = useMemo(() => {
    return selected.reduce((acc, value) => {
      const option = optionsMapByValue[value];
      acc.list.push(option);
      acc.byId[option.id] = option;
      return acc;
    }, { list: [], byId: {} });
  }, [selected, optionsMapByValue]);

  const closeDropdown = useCallback(() => {
    setDropdownVisible(false);
    setSearch('');
  }, []);

  const filteredOptions = useMemo(() => {
    if (!extendedOptions) return [];
    if (!search) return extendedOptions;

    return extendedOptions.filter(({ searchString }) => searchString.toUpperCase().includes(search.toUpperCase()));
  }, [extendedOptions, search]);

  const onInputChange = (e) => {
    setSearch(e.target.value);
  };

  const onInputFocus = () => {
    setDropdownVisible(true);
  };

  const onInputBlur = () => {
    // setInputInFocus(false);
  };

  const onSelect = (option) => {
    closeDropdown();
    select(option);
  };

  const onDeselect = (option) => {
    closeDropdown();
    deselect(option);
  };

  const toggle = (option) => {
    if (selectedOptions.byId[option.id]) {
      onDeselect(option.value);
    } else {
      onSelect(option.value);
    }
  }

  // const onSubmit = (e) => {
  //   e.preventDefault();
  //
  //   console.log(search);
  //   // if (optionsMapByValue[])
  //   // const newOption
  // }

  return (
    <div className={`w-full flex flex-wrap gap-1 border rounded p-0.5 ${className}`}>
      {selectedOptions.list.map(option =>
        <button type={'button'} key={option.id} onClick={() => toggle(option)}
                className={'flex-grow-0 flex-shrink-0 py-0.5 px-3 bg-amber-200 rounded'}>{option.label}</button>
      )}
      <input ref={inputRef} value={search} onChange={onInputChange} onFocus={onInputFocus} onBlur={onInputBlur}
             placeholder={placeholder} className={'w-full min-w-[10ch] h-6 flex-1 !border-none'} />
      {dropdownVisible &&
        <ContextMenu trigger={inputRef.current} position={contextMenuPositions.BOTTOM_LEFT} offsets={{ top: 5 }}
                     close={closeDropdown} className={contextClassName}>
          {filteredOptions.map(({ Component, ...option }) => {
              const isSelected = !!selectedOptions.byId[option.id];
              return <ContextMenu.Item key={option.id}>
                <button type={'button'} onClick={() => toggle(option)}
                        className={`w-full px-4 py-1 hover:bg-gray-200 ${isSelected ? 'bg-gray-200' : ''}`}>
                  {Component ? <Component {...option} isSelected={isSelected} /> :
                    <div className={`flex gap-6 items-center justify-between`}>
                      <span>{option.label}</span>
                      <span className={'flex items-center size-2'}>
                      {isSelected && <CheckIcon className={'w-full h-full [&>path]:fill-gray-500'} />}
                      </span>
                    </div>}
                </button>
              </ContextMenu.Item>
            }
          )}
        </ContextMenu>}
    </div>
  );
}
