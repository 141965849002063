const SET_USER = 'USER/SET_USER';

const initialState = {
  user: null,
  isAuth: false
};

export const userReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: payload,
        isAuth: true,
      };
    default:
      return state;
  }
};

export const userActions = {
  setUser(user) {
    return {
      type: SET_USER,
      payload: user
    }
  }
};
