import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { appReducer } from './app/reducer';
import { modalReducer } from './modals/reducer';
import { userReducer } from './user/reducer';
import { partnersConfigReducer } from './partnersConfig/reducer';
import { operatorsConfigsReducer } from './operatorsConfigs/reducer';
import { openRouteReducer } from './openRoute/reducer';

const rootReducer = combineReducers({
  app: appReducer,
  modals: modalReducer,
  user: userReducer,
  partnersConfig: partnersConfigReducer,
  operatorsConfigs: operatorsConfigsReducer,
  openRoute: openRouteReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware()));
// export const store = createStore(rootReducer);
