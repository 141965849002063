import {
  createCasinoOption,
  createOperatorOptionWithIdLabel
} from '../../../utils/createOptions';
import Multiselect from '../../../uiKit/inputs/multiselect';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { partnerConfigSelectors } from '../../../redux/partnersConfig/selectors';
import Delete from '../../../uiKit/buttons/delete';
import { appSelectors } from '../../../redux/app/selectors';
import { SearchSelectInputV2 } from '../../../uiKit/inputs';

export default function PermissionsByOperatorBlock({
                                                     selectedOperator,
                                                     selectOperator,
                                                     selectedCasino,
                                                     selectCasino,
                                                     deselectCasino,
                                                     deleteBlock,
                                                     idSuffix,
                                                   }) {
  const operators = useSelector(partnerConfigSelectors.getOperators);
  const casino = useSelector(appSelectors.getCasinoListByOperator);

  const operatorsOptions = useMemo(() => Object.values(operators).map(operator => createOperatorOptionWithIdLabel(operator)), [operators]);
  const casinoOptions = selectedOperator && casino && casino[selectedOperator] ? casino[selectedOperator].map(item => createCasinoOption(item)) : [];

  return (
    <div className={'relative flex flex-col gap-2'}>
      {deleteBlock &&
        <Delete onClick={deleteBlock}
                className={'absolute top-2 left-[calc(100%+theme(space.1))] opacity-20 hover:!opacity-100 bg-red-500'}>
          Delete
        </Delete>}
      <SearchSelectInputV2 placeholder={'Operator'} options={operatorsOptions} callback={selectOperator}
                           selectedValue={selectedOperator} idSuffix={idSuffix}
                           dropdownClassName={'z-[100] w-max max-w-80'}
                           className={'!flex-row items-center'} titleClassName={'text-gray-400'}
                           optionClassName={'text-left'} calculateBounds usePortal />
      <Multiselect options={casinoOptions} selected={selectedCasino} select={selectCasino} deselect={deselectCasino}
                   placeholder={'Casino'} className={'[&_input]:!h-6'} contextClassName={'max-h-36 rounded'} />
    </div>
  )
}
