const SET_CONFIG = 'OPERATORS_CONFIGS/SET_CONFIG';

const initialState = {
  configs: null,
};

export const operatorsConfigsReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state,
        configs: payload
      };
    default:
      return state;
  }
};

export const operatorsConfigsActions = {
  setConfigs(configs) {
    return {
      type: SET_CONFIG,
      payload: configs
    }
  },
};
