import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout'

export default React.memo(forwardRef(function SingleBooleanField({
                                                                   title,
                                                                   fieldName,
                                                                   canChangeBlock,
                                                                   canOverride,
                                                                   initialValue,
                                                                   configForView,
                                                                   setSaveFunctionPool,
                                                                   overriddenBlocks,
                                                                   toggleOverriddenField,
                                                                   info,
                                                                   setValueToConfigForView
                                                                 }, ref) {
  const [value, setValue] = useState(initialValue);

  const saveFunction = useCallback(() => {
    const data = {};
    if (configForView.isGlobalConfig || overriddenBlocks[fieldName] === configForView.type) {
      data[fieldName] = value;
    }
    return data;
  }, [overriddenBlocks, fieldName, value, configForView.type, configForView.isGlobalConfig]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [fieldName]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, fieldName, configForView.type]);

  useEffect(() => {
    const value = configForView.config[fieldName] !== undefined ? configForView.config[fieldName] : initialValue;
    setValue(value);
  }, [configForView.config, fieldName, setValue, initialValue]);

  const checkOverride = useCallback(() => {
    const value = configForView.config[fieldName] !== undefined ? configForView.config[fieldName] : initialValue;
    setValue(value);
    toggleOverriddenField(configForView.type, fieldName);
  }, [setValue, toggleOverriddenField, fieldName, configForView, initialValue]);

  const changeValue = useCallback((value) => {
    canChangeBlock && setValueToConfigForView(configForView.type, fieldName, value);
  }, [canChangeBlock, setValueToConfigForView, configForView, fieldName]);

  const reset = useCallback(() => {
    const value = configForView.config[fieldName] !== undefined ? configForView.config[fieldName] : initialValue;
    setValue(value);
  }, [configForView.config, initialValue, setValue, fieldName]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>
      <div className={'input_form'}>
        <span className={'input_form_title'}>{title}</span>
        <div className={'operators_configs_override'}>
          <Checkbox checked={value} setChecked={changeValue} id={`${configForView.type}_${fieldName}_checkbox`}/>
          <Info info={info}/>
          {!configForView.isGlobalConfig && canOverride &&
            <AddCheckboxLayout title={'override'}
                               checked={overriddenBlocks[fieldName] === configForView.type}
                               checkboxId={`${configForView.type}_${fieldName}_override_checkbox`}
                               setChecked={checkOverride}
                               disabled={!canOverride}
                               additionalInfo={overriddenBlocks[fieldName]}
            />}
        </div>
      </div>
    </div>
  );
}));
