import React, {useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import moment from 'moment';
import TextFilter from '../filters/textFilter';
import RangeFilter from '../filters/rangeFilter';
import SelectFilter from '../filters/selectFilter';
import DateFilter from '../filters/dateFilter';
import SearchSelectFilter from '../filters/searchSelectFilter';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../redux/app/selectors';
import { userSelectors } from '../../redux/user/selectors';
import { managerRest } from '../../index';
import { SuperAdminCheckLayout } from '../../utils/rolesCheck';

const isClosedOptions = {
  CLOSED: 'Yes',
  NOT_CLOSED: 'No',
};

export default React.memo(React.forwardRef(function TransactionsFilters({ onSearch, onDownload }, ref) {
  const isAuth = useSelector(userSelectors.getIsAuth);
  const options = useSelector(appSelectors.getOptions);
  const [filters, setFilters] = useState({
    id: '',
    operator_id: '',
    casino_id: '',
    bet_amount: { min: '', max: '' },
    win_amount: { min: '', max: '' },
    currency: '',
    game_bundle: '',
    is_closed: '',
    operator_round: '',
    provider_round: '',
    operator_user: '',
    updated_at: {
      min: moment(new Date()).utc(false).startOf('day').format('YYYY-MM-DDTHH:mm'),
      max: moment(new Date()).utc(false).endOf('minute').add(1, 'second').format('YYYY-MM-DDTHH:mm'),
    },
  });

  useEffect(() => {
    if (!isAuth) return;

    managerRest.getOptions();
    }, [isAuth]);

  const setIsClosed = useCallback((f) => {
    let [fieldName, value] = Object.entries(f())[0];
    if (value === isClosedOptions.CLOSED) {
      value = 1;
    } else if (value === isClosedOptions.NOT_CLOSED) {
      value = 0;
    }

    setFilters(prev => ({
      ...prev,
      [fieldName]: value,
    }));
    }, []);

  const checkDates = () => {
    let now = Date.now();
    let minDate = filters.updated_at.min ? new Date(filters.updated_at.min).getTime() : '';
    let maxDate = filters.updated_at.max ? new Date(filters.updated_at.max).getTime() : '';
    if (minDate || maxDate) {
      let minDateDiff = minDate ? now - minDate : 0;
      let maxDateDiff = maxDate ? now - maxDate : 0;
      minDate = minDateDiff < 0 ? now : minDate;
      maxDate = maxDateDiff < 0 ? now : maxDate;
    }

    if ((minDate && maxDate) && minDate - maxDate > 0) {
      minDate = maxDate
    }

    return { min: minDate, max: maxDate };
  };

  const updateFilters = () => {
    const dates = checkDates();
    const checkedFilters = {
      ...filters,
      updated_at: {
        min: dates.min ? moment(dates.min).format('YYYY-MM-DDTHH:mm') : '',
        max: dates.max ? moment(dates.max).format('YYYY-MM-DDTHH:mm') : '',
      },
      bet_amount: {
        min: Number(filters.bet_amount.min) || '',
        max: Number(filters.bet_amount.max) || '',
      },
      win_amount: {
        min: Number(filters.win_amount.min) || '',
        max: Number(filters.win_amount.max) || '',
      }
    };

    setFilters(checkedFilters);
    return checkedFilters;
  };

  const selectOperator = useCallback((updateFn) => {
    const operator_id = updateFn().operator_id.replace(/:.*$/, '');
    setFilters(prev => ({ ...prev, operator_id }));
    }, []);

  const search = () => {
    const filters = updateFilters();
    onSearch(filters);
  };

  const download = () => {
    const filters = updateFilters();
    onDownload(filters);
  };

  const operatorsOptions = useMemo(() => Object.entries(options.operators).map(entry => `${entry[0]}: ${entry[1]}`), [options.operators]);
  const selectedIsClosedValue = useMemo(() => {
    let value = filters.is_closed;
    if (value === 1) {
      value = isClosedOptions.CLOSED;
    } else if (value === 0) {
      value = isClosedOptions.NOT_CLOSED
    }
    return value;
    }, [filters.is_closed]);

  useImperativeHandle(ref, () => ({
    getFilter: updateFilters,
  }));

  return (
    <div className={'filters_block'}>
      <TextFilter title={'id'} fieldName={'id'} callback={setFilters} value={filters.id} number />
      <SearchSelectFilter title={'operator'} fieldName={'operator_id'} options={operatorsOptions}
        callback={selectOperator} />
      <TextFilter title={'casino id'} fieldName={'casino_id'} callback={setFilters}
                  value={filters.casino_id} />
      <RangeFilter title={'bet amount'} fieldName={'bet_amount'} callback={setFilters} value={filters.bet_amount} />
      <RangeFilter title={'win amount'} fieldName={'win_amount'} callback={setFilters} value={filters.win_amount} />
      <SearchSelectFilter title={'currency'} fieldName={'currency'} options={options.currencies}
                          callback={setFilters} />
      <SearchSelectFilter title={'game'} fieldName={'game_bundle'} options={options.games} callback={setFilters} />
      <SelectFilter title={'closed'} fieldName={'is_closed'} options={Object.values(isClosedOptions)}
        callback={setIsClosed}
        value={selectedIsClosedValue} />
      <TextFilter title={'operator round id'} fieldName={'operator_round'} callback={setFilters}
        value={filters.operator_round} />
      <TextFilter title={'provider round id'} fieldName={'provider_round'} callback={setFilters}
        value={filters.provider_round} />
      <TextFilter title={'user id'} fieldName={'operator_user'} callback={setFilters} value={filters.operator_user} />
      <DateFilter title={'date (UTC)'} fieldName={'updated_at'} callback={setFilters} value={filters.updated_at} />
      <div className={'filter'}>
        <span className={'filter_title'}>
          result
        </span>
        <div className={'filter_inputs'}>
          <span onClick={search} className={'button_search'}>
            SEARCH
          </span>

          {<SuperAdminCheckLayout>
            <span onClick={download} className={'button_download'}>
              DOWNLOAD
            </span>
        </SuperAdminCheckLayout>}
        </div>
      </div>
    </div>
    );
}));
