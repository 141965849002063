import React, { useEffect, useState } from 'react';
import BaseModal from '../../modals/baseModal';
import Form from '../base';
import Spinner from '../../loading/indicator';
import { Input, TextArea } from '../../../uiKit/inputs';
import ButtonFulfilled from '../../../uiKit/buttons/fulfilled';
import ButtonOutlined from '../../../uiKit/buttons/outlined';
import Title from '../../../uiKit/typography/title';
import { managerRest } from '../../../index';

const defaultFormData = {
  to: '',
  subject: '',
  text: '',
};

export default React.memo(function EmailForm({ visible, close, data }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    setFormData({ ...defaultFormData, ...data });
  }, [data]);

  useEffect(() => {
    setErrors(null);
  }, [visible]);

  const submit = async (e) => {
    console.log(formData);
    setLoading(true);
    const data = { ...formData, to: formData.to.replace(/\s/g, '').split(',') };
    const success = await managerRest.sendTextEmail(data);
    setLoading(false);
    if (success) close();
  }

  const onError = (errors) => {
    setErrors(errors);
  }

  const changeForm = (e) => {
    if (errors?.[e.target.name]) {
      setErrors(prev => Object.fromEntries(Object.entries(prev).filter(([key]) => key !== e.target.name)));
    }

    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

    setFormData(prev => ({
      ...prev,
      [e.target.name]: value
    }))
  }

  return (
    <BaseModal visible={visible} onClose={close} showCloseButton>
      <div className={'modal'}>

        {loading && <Spinner />}

        <div className={'modal_header'}>
          <Title>Send Email</Title>
        </div>

        <div className={'modal_body'}>
          <Form submit={submit} onError={onError}>
            <div className={'flex flex-col gap-4 [&>label]:flex [&>label]:flex-col [&>label]:gap-2'}>

              <label>
                <Title className={'text-sm'}>To:</Title>
                <Input name={'to'} value={formData.to} onChange={changeForm}
                       placeholder={'example@gmail.com, example2@gmail.com...'}
                       className={errors?.to ? 'input_error' : ''} required />
              </label>

              <label>
                <Title className={'text-sm'}>Subject:</Title>
                <Input name={'subject'} value={formData.subject} onChange={changeForm} placeholder={'Subject'}
                       className={errors?.subject ? 'input_error' : ''} required />
              </label>

              <label>
                <Title className={'text-sm'}>Text:</Title>
                <TextArea name={'text'} value={formData.text} onChange={changeForm} placeholder={'Text'}
                          className={`h-32 ${errors?.text ? 'input_error' : ''}`} required />
              </label>

            </div>

            <div className={'w-2/3 m-auto grid grid-cols-2 gap-2 items-center'}>
              <ButtonOutlined type={'button'} onClick={close}>
                Cancel
              </ButtonOutlined>
              <ButtonFulfilled type={'submit'}>
                Send
              </ButtonFulfilled>
            </div>
          </Form>
        </div>
      </div>
    </BaseModal>
  );
})
