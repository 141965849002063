import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import AddCheckboxLayout from '../../../inputForms/addCheckboxLayout';
import ePartnerConfigFieldName from '../../ePartnerConfigFieldName';
import MessageEvent from './messageEvent';
import eventsParams from './eventsParams';

export default React.memo(forwardRef(function MessageEvents({
                                                              config,
                                                              canChangeBlock,
                                                              canOverride,
                                                              setSaveFunctionPool,
                                                              selectedCasino,
                                                              overriddenBlocks,
                                                              setOverriddenBlocks,
                                                              getConfigOrCasinoConfigProperty,
                                                              casinoConfig
                                                            }, ref) {
  const [formData, setFormData] = useState({});

  const saveFunction = useCallback(() => {
    const data = {};
    const eventsData = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS])) {
      Object.keys(formData).forEach((eventName => {
        if (formData[eventName] === true || (Array.isArray(formData[eventName]) && formData[eventName].length === 0)) {
          eventsData[eventName] = true;
        } else if (formData[eventName] && Array.isArray(formData[eventName])) {
          eventsData[eventName] = formData[eventName]
        }
      }));
    }
    if (Object.keys(eventsData).length) data.messageEvents = eventsData;
    return data;
  }, [selectedCasino, overriddenBlocks, formData]);

  const parseConfig = useCallback((obj) => {
    const newObj = Object.keys(obj).reduce((prevObj, key) => {
      prevObj[key] = obj[key] === true ? [] : [...obj[key]];
      return prevObj;
    }, {})
    setFormData(newObj);
  }, [setFormData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS]: saveFunction,
    }));
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!config) return setFormData({});
    const eventsData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS);
    parseConfig(eventsData || {});
  }, [setFormData, config, casinoConfig, getConfigOrCasinoConfigProperty]);

  const checkOverride = () => {
    const fieldData = !overriddenBlocks[ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS] ? config && config[ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS] : getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS);
    parseConfig(fieldData || {});
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS]: !prev[ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS]
    }));
  };

  const onSelectParam = useCallback((selectedParams, eventsName) => {
    setFormData(prev => ({...prev, [eventsName]: selectedParams}))
  }, [setFormData]);

  const onEventChecked = useCallback((checked, eventsName) => {
    setFormData(prev => ({...prev, [eventsName]: checked ? prev[eventsName] || [] : undefined}))
  }, [setFormData]);

  const reset = () => {
    const eventsData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS);
    parseConfig(eventsData || {});
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      {!!selectedCasino && canOverride &&
        <div className={'partners_config_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_MESSAGE_EVENTS}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        </div>}
      <span className={'partners_config_body_form_box_title'}>Message events</span>
      {Object.keys(eventsParams).map((eventName, index) => (
        <MessageEvent
          key={eventName}
          eventName={eventName}
          params={eventsParams[eventName]}
          selected={formData[eventName]}
          disabled={!canChangeBlock}
          onSelect={onSelectParam}
          checked={!!formData[eventName]}
          onChecked={(boolean) => onEventChecked(boolean, eventName)}
        />
      ))}
    </div>
  );
}));
