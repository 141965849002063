const _roundMinMaxValues = (minValue, maxValue) => {
  if (minValue > 10) {
    const minNumbers = minValue.toFixed(8).split('.')[0].length;
    const modMin = Math.pow(10, Math.max(minNumbers - 2, 0));
    minValue = Math.ceil(minValue / modMin) * modMin;
  } else {
    const expMin = minValue.toFixed(8).split('.')[1].search(/[1-9]/);
    let modMin = Math.pow(10, expMin + 1);
    if (minValue >= 1) modMin = 1;
    let newMinValue = Math.round(minValue * modMin) / modMin;
    const tempMaxValue = Math.floor(maxValue * modMin) / modMin;
    if (newMinValue >= tempMaxValue ) {
      modMin *= 10;
      newMinValue = Math.round(minValue * modMin) / modMin;
    }
    minValue = newMinValue;
  }

   if (maxValue >= 20) {
    const numbers = maxValue.toFixed(8).split('.')[0].length;
    let modMax = Math.pow(10, Math.max(numbers - 2, 1));
    let newMaxValue = Math.floor(maxValue / modMax) * modMax;
    if (newMaxValue <= minValue) {
      modMax /= 10;
      newMaxValue = Math.floor(maxValue * modMax) / modMax;
    }
    maxValue = newMaxValue;
  } else if (maxValue > 12) {
    maxValue = 15
  } else if (maxValue > 10) {
    maxValue = 10;
  } else {
    const expMax = maxValue.toFixed(8).split('.')[1].search(/[1-9]/);
    let modMax = Math.pow(10, expMax + 1);
    if (maxValue >= 1) modMax = 1;
    let newMaxValue = Math.floor(maxValue * modMax) / modMax;
    if (newMaxValue <= minValue) {
      modMax *= 10;
      newMaxValue = Math.floor(maxValue * modMax) / modMax;
    }
    maxValue = newMaxValue;
  }
  return [minValue, maxValue];
}
export const generateBets = (minValue, maxValue, betsCount, generatorFunctionDegree, currency) => {
  [minValue, maxValue] = _roundMinMaxValues(minValue, maxValue);

  const offset = maxValue - minValue;

  let result = [minValue];

  let value = minValue;
  for (let i = 1; i < betsCount - 1; i++) {
    const progress = i / (betsCount - 1);
    value = minValue + offset * Math.pow(progress, generatorFunctionDegree);
    result.push(value);
  }

  result.push(maxValue);

  result = roundBets(result, currency)

  return result;
};

export const roundBets = (bets, currency) => {
  const [minValue, maxValue] = _roundMinMaxValues(bets[0], bets.at(-1));

  const result = [];

  for (let i = 0; i < bets.length; i++) {
    const currentBet = bets[i];

    if (i === bets.length - 1) {
      result.push(maxValue);
    } else if (i === 0) {
      result.push(minValue);
    } else if (currentBet > 10) {
      const minNumbers = currentBet.toFixed(8).split('.')[0].length;
      const mod = Math.pow(10, Math.max(minNumbers - 2, 0));
      result.push(Math.floor(currentBet / mod) * mod);
    } else {
      const decimalPartLength = String(currentBet).split('.')[1]?.length || 0;
      const exp = currentBet.toFixed(8).split('.')[1].search(/[1-9]/);
      let mod = Math.pow(10, exp + 1);
      if (currentBet >= 1) mod = 1;
      let newValue = Math.floor(currentBet * mod) / mod;
      let whileCount = 0;

      while (newValue === 0 || newValue <= result.at(-1)) {
        mod *= 10;
        newValue = Math.floor(currentBet * mod) / mod;
        if (++whileCount > decimalPartLength) {
          const prevDecimals = String(result.at(-1)).split('.')[1]?.length || 1;
          newValue = Number((result.at(-1) + 1 / Math.pow(10, prevDecimals + 1)).toFixed(prevDecimals + 1));
          console.error(`iteration limit exceeded for currency: ${currency}`)
        }
      }
      result.push(newValue);
    }
  }

  return tryAdditionallyRoundBets(result, currency);
};

function tryAdditionallyRoundBets(bets, currency) {
  return bets.map((value, index) => {
    if (index === 0 || index === bets.length - 1) return value; // if min or max bet
    const numbers = value.toString().split('.')[1]?.length;
    if (!numbers) return value; //if integer bet
    let mod = Math.pow(10, Math.max(numbers - 1, 0));
    let newValue = value;
    let prevValue = value;
    let whileCount = 0;
    while (newValue !== 0 && newValue > bets[index - 1]) {
      prevValue = newValue;
      newValue = Math.floor(value * mod) / mod;
      mod /= 10;
      if (++whileCount > 100) {
        console.error(`iteration limit exceeded for currency: ${currency}`)
      }
    }
    return prevValue;
  })
}
