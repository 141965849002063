import React, { useCallback, useEffect, useMemo, useState } from 'react';

export default React.memo(function SearchSelectInput({ title, selected, fieldName, onSelect, options, disabled, inputProps = {} }) {
  const [optionsList, setOptionsList] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputInFocus, setInputInFocus] = useState(false);
  const [selectedFilteredOption, setSelectedFilteredOption] = useState(0);

  const hideDropdown = useCallback((e) => {
    if (e.target.closest(`#search_select_input_${title}`) || e.target.closest(`#search_select_dropdown_${title}`) || inputInFocus) return;

    setShowDropdown(false);
  }, [setShowDropdown, title, inputInFocus]);

  useEffect(() => {
    console.log('selectedFilteredOption', selectedFilteredOption)
  },[selectedFilteredOption])

  useEffect(() => {
    setOptionsList(options.map(option => String(option)));
  }, [options]);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('click', hideDropdown);
    } else {
      document.removeEventListener('click', hideDropdown);
    }

    return () => document.removeEventListener('click', hideDropdown);
  }, [hideDropdown, showDropdown]);

  const filteredOptions = useMemo(() => {
    if (!optionsList) return [];

    return optionsList.filter(el => el.toUpperCase().includes(searchValue.toUpperCase()));
  }, [optionsList, searchValue]);

  const onInputChange = (e) => {
    console.log('onInputChange')

    setSearchValue(e.target.value);
  };

  const onInputFocus = () => {
    console.log('onInputFocus')

    setInputInFocus(true);
    setShowDropdown(true);
  };

  const onInputBlur = () => {
    console.log('onInputBlur')
    onSelect({ target:{ value: searchValue} })
    setInputInFocus(false);
  };

  const onKeyDown = (e) => {
    console.log(e.key)
    if (e.key === 'Enter') {
      console.dir(e.target);
      setInputInFocus(false);
      setShowDropdown(false);
      setSearchValue(filteredOptions[selectedFilteredOption] || searchValue);
      onSelect({ target:{ value: filteredOptions[selectedFilteredOption] || searchValue} })
    } else if (e.key === 'ArrowDown') {
      setShowDropdown(true);
      setInputInFocus(true);
      setSelectedFilteredOption(prev => (prev + 1) % filteredOptions.length)
    } else if (e.key === 'ArrowUp') {
      setShowDropdown(true);
      setInputInFocus(true);
      setSelectedFilteredOption(prev => (prev - 1) >= 0 ? (prev - 1) : filteredOptions.length - 1)
    } else {
      setShowDropdown(true);
      setInputInFocus(true);
    }
  }

  const _onSelect = (e) => {
    console.log('_onSelect')
    setSearchValue(e.target.dataset.value);

    onSelect({
      target: {
        value: e.target.dataset.value
      },
      originalEvent: e
    })
    setShowDropdown(false);
  };

  return (
    <div className={'filter'}>
      <span className={'filter_title'}>{title}</span>
      <div className={'filter_inputs relative'}>
        <input disabled={disabled}  id={`search_select_input_${title}`} value={inputInFocus ? searchValue : selected} onChange={onInputChange}
               autoComplete={'off'} onFocus={onInputFocus} onBlur={onInputBlur} {...inputProps} onKeyDown={onKeyDown} />

        {showDropdown && !!filteredOptions.length &&
        <div id={`search_input_dropdown_${title}`} className={'search_input_dropdown'}>
          <div onClick={_onSelect} className={'search_filter_dropdown_option'} data-value={''}>
            None
          </div>

          {filteredOptions.map((el, i) =>
            <div key={i} onClick={_onSelect} className={'search_input_dropdown_option' + (selectedFilteredOption === i ? ' active' : '') } data-value={el}>
              {el}
            </div>
          )}
        </div>}

      </div>
    </div>
  );
})
