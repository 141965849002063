export const createLegacyCasinoOption = (casinoId) => ({
  id: casinoId,
  searchString: casinoId,
  label: casinoId,
  value: casinoId,
})

export const createLegacyGameOption = (bundle) => ({
  id: bundle,
  searchString: bundle,
  label: bundle,
  value: bundle,
})

export const createCasinoOption = ({casino_id, operator_id, title}, Component) => {
  return {
    id: casino_id,
    value: casino_id,
    label: `${title} (${casino_id})`,
    searchString: title + casino_id,
    operator_id,
    casino_id,
    Component,
  }
}

export const createOperatorOption = ({id, name, casino_list}, Component) => {
  return {
    id: id,
    value: id,
    label: name,
    searchString: id + name,
    Component,
  }
}

export const createOperatorOptionWithIdLabel = ({id, name, casino_list}, Component) => {
  return {
    id: id,
    value: id,
    label: `${id}: ${name}`,
    searchString: id + name,
    Component,
  }
}
