import React from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';

export default React.memo(function Paginator({ page, setPage, hasMore }) {

  const nextPage = () => {
    setPage(page + 1);
  };

  const previousPage = () => {
    setPage(page - 1);
  };

  return (
    <div className={'paginator'}>
      <div>
        <button disabled={page === 1} onClick={previousPage}>
          <ArrowIcon />
        </button>
        <span>{page}</span>
        <button disabled={!hasMore} onClick={nextPage}>
          <ArrowIcon />
        </button>
      </div>
    </div>
  );
})
