export const modalTypes = {
  LOGIN: 'LOGIN',
  USER_MANAGER: 'USER_MANAGER',
  NOTIFICATION: 'NOTIFICATION',
  ERROR: 'ERROR',
  TRANSACTION: 'TRANSACTION',
  INFO: 'INFO',
  CLOSE_ROUND: 'CLOSE_ROUND',
  COMPARE_JSON: 'COMPARE_JSON',
  CONFIRM: 'CONFIRM',
  MESSAGE: 'MESSAGE',
};
