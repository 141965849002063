import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import ePartnerConfigFieldName from '../ePartnerConfigFieldName';

export default React.memo(forwardRef(function RoundDuration({
                                                              config,
                                                              canChangeBlock,
                                                              canOverride,
                                                              setSaveFunctionPool,
                                                              selectedCasino,
                                                              overriddenBlocks,
                                                              setOverriddenBlocks,
                                                              getConfigOrCasinoConfigProperty
                                                            }, ref) {
  const defaultValue = '';
  const [roundDuration, setRoundDuration] = useState(defaultValue);

  const saveFunction = useCallback(() => {
    let data = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_ROUND_DURATION])) {


      if (roundDuration) {
        data[ePartnerConfigFieldName.EPCFN_ROUND_DURATION] = parseInt(roundDuration) || roundDuration;
      }
    }

    return data;
  }, [selectedCasino, overriddenBlocks, roundDuration, defaultValue]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_ROUND_DURATION]: saveFunction,
    }))
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!config) return;
    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_ROUND_DURATION);
    setRoundDuration(configData || configData === 0 ? configData : defaultValue);
  }, [config, setRoundDuration, defaultValue, getConfigOrCasinoConfigProperty]);

  const changeInterval = useCallback((e) => {
    setRoundDuration(e.target.value);
  }, [setRoundDuration]);

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_ROUND_DURATION]: !prev[ePartnerConfigFieldName.EPCFN_ROUND_DURATION],
    }));

    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_ROUND_DURATION);
    setRoundDuration(configData || configData === 0 ? configData : defaultValue);
  };

  const reset = () => {
    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_ROUND_DURATION);
    setRoundDuration(configData || configData === 0 ? configData : defaultValue);
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Round duration</span>
        <input type={'number'} value={roundDuration} onChange={changeInterval}
               disabled={!canChangeBlock}/>
        {!!selectedCasino && canOverride &&
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_ROUND_DURATION]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_ROUND_DURATION}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        }
        <Info info={''}/>
      </div>
    </div>
  );
}));
