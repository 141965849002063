import React from "react";
import {Switch, Route} from "react-router-dom";

import HashPermissionRouter from "../components/permissionRouters/hashPermissionRouter";
import OpenRouteContextProvider from "../contextProviders/openRouteContext";
import ExternalScriptsHandler from "../externalScripts/externalScriptsHandler";

import RoundsHistory from "../components/roundsHistory/list";
import RoundDetails from "../components/roundsHistory/details";

export default function OpenRoute() {

  return (
    <OpenRouteContextProvider>
      <HashPermissionRouter>
        <ExternalScriptsHandler>
          <Switch>
            <Route exact path={'/player-results'} render={() => <RoundsHistory/>}/>
            <Route exact path={'/player-results/details/:round_id'}
                   render={({match}) => <RoundDetails roundId={match.params.round_id}/>}/>
          </Switch>
        </ExternalScriptsHandler>
      </HashPermissionRouter>
    </OpenRouteContextProvider>
  );
}