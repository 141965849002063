import { useSelector } from 'react-redux';
import { userSelectors } from '../../redux/user/selectors';
import { useMemo } from 'react';

export const useRoutingPermissions = (location) => {
  const user = useSelector(userSelectors.getUser);

  return useMemo(() => {
    let result = true;

    if (!user) {
      result = false;
    } else if (!user.routes.some(route => new RegExp(route, 'i').test(location.pathname))) {
      result = false;
    }

    return result;
  }, [user, location]);
};
