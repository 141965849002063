export const partnerConfigSelectors = {
  getOperators(state) {
    return state.partnersConfig.operators;
  },
  getRates(state) {
    return state.partnersConfig.rates;
  },
  getConfig(state) {
    return state.partnersConfig.config;
  },
  getGameList(state) {
    return state.partnersConfig.gameList;
  }
};
