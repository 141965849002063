import React, { useEffect, useState } from 'react';

export default React.memo(function DateFilter({ title, fieldName, callback, value }) {
  const [dates, setDates] = useState({
    min: '',
    max: '',
  });

  useEffect(() => {
    setDates(value);
  }, [value]);

  const onSelectDate = (e) => {
    setDates({ ...dates, [e.currentTarget.dataset.field]: e.currentTarget.value });
  };

  const onBlur = () => {
    callback(prev => ({
      ...prev,
      [fieldName]: dates
    }));
  };

  return (
    <div className={'filter'}>
      <span className={'filter_title'}>{title}</span>
      <div className={'filter_inputs'}>
        <div className={'filter_inputs_datepicker_box'}>
          <label className={dates.min ? '' : 'color_grey'}>{dates.min.replace('T', ' ') || 'Start date'}</label>
          <input className={'filter_inputs_datepicker'} value={dates.min} onBlur={onBlur}
                 onChange={onSelectDate} type={'datetime-local'} data-field={'min'} />
        </div>

        <div className={'filter_inputs_datepicker_box'}>
          <label className={dates.max ? '' : 'color_grey'}>{dates.max.replace('T', ' ') || 'End date'}</label>
          <input className={'filter_inputs_datepicker'} value={dates.max} onBlur={onBlur}
                 onChange={onSelectDate} type={'datetime-local'} data-field={'max'} />
        </div>
      </div>
    </div>
  );
})
