import React, { useState } from 'react';
import BaseModal from '../../../modals/baseModal';

export default React.memo(function AddCurrencyWindow({ visible, close, callback }) {
  const [currencies, setCurrencies] = useState('');

  const changeCurrency = (e) => {
    setCurrencies(e.target.value);
  };

  const closeAndReset = () => {
    setCurrencies('');
    close();
  };

  const add = () => {
    const currenciesArray = currencies.replace(/\s/g, '').split(',').map(currency => currency.toUpperCase());
    callback(currenciesArray);
    closeAndReset();
  };

  return (
    <BaseModal visible={visible} onClose={closeAndReset} showCloseButton>
      <div className={'modal modal_xs'}>
          <span>Currencies:</span>
          <span className={'color_grey center_text'}>One or few currencies separated by comma</span>
          <input value={currencies} onChange={changeCurrency} />

        <div className={'modal_controls'}>
          <button className={'button'} onClick={closeAndReset}>Cancel</button>
          <button className={'button'} onClick={add}>Add</button>
        </div>
      </div>
    </BaseModal>
  );
})
