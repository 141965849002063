import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo, useRef,
  useState
} from 'react';
import Info from '../../../../inputForms/info';
import AddCurrencyWindow from './addCurrencyWindow';
import GenerateToEurWindow from './generateToEurWindow';
import {useDispatch, useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../../../redux/partnersConfig/selectors';
import DropdownLayout from '../../../../dropdownLayout';
import {generateBets, roundBets} from '../../../../../utils/betsGenerator';
import {modalActions} from '../../../../../redux/modals/reducer';
import {modalTypes} from '../../../../../redux/modals/modalTypes';

import {ReactComponent as DeleteIcon} from '../../../../../assets/icons/uncheck.svg';

export default React.memo(forwardRef(function BetLevels({
                                                              config,
                                                              canChangeBlock,
                                                              betLevelsData,
                                                              availableBets,
                                                              setAvailableBets
                                                            }, ref) {
  const dispatch = useDispatch();
  const MAX_BETS_COUNT = useRef(20);
  const [showAddCurrencyWindow, setShowAddCurrencyWindow] = useState(false);
  const [showGenerateToEurWindow, setShowGenerateToEurWindow] = useState(false);
  const defaultFormData = useMemo(() => ({
    minBet: 0,
    maxBet: 0,
    betsCount: 0,
    generatorFunctionDegree: 2,
    currencies: '',
    currenciesArr: [],
    currenciesViewDecimals: {},
    currenciesDefaultBetIndexes: {},
  }), []);
  const rates = useSelector(partnerConfigSelectors.getRates);
  const [availableBetsView, setAvailableBetsView] = useState({});
  const [generate, setGenerate] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  const generateAvailableBets = useCallback((currencies) => {
    const result = {};
    if (!rates) return result;

    for (let currency of currencies) {
      let rate = rates[currency.toUpperCase()];
      if (currency === 'dog') {
        rate = rates['DOGE'];
      }
      if (!rate) {
        console.warn('Can`t find rate for currency:', currency);
        delete result[currency];
        continue;
      }
      const betsArray = generateBets(rate * formData.minBet, rate * formData.maxBet, formData.betsCount, formData.generatorFunctionDegree, currency);
      if (betsArray.length > 0) result[currency.toUpperCase()] = betsArray;
    }

    return result;
  }, [rates, formData.minBet, formData.maxBet, formData.betsCount, formData.generatorFunctionDegree]);

  const generateAvailableBetsToEur = useCallback((currencies) => {
    if (!availableBets['EUR']) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Eur available bets must be specified'));
      return;
    }

    const result = {};
    if (!rates) return result;

    for (let currency of currencies) {
      if (currency.toUpperCase() === 'EUR') continue;

      let rate = rates[currency.toUpperCase()];
      if (currency === 'dog') {
        rate = rates['DOGE'];
      }
      if (!rate) {
        console.warn('Can`t find rate for currency:', currency);
        delete result[currency];
        continue;
      }
      const betsArray = availableBets['EUR'].map(bet => bet * rate);
      if (betsArray.length > 0) result[currency.toUpperCase()] = betsArray;
    }

    return result;
  }, [dispatch, rates, availableBets]);

  const getMaxBetsCount = useCallback((availableBets) => {
    let betsCount = 0;
    if (availableBets) {
      for (let currency in availableBets) {
        const bets = availableBets[currency];
        if (bets.length > betsCount) {
          betsCount = bets.length;
        }
      }
    }
    return betsCount;
  }, []);

  const getMinBet = useCallback((availableBets) => {
    let minBet = 0;

    if (availableBets && rates) {
      for (const currency in availableBets) {
        if (!availableBets.hasOwnProperty(currency)) continue;
        const rate = rates[currency.toUpperCase()];
        if (!rate) continue;
        const currencyMinBet = availableBets[currency][0] / rate;
        if (minBet === 0 || minBet > currencyMinBet) {
          minBet = currencyMinBet;
        }
      }
    }

    return Math.round(minBet * 100) / 100;
  }, [rates]);

  const getMaxBet = useCallback((availableBets) => {
    let maxBet = 0;

    if (availableBets) {
      for (const currency in availableBets) {
        if (!availableBets.hasOwnProperty(currency)) continue;
        const rate = rates[currency.toUpperCase()];
        if (!rate) continue;
        const currencyMaxBet = availableBets[currency][availableBets[currency].length - 1] / rate;
        if (maxBet < currencyMaxBet) {
          maxBet = currencyMaxBet;
        }
      }
    }
    return Math.round(maxBet * 100) / 100;
  }, [rates]);

  const setAvailableBetsData = useCallback((config) => {
    if (!config) {
      setAvailableBetsView({});
      setFormData({...defaultFormData, currenciesArr: defaultFormData.currenciesArr});
      return;
    }

    setAvailableBetsView(config);

    const currenciesArr = (config && Object.keys(config).map(currency => currency.toUpperCase())) || defaultFormData.currenciesArr;
    const betsCount = (config && config['EUR'] && config['EUR'].length) || getMaxBetsCount(config) || defaultFormData.betsCount;
    const minBet = (config && config['EUR'] && config['EUR'][0]) || getMinBet(config) || defaultFormData.minBet;
    const maxBet = (config && config['EUR'] && config['EUR'].at(-1)) || getMaxBet(config) || defaultFormData.maxBet;

    setFormData({
      ...defaultFormData,
      generatorFunctionDegree: defaultFormData.generatorFunctionDegree,
      minBet,
      maxBet,
      betsCount,
      currenciesArr,
      currencies: (config && currenciesArr.join(', ')) || defaultFormData.currencies,
    })
  }, [setAvailableBetsView, setFormData, defaultFormData, rates, getMaxBetsCount, getMinBet, getMaxBet]);

  useEffect(() => {
    if (!generate) return;

    const newAvailableBets = generateAvailableBets(formData.currenciesArr);
    setAvailableBetsView(newAvailableBets);
    setAvailableBets(newAvailableBets);
    setGenerate(false);
  }, [generateAvailableBets, formData.currenciesArr, setAvailableBetsView, setAvailableBets, generate, setGenerate]);

  useEffect(() => {
    if (!rates) return;
    setAvailableBetsData(availableBets);
  }, [betLevelsData, setAvailableBetsData, rates]);

  //check DefaultBetIndexes with max betsCount
  useEffect(() => {
    const currencies = Object.keys(formData.currenciesDefaultBetIndexes);
    const newCurrencies = currencies.filter(currency => formData.currenciesDefaultBetIndexes[currency] < formData.betsCount);
    if (currencies.length !== newCurrencies.length) {
      const newCurrenciesDefaultBetIndexes = newCurrencies.reduce((prev, currency) => {
        prev[currency] = formData.currenciesDefaultBetIndexes[currency];
        return prev;
      }, {});
      setFormData(prev => ({
        ...prev,
        currenciesDefaultBetIndexes: newCurrenciesDefaultBetIndexes
      }))
    }
  }, [formData.currenciesDefaultBetIndexes, formData.betsCount])

  const onChangeMinBet = (e) => {
    setFormData(prev => ({...prev, minBet: e.target.value}));
  };

  const onChangeMaxBet = (e) => {
    setFormData(prev => ({...prev, maxBet: e.target.value}));
  };

  const onChangeBetsCount = (e) => {
    setFormData(prev => ({...prev, betsCount: Number(e.target.value)}));
  };

  const onChangeGeneratorFunctionDegree = (e) => {
    setFormData(prev => ({...prev, generatorFunctionDegree: e.target.value}));
  };

  const onChangeCurrencies = (e) => {
    setFormData(prev => ({
      ...prev,
      currencies: e.target.value,
    }));
  };

  const onBlurCurrencies = (e) => {
    const currentCurrenciesArr = e.target.value === '' ? defaultFormData.currenciesArr : e.target.value.replaceAll(' ', '').split(',');
    const currenciesArrWasDeleted = [];
    const bets = Object.keys(availableBets).reduce((res, key) => {
      if (currentCurrenciesArr.includes(key)) {
        res[key] = availableBets[key];
      } else {
        currenciesArrWasDeleted.push(key);
      }
      return res;
    }, {});

    setAvailableBets(bets);
    setAvailableBetsView(bets);

    setFormData(prev => {
      const currenciesArr = prev.currenciesArr.filter(key => !currenciesArrWasDeleted.includes(key));
      return {
        ...prev,
        currencies: currenciesArr.join(', '),
        currenciesArr,
      }
    });
  };

  const onChangeAvailableBet = (availableBet, currency, betIndex) => {
    setAvailableBetsView(prev => {
      const availableBetsByCurrency = Array.isArray(prev[currency]) ? [...prev[currency]] : [];
      availableBetsByCurrency[betIndex] = availableBet;
      return {
        ...prev,
        [currency]: availableBetsByCurrency
      }
    });
  };

  const onBlurAvailableBet = (bet, currency, betIndex) => {
    let availableBet = bet;
    if (bet > 0) {
      setAvailableBets(prev => {
        const availableBetsbyCurrency = Array.isArray(prev[currency]) ? [...prev[currency]] : [];
        availableBetsbyCurrency[betIndex] = +bet;

        return {
          ...prev,
          [currency]: availableBetsbyCurrency
        }
      });
    } else {
      availableBet = availableBets[currency][betIndex];
    }
    onChangeAvailableBet(availableBet, currency, betIndex)
  };
  useLayoutEffect(() => {
    if (!rates) return;
    setFormData(prev => {
      const currenciesArr = prev.currenciesArr === '' || prev.currenciesArr.length === 0 ? defaultFormData.currenciesArr : prev.currenciesArr;
      return {
        ...prev,
        currenciesArr
      }
    });

  }, [rates, setFormData, config, defaultFormData]);

  const reset = () => {
    setAvailableBetsData(availableBets);
  };

  const onGenerateClick = () => {
    setGenerate(true);
  };

  const openAddCurrencyWindow = () => {
    setShowAddCurrencyWindow(true);
  };

  const openGenerateToEurWindow = () => {
    setShowGenerateToEurWindow(true);
  };

  const closeAddCurrencyWindow = () => {
    setShowAddCurrencyWindow(false);
  };

  const closeGenerateToEurWindow = () => {
    setShowGenerateToEurWindow(false);
  };

  const addCurrenciesAndBets = (currencies, bets) => {
    setAvailableBets(prev => ({...prev, ...bets}));
    setAvailableBetsView(prev => ({...prev, ...bets}));
    setFormData(prev => {
      const currenciesArr = Array.from(new Set([...prev.currenciesArr, ...currencies]));
      return {
        ...prev,
        currenciesArr,
        currencies: currenciesArr.join(', '),
      }
    });
  };

  const tryToAddCurrencies = (currencies) => {
    const alreadyExistList = [];
    const newList = [];

    for (let currency of currencies) {
      if (availableBets.hasOwnProperty(currency)) {
        alreadyExistList.push(currency);
      } else {
        newList.push(currency);
      }
    }

    if (newList.length) addCurrenciesAndBets(newList, generateAvailableBets(newList));

    if (alreadyExistList.length) {
      dispatch(modalActions.showModal(modalTypes.CONFIRM, {
        text: `${alreadyExistList.join(', ')} already exist in available bets list, override?`,
        buttons: [
          {label: 'Cancel'},
          {
            label: 'Yes',
            callback: () => addCurrenciesAndBets(alreadyExistList, generateAvailableBets(alreadyExistList))
          }
        ]
      }));
    }
  };

  const tryToGenerate = (currencies) => {
    const alreadyExistList = [];
    const newList = [];

    for (let currency of currencies) {
      if (availableBets.hasOwnProperty(currency.toUpperCase())) {
        alreadyExistList.push(currency);
      } else {
        newList.push(currency);
      }
    }

    if (alreadyExistList.length) {
      const bets = generateAvailableBetsToEur(alreadyExistList);
      for (let currency in bets) {
        bets[currency] = roundBets(bets[currency]);
      }
      addCurrenciesAndBets(alreadyExistList, bets);
    }

    if (newList.length) {
      dispatch(modalActions.showModal(modalTypes.ERROR, `Currencies not found: ${newList.join(', ')}`));
    }
  };

  const deleteCurrencyAndBets = (currency) => {
    const bets = Object.keys(availableBets).reduce((res, key) => {
      if (key !== currency) {
        res[key] = availableBets[key];
      }
      return res;
    }, {});

    setAvailableBets(bets);
    setAvailableBetsView(bets);
    setFormData(prev => {
      const currenciesArr = prev.currenciesArr.filter(curr => curr !== currency);
      return {
        ...prev,
        currenciesArr,
        currencies: currenciesArr.join(', '),
      }
    });
  };

  const showConfirmDeleting = (e) => {
    const currency = e.currentTarget.dataset.currency;
    dispatch(modalActions.showModal(modalTypes.CONFIRM, {
      text: `Delete
      '${currency}' from available bets?`,
      buttons: [
        {label: 'Cancel'},
        {label: 'Yes', callback: () => deleteCurrencyAndBets(currency)}
      ]
    }));
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <>
      <div className={'input_form'}>
        <span className={'input_form_title__size_fixed'}>minBet</span>
        <input type={'number'} value={formData.minBet} onChange={onChangeMinBet}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <div className={'input_form'}>
        <span className={'input_form_title__size_fixed'}>maxBet</span>
        <input type={'number'} value={formData.maxBet} onChange={onChangeMaxBet}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <div className={'input_form'}>
        <span className={'input_form_title__size_fixed'}>betsCount</span>
        <input type={'number'} value={formData.betsCount || ''} onChange={onChangeBetsCount}
               disabled={!canChangeBlock}/>
        <Info info={'Max count: 20'} visibility={formData.betsCount > MAX_BETS_COUNT.current}/>
      </div>
      <div className={'input_form'}>
        <span className={'input_form_title__size_fixed'}>generatorFunctionDegree</span>
        <input type={'number'} value={formData.generatorFunctionDegree} onChange={onChangeGeneratorFunctionDegree}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <div className={'input_form'}>
        <span className={'input_form_title'}>currencies</span>
        <input className={'big_input'}
               type={'string'}
               value={formData.currencies}
               onChange={onChangeCurrencies}
               onBlur={onBlurCurrencies}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>

      <div>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={onGenerateClick}>Generate All
        </button>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={openGenerateToEurWindow}>Update from EUR
        </button>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={openAddCurrencyWindow}>Add currency
        </button>

        <AddCurrencyWindow visible={showAddCurrencyWindow} close={closeAddCurrencyWindow}
                           callback={tryToAddCurrencies}/>
        <GenerateToEurWindow visible={showGenerateToEurWindow} close={closeGenerateToEurWindow}
                             callback={tryToGenerate}/>
      </div>

      <DropdownLayout title={'Bets'}>
        <div className="available_bets_bets_block">
          <div>
            <div className={'input_form available_bets_row'}></div>
            {formData.currenciesArr && formData.currenciesArr.map(currency =>
            <div key={currency} className={'input_form available_bets_row'}>
              <span className={'available_bets_row_currency'}>
                <button onClick={showConfirmDeleting} disabled={!canChangeBlock} data-currency={currency}>
                  <DeleteIcon/>
                </button>
                {currency}
              </span>
              <span className={'input_form_subtitle'}>Range:</span>
            </div>
            )}
          </div>
          <div className="available_bets_bets_List">
            <div className={'input_form available_bets_row'}>
              {
                (new Array(formData.betsCount)).fill().map((_, betIndex) => <div className={'bet_index'} key={'bet_index' + betIndex}>{betIndex}</div>)
              }
            </div>
            {formData.currenciesArr && formData.currenciesArr.map(currency =>
                <div key={currency} className={'input_form available_bets_row'}>
                  {(new Array(formData.betsCount)).fill().map((_, betIndex) => (
                      <input key={currency + betIndex} type={'number'}
                             value={
                               availableBetsView[currency]
                               && availableBetsView[currency][betIndex] !== undefined
                                 ? availableBetsView[currency][betIndex]
                                 : ''
                             }
                             onChange={(e) => onChangeAvailableBet(e.target.value, currency, betIndex)}
                             onBlur={(e) => onBlurAvailableBet(e.target.value, currency, betIndex)}
                             disabled={!canChangeBlock}
                      />
                    )
                  )}
                </div>
            )}
          </div>
          <div>
            <div className={'input_form available_bets_row'}></div>
            {formData.currenciesArr && formData.currenciesArr.map(currency =>
              <div key={currency} className={'input_form available_bets_row'}>
                <Info info={''}/>
              </div>
            )}
          </div>
        </div>
      </DropdownLayout>

    </>
  );
}));
