import { toDecimals } from "./toDecimals";

export const format = (value, decimals, hideFractionIfPossible = true) => {
  const splitValue = value.toFixed(decimals).split('.');
  let fraction = '';
  if (decimals === 2) {
    if (!hideFractionIfPossible || parseFloat(splitValue[1]) !== 0) {
      fraction = '.' + splitValue[1];
    }
  } else if (decimals > 0 && parseFloat(splitValue[1]) !== 0) {
    fraction = '.' + splitValue[1].replace(/0*$/, '');
  }
  const mainValue = _format(splitValue[0]);

  return `${mainValue}${fraction}`;
};

export const formatCoins = (value, decimals, currency, convertToDecimals) => {
  value = convertToDecimals ? toDecimals(value, decimals) : value;
  let formatedValue = format(value, decimals, false);
  if (currency) formatedValue = `${currency.toUpperCase()} ${formatedValue}`;
  return formatedValue;
}

function _format(value) {
  let result = [];
  for (let i = value.length - 1; i >= 0; i--) {
    if (i !== value.length - 1 && (value.length - 1 - i) % 3 === 0) result.unshift(' ');
    result.unshift(value[i]);
  }
  return result.join('');
}
