import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import eOperatorsConfigsFieldName from '../eOperatorsConfigsFieldName';

export default React.memo(forwardRef(function CustomResourcePool({
                                                        configForView,
                                                        canChangeBlock,
                                                        canOverride,
                                                        setSaveFunctionPool,
                                                        overriddenBlocks,
                                                        toggleOverriddenField,
                                                        setValueToConfigForView
                                                      }, ref) {
  const defaultValues = useMemo(() => ({
    enabled: false,
    name: '',
  }), []);
  const [formData, setFormData] = useState(defaultValues);

  const saveFunction = useCallback(() => {
    let data = {};
    if ((configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] === configForView.type) && (formData.enabled || formData.name)) {
      data[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] = {
        enabled: formData.enabled,
        name: formData.name ||  defaultValues.name
      };
    }

    return data;
  }, [overriddenBlocks, formData, defaultValues, configForView.isGlobalConfig, configForView.type]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] : defaultValues;
    setFormData({
      enabled: value && value.hasOwnProperty('enabled') ? value.enabled : defaultValues.enabled,
      name: value && value.hasOwnProperty('name') ? value.name : defaultValues.name,
    });
  }, [configForView.config, setFormData, defaultValues]);

  const toggleCheckStatus = useCallback((value) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL,  {...formData, enabled: value});
  }, [setValueToConfigForView, configForView.type, formData]);

  const changeInterval = useCallback((e) => {
    setFormData(prev => ({...prev, name: e.target.value}));
  }, [setFormData]);

  const onBlurText = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL, {...formData, name: e.target.value});
  }, [setValueToConfigForView, configForView.type, formData]);

  const checkOverride = useCallback(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] : defaultValues;
    setFormData({
      enabled: value && value.hasOwnProperty('enabled') ? value.enabled : defaultValues.enabled,
      name: value && value.hasOwnProperty('name') ? value.name : defaultValues.name,
    });
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL);
  }, [setFormData, toggleOverriddenField, configForView, defaultValues]);

  const reset = useCallback(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] : defaultValues;
    setFormData({
      enabled: value && value.hasOwnProperty('enabled') ? value.enabled : defaultValues.enabled,
      name: value && value.hasOwnProperty('name') ? value.name : defaultValues.name,
    });
  }, [configForView.config, defaultValues, setFormData]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>
      {!configForView.isGlobalConfig && canOverride &&
        <div className={'operators_configs_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL]}
          />
        </div>}

      <span className={'operators_configs_body_form_box_title'}>Custom Resource Pool</span>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Enabled</span>
        <Checkbox checked={!!formData.enabled} setChecked={toggleCheckStatus}
                  id={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL}_enabled`} disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Name</span>
        <input type={'text'} className={'medium_input'} value={formData.name} onChange={changeInterval} onBlur={onBlurText}
               disabled={!canChangeBlock || !formData.enabled}/>
        <Info info={''}/>
      </div>
    </div>
  );
}));
