import React, { useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { modalTypes } from '../../../redux/modals/modalTypes';
import { modalActions } from '../../../redux/modals/reducer';
import { managerRest } from '../../../index';
import useUserManagerModel, { defaultPermissions } from './model';
import { getEnvironment, getEnvironmentDomainSuffix } from '../../../utils/environment';

const initialForm = {
  username: '',
  password: '',
  role: '',
  permissions: defaultPermissions
};

export default React.memo(forwardRef(function Registration({ openEmailForm }, ref) {
  const dispatch = useDispatch();
  const {
    roles,
    formData,
    setFormData,
    changeRole,
    changeUsername,
    changePassword,
    getInputByKey,
  } = useUserManagerModel(initialForm);

  const reset = () => {
    setFormData(initialForm);
  };

  const submit = useCallback(async () => {
    if (!formData.username || !formData.password || typeof formData.role !== 'number') {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Username, password and role should be specified.'));
      return false;
    }
    if (formData.username.length < 4) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Username must contain at least 4 characters.'));
      return false;
    }
    if (formData.password.length < 6) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Password must contain at least 6 characters.'));
      return false;
    }
    if (formData.hasOwnProperty('operator_id') && formData.operator_id === '') {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Operator should be specified.'));
      return false;
    }
    if (formData.hasOwnProperty('game_bundle') && formData.game_bundle === '') {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Game should be specified.'));
      return false;
    }
    if (formData.permissions.operators.length && formData.permissions.operators.some(({ id }) => id === '')) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Operator should be specified.'));
      return false;
    }

    const requestData = {
      ...formData,
      role: Number(formData.role),
    };

    const success = await managerRest.register(requestData);

    if (success) openEmailForm({
      to: formData.username,
      subject: `Onlyplay backoffice ${getEnvironment()} credentials`,
      text: `Hello!\n
Here is your ${getEnvironment().toUpperCase()} credentials to access backoffice:\n
username: ${formData.username}
password: ${formData.password}
link: https://backoffice.${getEnvironmentDomainSuffix() ? getEnvironmentDomainSuffix().concat('.') : ''}onlyplay.net/
\nBest Regards,
Onlyplay Tech Team`
    });

    return success;
  }, [dispatch, formData, openEmailForm]);

  useImperativeHandle(ref, () => ({
    submit,
    reset,
  }));

  return (
    <>
      <div className={'modal_body'}>

        <div className={'form_block'}>
          <label>Username:</label>
          <input value={formData.username} onChange={changeUsername} data-field={'username'} />
        </div>

        <div className={'form_block'}>
          <label>Password:</label>
          <input value={formData.password} onChange={changePassword} data-field={'password'} />
        </div>

        <div className={'form_block'}>
          <label>Role:</label>
          <select value={formData.role} onChange={changeRole}>
            <option value={''} />
            {roles.length && roles.map(role =>
              <option key={role.id} value={role.id}>{role.title}</option>
            )}
          </select>
        </div>

        <div className={'form_block'}>
          <label>Permissions:</label>

          <div className={'w-3/5 p-2 border border-gray-200 rounded'}>
            <div className={'form_block'}>
              <label className={'!w-full'}>Operators:</label>
              {getInputByKey('operators')}
            </div>
            <div className={'form_block'}>
              <label className={'!w-full'}>Games:</label>
              {getInputByKey('games')}
            </div>
          </div>
        </div>

      </div>
    </>
  );
}));
