import { modalActions } from '../redux/modals/reducer';
import { modalTypes } from '../redux/modals/modalTypes';
import { userActions } from '../redux/user/reducer';
import { appActions } from '../redux/app/reducer';
import { globalLoadingFetchTypes } from './globalLoadingFetchTypes';
import { partnersConfigActions } from '../redux/partnersConfig/reducer';
import { getFromStorage, setToStorage } from '../utils/storage';
// import Cache from './cache';
import { createId } from '../utils/transactionHelpers';
import { operatorsConfigsActions } from '../redux/operatorsConfigs/reducer';
import { eStorageKeys } from '../enums/eStorageKeys';

export default class RestApi {
  constructor(store) {
    this.store = store;
    // this.cache = new Cache();
    this.domain = `https://${window.location.hostname}/api`;
    if ((/(localhost|192.168.1.54)/i).test(this.domain))
      // this.domain = `http://${window.location.hostname}:8083/api`;
    this.domain = `https://backoffice.dev.onlyplay.net/api`;

    this.storageTokenKey = eStorageKeys.ESTK_AUTH;
    this.checkAuth();
  }

  checkAuth = async () => {
    const token = getFromStorage(this.storageTokenKey);
    if (!token) {
      this.store.dispatch(modalActions.showModal(modalTypes.LOGIN));
      return;
    }

    const data = { token };

    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.AUTH));
      const response = await this.postRequest('/users/checkauth', data);
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.AUTH));
      if (response.success) {
        setToStorage(this.storageTokenKey, response.token);
        this.store.dispatch(userActions.setUser(response.user));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.LOGIN));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  login = async (data) => {
    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.LOGIN));
      const response = await this.postRequest('/users/login', data);
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.LOGIN));

      if (response.success) {
        setToStorage(this.storageTokenKey, response.token);
        this.store.dispatch(userActions.setUser(response.user));
        this.store.dispatch(modalActions.closeModal(modalTypes.LOGIN));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  register = async (data) => {
    try {
      const response = await this.postRequest('/users/register', data);
      if (response.success) {
        return response.success;
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getUserRoles = async () => {
    try {
      const response = await this.postRequest('/users/get_roles');
      if (response.success) {
        this.store.dispatch(appActions.setRoles(response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getOptions = async () => {
    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.OPTIONS));
      const response = await this.postRequest('/txs/options');
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.OPTIONS));

      if (response.success) {
        this.store.dispatch(appActions.setOptions(response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getRounds = async (page, filters) => {
    const data = {
      page,
      filters
    };

    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.ROUNDS));
      const response = await this.postRequest('/txs/list', data);
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.ROUNDS));

      if (response.success) {
        this.store.dispatch(appActions.setRounds(response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getTransactions = async (id, region, date) => {
    const data = {
      id,
      region,
      year: date.year(),
      month: date.month() + 1,
    };

    try {
      const response = await this.postRequest('/txs/info', data);
      if (response.success) {
        this.store.dispatch(appActions.setTxs(createId(id, region), response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getOperators = async () => {
    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.OPERATORS));
      const response = await this.postRequest('/common/get_operators');
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.OPERATORS));

      if (response.success) {
        this.store.dispatch(partnersConfigActions.setOperators(response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  async getCasinos() {
    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.CASINO));
      const response = await this.postRequest('/common/get_casino_list');
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.CASINO));

      if (response.success) {
        this.store.dispatch(appActions.setCasinoList(response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  }

  getUsers = async () => {
    try {
      const response = await this.postRequest('/users/users_list');
      if (response.success) {
        return response.data;
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getUsersData = async () => {
    try {
      const response = await this.postRequest('/users/users_data_list');
      if (response.success) {
        return response.data;
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getPartnerConfig = async (partner) => {
    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.PROVIDERS));
      const response = await this.postRequest('/settings/get_partner_config', {
        partner
      });
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.PROVIDERS));
      if (response.success) {
        console.log(response.data);
        this.store.dispatch(partnersConfigActions.setConfig(response.data));
      } else {
        this.store.dispatch(partnersConfigActions.setConfig(null));
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getOperatorsConfigs = async (operator_id, casino_id, bundle) => {
    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.PROVIDERS));
      const response = await this.postRequest('/settings/get_operators_configs', {
        operator_id,
        casino_id,
        bundle
      });
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.PROVIDERS));
      if (response.success) {
        console.log(response.data);
        this.store.dispatch(operatorsConfigsActions.setConfigs(response.data));
      } else {
        this.store.dispatch(operatorsConfigsActions.setConfigs(null));
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getRates = async () => {
    try {
      let response = await fetch('https://analytics.onlyplay.net/rates.json');

      response = await response.json();
      this.store.dispatch(partnersConfigActions.setRates(response));
    } catch (e) {
      console.error(e);
    }
  };

  getCertifications = async () => {
    try {
      let response = await this.postRequest('/common/get_certifications');
      if (response.success) {
        this.store.dispatch(appActions.setCertifications(response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e);
    }
  };

  getGameList = async (providers) => {
    try {
      const response = await this.postRequest('/common/get_games_list', {
        providers
      });
      if (response.success) {
        console.log(response.data);
        this.store.dispatch(partnersConfigActions.setGamesList(response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  updateUser = async (data) => {
    try {
      const response = await this.postRequest('/users/update', data);
      if (response.success) {
        return response.success
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };


  deleteUser = async (data) => {
    try {
      const response = await this.postRequest('/users/delete', data);
      if (response.success) {
        return response.success
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  closeRound = async (id, region, winAmount) => {
    try {
      const response = await this.postRequest('/txs/close_round', { id, region, winAmount });
      if (response.success) {
        this.store.dispatch(appActions.updateRound(response.data.round));
        response.data.tx && this.store.dispatch(appActions.updateTxs(createId(id, region), response.data.tx));

        this.store.dispatch(modalActions.closeModal(modalTypes.CLOSE_ROUND));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  setPartnerConfig = async (partner, config) => {
    try {
      const response = await this.postRequest('/settings/set_partner_config', {
        partner,
        config,
      });
      if (response.success) {
        console.log(response.data);
        this.store.dispatch(partnersConfigActions.setConfig(response.data));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  setOperatorsConfigs = async (configs, operator_id, casino_id, bundle) => {
    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.PROVIDERS));
      const response = await this.postRequest('/settings/set_operators_configs', {
        configs,
        operator_id,
        casino_id,
        bundle
      });
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.PROVIDERS));
      if (response.success) {
        console.log(response.data);
        this.store.dispatch(operatorsConfigsActions.setConfigs(response.data));
        this.store.dispatch(modalActions.showModal(modalTypes.MESSAGE, 'SAVED'));
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  downloadRounds = async (filters) => {
    const data = { filters };

    try {
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.DOWNLOAD));
      const response = await this.postRequest('/txs/generate_csv', data);
      this.store.dispatch(appActions.setLoading(globalLoadingFetchTypes.DOWNLOAD));
      if (response.success) {
        window.open(response.link);
      } else {
        this.store.dispatch(modalActions.showModal(modalTypes.ERROR, response.message));
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getSessionParams = async (hash, options) => {
    try {
      const response = await this.postRequest(`/rounds_history/get_session_params?hash=${hash}`, undefined, options);
      if (response.success) {
        return response.data;
      }
    } catch (e) {
      console.error(e.message);
    }
  }

  getRoundsHistory = async ({ hash, intervalStart, intervalEnd, page }, options) => {
    const data = { intervalStart, intervalEnd, page };

    try {
      const response = await this.postRequest(`/rounds_history/get_rounds_history?hash=${hash}`, data, options);
      if (response.success) {
        return response.data;
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  getRoundDetails = async ({ hash, id }, options) => {
    const data = { id };

    try {
      const response = await this.postRequest(`/rounds_history/get_round_details?hash=${hash}`, data, options);
      if (response.success) {
        return response.data;
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  sendTextEmail = async (data, options) => {
    try {
      const response = await this.postRequest(`/utility/send_text_email`, data, options);
      return response.success;
    } catch (e) {
      console.error(e.message);
    }
  };

  postRequest = async (url, data, options) => {
    try {
      const requestData = { url, data, options };
      // const responseFromCache = this.cache.tryToGetFromStorage(requestData);
//      if (responseFromCache) return responseFromCache;

      const response = await fetch(this.domain + url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'content-type': 'application/json',
          'token': getFromStorage(this.storageTokenKey) || '',
        },
        ...options,
      });

      const parsedResponse = await response.json();
      if (parsedResponse.success) {
        // this.cache.writeToStorage(requestData, parsedResponse);
      } else {
        throw new Error(parsedResponse.message || response.statusText);
      }

      return parsedResponse;
    } catch (e) {
      return this.handleError(e)
    }
  };

  getRequest = async (url, options) => {
    try {
      const response = await fetch(this.domain + url, {
        headers: {
          'token': getFromStorage(this.storageTokenKey) || '',
        },
        ...options
      });

      return await response.json();
    } catch (e) {
      return this.handleError(e);
    }
  };

  handleError(e) {
    console.error(e);
    return { success: false, message: e.message };
  }
}
