import React, { useCallback, useEffect, useMemo, useState } from 'react';

export default React.memo(function SearchSelectFilter({ title, fieldName, callback, options, defaultValue = '', className = '', placeholder = 'All' }) {
  const [optionsList, setOptionsList] = useState(null);
  const [searchValue, setSearchValue] = useState(defaultValue);
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputInFocus, setInputInFocus] = useState(false);

  const hideDropdown = useCallback((e) => {
    if (e.target.closest(`#search_select_input_${title}`) || e.target.closest(`#search_select_dropdown_${title}`) || inputInFocus) return;

    setShowDropdown(false);
  }, [setShowDropdown, title, inputInFocus]);

  useEffect(() => {
    const newOptions = options.map(option => String(option));
    setOptionsList(newOptions);
    if(newOptions && !newOptions.includes(searchValue)) {
      setSearchValue(defaultValue);
      callback(prev => ({
        ...prev,
        [fieldName]: defaultValue
      }));
    }
  }, [options]);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener('click', hideDropdown, true);
    } else {
      document.removeEventListener('click', hideDropdown);
    }

    return () => document.removeEventListener('click', hideDropdown);
  }, [hideDropdown, showDropdown]);

  const filteredOptions = useMemo(() => {
    if (!optionsList) return [];

    return optionsList.filter(el => el.toUpperCase().includes(searchValue.toUpperCase()));
  }, [optionsList, searchValue]);

  const onInputChange = (e) => {
    setSearchValue(e.currentTarget.value);
  };

  const onInputFocus = () => {
    setInputInFocus(true);
    setShowDropdown(true);
  };

  const onInputBlur = () => {
    setInputInFocus(false);

    callback(prev => ({
      ...prev,
      [fieldName]: searchValue
    }));
  };

  const onSelect = (e) => {
    const value = e.currentTarget.dataset.value;

    setSearchValue(value);

    callback(prev => ({
      ...prev,
      [fieldName]: value
    }));

    setShowDropdown(false);
  };

  const startAnimation = (e) => {
    const child = e.currentTarget.querySelector('span');
    const offset = e.currentTarget.scrollWidth - e.currentTarget.offsetWidth;
    if (!offset) return;

    child.animate([
      {transform: 'translateX(0)'},
      {transform: `translateX(-${offset}px)`},
    ], {
      duration: 2000,
      iterations: Infinity,
      direction: 'alternate'
    });
  };

  const stopAnimation = (e) => {
    const child = e.currentTarget.querySelector('span');
    const animations = child.getAnimations();
    if (!animations.length) return;

    animations[0].cancel();
  };

  return (
    <div className={`filter ${className}`}>
      <span className={'filter_title'}>{title}</span>
      <div className={'filter_inputs relative'}>
        <input id={`search_select_input_${title}`} placeholder={placeholder} value={searchValue} onChange={onInputChange}
               autoComplete={'off'} onFocus={onInputFocus} onBlur={onInputBlur} />

        {showDropdown && !!filteredOptions.length &&
        <div id={`search_select_dropdown_${title}`} className={'search_filter_dropdown'}>
          <div onPointerDown={onSelect} className={'search_filter_dropdown_option'} data-value={''}>
            {placeholder}
          </div>

          {filteredOptions.map((el, i) =>
            <div key={i} onPointerDown={onSelect} onMouseEnter={startAnimation} onMouseLeave={stopAnimation} className={'search_filter_dropdown_option'} data-value={el}>
              <span>
                {el}
              </span>
            </div>
          )}
        </div>}

      </div>
    </div>
  );
})
