import React from "react";
import LoadingIndicator from './indicator';

export default function Preloader() {
    return (
        <div className={'preloader'}>
            <LoadingIndicator />
            <span className={'preloader_label'}>
                Loading...
            </span>
        </div>
    );
}
