import React from 'react';
import { Redirect } from 'react-router-dom';
import Preloader from '../loading/preloader';
import { useDetailsModel } from './detailsModel';

export default function RoundDetails({ roundId }) {
  const { redirect, loading, frameRef } = useDetailsModel(roundId);

  return redirect ? <Redirect to={'/404'} /> : <div className={'rounds_history_details'}>
    {loading && <Preloader />}
    <iframe ref={frameRef} src={`${process.env.REACT_APP__RESULTS_VIEW_ORIGIN}/round-info`} />
  </div>;
}
