import { useCallback, useEffect, useState } from 'react';
import { managerRest } from '../../index';
import { useOpenRouteContext } from '../../contextProviders/openRouteContext';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { openRouteSelectors } from '../../redux/openRoute/selectors';
import { openRouteActions } from '../../redux/openRoute/reducer';

const useRoundsModel = () => {
  const { sessionParams, urlParams, setUrlParams, isFetching } = useOpenRouteContext();
  const [loading, setLoading] = useState(true);
  const data = useSelector(openRouteSelectors.getRounds);
  const dispatch = useDispatch();

  const handlePageChange = useCallback((page) => {
    setUrlParams(prev => ({ ...prev, page }));
  }, [setUrlParams]);

  const handleDatesChange = useCallback((_, range) => {
    setUrlParams(prev => ({ ...prev, intervalStart: range[0], intervalEnd: range[1] }));
  }, [setUrlParams]);

  useEffect(() => {
    if (urlParams.intervalStart && urlParams.intervalEnd) return

    setUrlParams(prev => ({
      ...prev,
      intervalStart: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
      intervalEnd: dayjs().format('YYYY-MM-DD')
    }));
  }, [urlParams, setUrlParams]);

  useEffect(() => {
    if (!urlParams.intervalStart || !urlParams.intervalEnd) return;

    let mounted = true;
    const abortController = new AbortController();

    const getRounds = async () => {
      setLoading(true);
      const data = await managerRest.getRoundsHistory({
        hash: urlParams.hash,
        intervalStart: urlParams.intervalStart,
        intervalEnd: dayjs(urlParams.intervalEnd).add(1, 'days').format('YYYY-MM-DD'), //ensure that all end day will be in range
        page: urlParams.page
      }, { signal: abortController.signal });
      if (!mounted) return;

      if (data) dispatch(openRouteActions.setRounds(data));
      setLoading(false);
    }

    getRounds();

    return () => {
      mounted = false;
      abortController.abort();
    }
  }, [urlParams, dispatch]);

  const intervalRange = urlParams.intervalStart && urlParams.intervalEnd ? [dayjs(urlParams.intervalStart), dayjs(urlParams.intervalEnd)] : undefined;

  return {
    sessionParams,
    urlParams,
    loading,
    isFetching,
    intervalRange,
    list: data.list,
    totalCount: data.totalCount,
    handlePageChange,
    handleDatesChange
  };
}

export {
  useRoundsModel,
}
