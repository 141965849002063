import React, { useEffect, useMemo, useState } from 'react';
import BaseModal from './baseModal';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '../../redux/modals/selectors';
import { modalTypes } from '../../redux/modals/modalTypes';
import { modalActions } from '../../redux/modals/reducer';
import { managerRest } from '../../index';

export default React.memo(function CloseRound() {
  const modal = useSelector(getModalData(modalTypes.CLOSE_ROUND));
  const [winAmount, setWinAmount] = useState('');
  const [error, setError] = useState('');
  const [errorTimeout, setErrorTimeout] = useState(null);
  const dispatch = useDispatch();

  const data = useMemo(() => modal.data || {}, [modal.data]);

  useEffect(() => {
    if (!Object.keys(data).length) return;

    setWinAmount(data.win_amount);
  }, [data, modal.visible]);

  useEffect(() => {
    if (!error) return;
    setErrorTimeout(setTimeout(() => setError(''), 4000));
  }, [error]);

  useEffect(()  =>  {
    if (!errorTimeout) return;

    clearTimeout(errorTimeout);
    setError('');
    setErrorTimeout(null);
  }, [winAmount, modal.visible]);

  const closeModal = () => {
    dispatch(modalActions.closeModal(modalTypes.CLOSE_ROUND));
  };

  const changeInputValue = (e) => {
    setWinAmount(e.target.value);
  };

  const checkInput = () => {
    if (winAmount === '') {
      setError('Incorrect value provided');
      return setWinAmount(data.win_amount);
    }

    let num = Number(winAmount);
    if (!num && num !== 0) {
      num = -1;
    }
    if (Number(data.win_amount) > num || (winAmount[0] === '0' && winAmount[1] !== '.')) {
      setError('Incorrect value provided');
      return setWinAmount(data.win_amount);
    }
  };

  const closeRound = () => {
    if (error) return;
    console.log(winAmount);
    managerRest.closeRound(data.id, data.region, winAmount);
  };

  return (
    <BaseModal visible={modal.visible} onClose={closeModal} showCloseButton>
      <div className={'modal modal_s'}>
        <div className={'modal_header'}>
          Confirm closing round {data.id}
        </div>

        <div className="modal_body">
          <span className={'tip'}>You can only increase the value!</span>
          <div className="form_block relative">
            <label>Win amount</label>
            <input value={winAmount} onChange={changeInputValue} onBlur={checkInput} />
            <span className={'currency_label'}>
              {data.currency}
            </span>
            <span className={'form_block_error'}>
              {error}
            </span>
          </div>
        </div>

        <div className="modal_controls">
          <span onClick={closeModal} className="button">Cancel</span>
          <span onClick={closeRound} className="button">Close round</span>
        </div>

      </div>
    </BaseModal>
  );
})
