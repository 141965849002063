import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import AddCheckboxLayout from '../../../inputForms/addCheckboxLayout';
import eOperatorsConfigsFieldName from '../../eOperatorsConfigsFieldName';
import MessageEvent from './messageEvent';
import eventsParams from './eventsParams';

export default React.memo(forwardRef(function MessageEvents({
                                                              configForView,
                                                              canChangeBlock,
                                                              canOverride,
                                                              setSaveFunctionPool,
                                                              overriddenBlocks,
                                                              toggleOverriddenField,
                                                              setValueToConfigForView
                                                            }, ref) {
  const [formData, setFormData] = useState({});

  const saveFunction = useCallback(() => {
    const data = {};
    const eventsData = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS] === configForView.type) {
      Object.keys(formData).forEach((eventName => {
        if (formData[eventName] === true || (Array.isArray(formData[eventName]) && formData[eventName].length === 0)) {
          eventsData[eventName] = true;
        } else if (formData[eventName] && Array.isArray(formData[eventName])) {
          eventsData[eventName] = formData[eventName]
        }
      }));
    }
    if (Object.keys(eventsData).length) data.messageEvents = eventsData;
    return data;
  }, [overriddenBlocks, formData, configForView.type, configForView.isGlobalConfig]);

  const parseConfig = useCallback((obj) => {
    const newObj = Object.keys(obj).reduce((prevObj, key) => {
      if (obj[key]) {
        prevObj[key] = obj[key] === true ? [] : [...obj[key]];
      }
      return prevObj;
    }, {})
    setFormData(newObj);
  }, [setFormData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS] : {};
    parseConfig(value);
  }, [configForView.config, parseConfig]);

  const checkOverride = useCallback(() => {
    toggleOverriddenField(configForView.type, eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS);
  }, [toggleOverriddenField, configForView.type]);

  const onSelectParam = useCallback((selectedParams, eventsName) => {
    setFormData(prev => ({...prev, [eventsName]: selectedParams}));
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS, { ...formData, [eventsName]: selectedParams});
  }, [formData, setValueToConfigForView, configForView.type]);


  const onEventChecked = useCallback((checked, eventsName) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS, { ...formData, [eventsName]: checked ? true : undefined});
  }, [formData, setValueToConfigForView, configForView.type]);

  const reset = useCallback(() => {
    const value = configForView.config[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS] !== undefined ? configForView.config[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS] : {};
    parseConfig(value);
  }, [configForView.config, parseConfig]);

  useImperativeHandle(ref, () => ({
    reset
  }));


  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>
      {!configForView.isGlobalConfig && canOverride &&
        <div className={'operators_configs_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS]}
          />
        </div>}
      <span className={'operators_configs_body_form_box_title'}>Message events</span>
      {Object.keys(eventsParams).map((eventName, index) => (
        <MessageEvent
          key={eventName}
          eventName={eventName}
          params={eventsParams[eventName]}
          selected={formData[eventName]}
          disabled={!canChangeBlock}
          onSelect={onSelectParam}
          checked={!!formData[eventName]}
          onChecked={(boolean) => onEventChecked(boolean, eventName)}
          id={configForView.type}
        />
      ))}
    </div>
  );
}));
