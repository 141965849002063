import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo, useRef,
  useState
} from 'react';
import Info from '../../../inputForms/info';
import AddCurrencyWindow from './addCurrencyWindow';
import GenerateToEurWindow from './generateToEurWindow';
import {useDispatch, useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../../redux/partnersConfig/selectors';
import ePartnerConfigFieldName from '../../ePartnerConfigFieldName';
import AddCheckboxLayout from '../../../inputForms/addCheckboxLayout';
import DropdownLayout from '../../../dropdownLayout';
import {generateBets, roundBets} from '../../../../utils/betsGenerator';
import {modalActions} from '../../../../redux/modals/reducer';
import {modalTypes} from '../../../../redux/modals/modalTypes';
import {integerInputRegExp} from '../../../../utils/regexp/numbers';

import {ReactComponent as DeleteIcon} from '../../../../assets/icons/uncheck.svg';

export default React.memo(forwardRef(function AvailableBets({
                                                              config,
                                                              canChangeBlock,
                                                              canOverride,
                                                              setSaveFunctionPool,
                                                              selectedCasino,
                                                              overriddenBlocks,
                                                              setOverriddenBlocks,
                                                              getConfigOrCasinoConfig
                                                            }, ref) {
  const dispatch = useDispatch();
  const MAX_BETS_COUNT = useRef(20);
  const [showAddCurrencyWindow, setShowAddCurrencyWindow] = useState(false);
  const [showGenerateToEurWindow, setShowGenerateToEurWindow] = useState(false);
  const [defaultBetIndexMode, setDefaultBetIndexMode] = useState(false);
  const defaultFormData = useMemo(() => ({
    minBet: 0,
    maxBet: 0,
    betsCount: 0,
    generatorFunctionDegree: 2,
    currencies: '',
    currenciesArr: [],
    currenciesViewDecimals: {},
    currenciesDefaultBetIndexes: {},
  }), []);
  const rates = useSelector(partnerConfigSelectors.getRates);
  const [availableBets, setAvailableBets] = useState({});
  const [availableBetsView, setAvailableBetsView] = useState({});
  const [generate, setGenerate] = useState(false);
  const [formData, setFormData] = useState(defaultFormData);

  const saveFunction = useCallback(() => {
    const data = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS])) {
      const availableBetsKey = Object.keys(availableBets);
      if (availableBetsKey.length) data[ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS] = availableBetsKey.reduce((prev, currency) => {
        prev[currency.toLowerCase()] = availableBets[currency];
        return prev;
      }, {});

      const currenciesDecimalsKeys = Object.keys({...formData.currenciesViewDecimals, ...formData.currenciesDefaultBetIndexes});
      if (currenciesDecimalsKeys.length) {
        data[ePartnerConfigFieldName.EPCFN_CURRENCIES] = currenciesDecimalsKeys.reduce((prev, key) => {
          const currencySettings = {};
          if (typeof formData.currenciesViewDecimals[key] === 'number') currencySettings.viewDecimals = formData.currenciesViewDecimals[key];
          if (typeof formData.currenciesDefaultBetIndexes[key] === 'number') currencySettings.defaultBetIndex = formData.currenciesDefaultBetIndexes[key];
          prev[key.toLowerCase()] = currencySettings;
          return prev;
        }, {})
      }
    }

    return data;
  }, [availableBets, selectedCasino, overriddenBlocks, formData]);

  const getViewDecimals = useCallback((currencies) => {
    const config = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_CURRENCIES]);
    const currenciesDecimalsConfig = config?.currencies;
    const result = {};

    currencies.forEach(key => {
      let configKey;

      if (currenciesDecimalsConfig) {
        if (currenciesDecimalsConfig.hasOwnProperty(key)) {
          configKey = key;
        } else if (currenciesDecimalsConfig.hasOwnProperty(key.toLowerCase())) {
          configKey = key.toLowerCase();
        }
      }

      if (configKey && typeof currenciesDecimalsConfig[configKey].viewDecimals === 'number') {
        result[key.toUpperCase()] = currenciesDecimalsConfig[configKey].viewDecimals;
      }
    });
    return result
  }, [getConfigOrCasinoConfig]);

  const getDefaultBetIndexes = useCallback((currencies) => {
    const config = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_CURRENCIES]);
    const currenciesDefaultBetIndexesConfig = config?.currencies;
    const result = {};

    currencies.forEach(key => {
      let configKey;

      if (currenciesDefaultBetIndexesConfig) {
        if (currenciesDefaultBetIndexesConfig.hasOwnProperty(key)) {
          configKey = key;
        } else if (currenciesDefaultBetIndexesConfig.hasOwnProperty(key.toLowerCase())) {
          configKey = key.toLowerCase();
        }
      }

      if (configKey && typeof currenciesDefaultBetIndexesConfig[configKey].defaultBetIndex === 'number') {
        result[key.toUpperCase()] = currenciesDefaultBetIndexesConfig[configKey].defaultBetIndex;
      }
    });

    return result
  }, [getConfigOrCasinoConfig]);

  const generateAvailableBets = useCallback((currencies) => {
    const result = {};
    if (!rates) return result;

    for (let currency of currencies) {
      let rate = rates[currency.toUpperCase()];
      if (currency === 'dog') {
        rate = rates['DOGE'];
      }
      if (!rate) {
        console.warn('Can`t find rate for currency:', currency);
        delete result[currency];
        continue;
      }
      const betsArray = generateBets(rate * formData.minBet, rate * formData.maxBet, formData.betsCount, formData.generatorFunctionDegree, currency);
      if (betsArray.length > 0) result[currency.toUpperCase()] = betsArray;
    }

    return result;
  }, [rates, formData.minBet, formData.maxBet, formData.betsCount, formData.generatorFunctionDegree]);

  const generateAvailableBetsToEur = useCallback((currencies) => {
    if (!availableBets['EUR']) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'Eur available bets must be specified'));
      return;
    }

    const result = {};
    if (!rates) return result;

    for (let currency of currencies) {
      if (currency.toUpperCase() === 'EUR') continue;

      let rate = rates[currency.toUpperCase()];
      if (currency === 'dog') {
        rate = rates['DOGE'];
      }
      if (!rate) {
        console.warn('Can`t find rate for currency:', currency);
        delete result[currency];
        continue;
      }
      const betsArray = availableBets['EUR'].map(bet => bet * rate);
      if (betsArray.length > 0) result[currency.toUpperCase()] = betsArray;
    }

    return result;
  }, [dispatch, rates, availableBets]);

  const getMaxBetsCount = useCallback((availableBets) => {
    let betsCount = 0;
    if (availableBets) {
      for (let currency in availableBets) {
        const bets = availableBets[currency];
        if (bets.length > betsCount) {
          betsCount = bets.length;
        }
      }
    }
    return betsCount;
  }, []);

  const getMinBet = useCallback((availableBets) => {
    let minBet = 0;

    if (availableBets && rates) {
      for (const currency in availableBets) {
        if (!availableBets.hasOwnProperty(currency)) continue;
        const rate = rates[currency.toUpperCase()];
        if (!rate) continue;
        const currencyMinBet = availableBets[currency][0] / rate;
        if (minBet === 0 || minBet > currencyMinBet) {
          minBet = currencyMinBet;
        }
      }
    }

    return Math.round(minBet * 100) / 100;
  }, [rates]);

  const getMaxBet = useCallback((availableBets) => {
    let maxBet = 0;

    if (availableBets) {
      for (const currency in availableBets) {
        if (!availableBets.hasOwnProperty(currency)) continue;
        const rate = rates[currency.toUpperCase()];
        if (!rate) continue;
        const currencyMaxBet = availableBets[currency][availableBets[currency].length - 1] / rate;
        if (maxBet < currencyMaxBet) {
          maxBet = currencyMaxBet;
        }
      }
    }
    return Math.round(maxBet * 100) / 100;
  }, [rates]);

  const setAvailableBetsData = useCallback((config) => {
    if (!config) {
      setAvailableBets({});
      setAvailableBetsView({});
      setFormData({...defaultFormData, currenciesArr: defaultFormData.currenciesArr});
      return;
    }

    const newAvailableBets = config.available_bets
      ? Object.keys(config.available_bets).reduce((prev, currency) => {
        prev[currency.toUpperCase()] = config.available_bets[currency];
        return prev
      }, {})
      : {};

    setAvailableBets(newAvailableBets);
    setAvailableBetsView(newAvailableBets);

    const currenciesArr = (config.available_bets && Object.keys(config.available_bets).map(currency => currency.toUpperCase())) || defaultFormData.currenciesArr;
    const currenciesDecimals = getViewDecimals(currenciesArr);
    const currenciesDefaultBetIndexes = getDefaultBetIndexes(currenciesArr);
    const betsCount = (config.available_bets && config.available_bets['eur'] && config.available_bets['eur'].length) || getMaxBetsCount(config.available_bets) || defaultFormData.betsCount;
    const minBet = (config.available_bets && config.available_bets['eur'] && config.available_bets['eur'][0]) || getMinBet(config.available_bets) || defaultFormData.minBet;
    const maxBet = (config.available_bets && config.available_bets['eur'] && config.available_bets['eur'].at(-1)) || getMaxBet(config.available_bets) || defaultFormData.maxBet;

    setFormData({
      ...defaultFormData,
      generatorFunctionDegree: defaultFormData.generatorFunctionDegree,
      minBet,
      maxBet,
      betsCount,
      currenciesArr,
      currenciesViewDecimals: currenciesDecimals,
      currenciesDefaultBetIndexes: currenciesDefaultBetIndexes,
      currencies: (config.available_bets && currenciesArr.join(', ')) || defaultFormData.currencies,
    })
  }, [setAvailableBets, setAvailableBetsView, setFormData, defaultFormData, getViewDecimals, getDefaultBetIndexes, rates, getMaxBetsCount, getMinBet, getMaxBet]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS]: saveFunction,
    }))
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!generate) return;

    const newAvailableBets = generateAvailableBets(formData.currenciesArr);
    setAvailableBetsView(newAvailableBets);
    setAvailableBets(newAvailableBets);
    setGenerate(false);
  }, [generateAvailableBets, formData.currenciesArr, setAvailableBetsView, setAvailableBets, generate, setGenerate]);

  useEffect(() => {
    if (!rates) return;
    const configOrCasinoConfig = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS]);
    setAvailableBetsData(configOrCasinoConfig);
  }, [getConfigOrCasinoConfig, setAvailableBetsData, rates]);

  //check DefaultBetIndexes with max betsCount
  useEffect(() => {
    const currencies = Object.keys(formData.currenciesDefaultBetIndexes);
    const newCurrencies = currencies.filter(currency => formData.currenciesDefaultBetIndexes[currency] < formData.betsCount);
    if (currencies.length !== newCurrencies.length) {
      const newCurrenciesDefaultBetIndexes = newCurrencies.reduce((prev, currency) => {
        prev[currency] = formData.currenciesDefaultBetIndexes[currency];
        return prev;
      }, {});
      setFormData(prev => ({
        ...prev,
        currenciesDefaultBetIndexes: newCurrenciesDefaultBetIndexes
      }))
    }
  }, [formData.currenciesDefaultBetIndexes, formData.betsCount])

  const onChangeMinBet = (e) => {
    setFormData(prev => ({...prev, minBet: e.target.value}));
  };

  const onChangeMaxBet = (e) => {
    setFormData(prev => ({...prev, maxBet: e.target.value}));
  };

  const onChangeBetsCount = (e) => {
    setFormData(prev => ({...prev, betsCount: Number(e.target.value)}));
  };

  const onChangeGeneratorFunctionDegree = (e) => {
    setFormData(prev => ({...prev, generatorFunctionDegree: e.target.value}));
  };

  const onChangeCurrencies = (e) => {
    const currenciesArr = e.target.value === '' ? defaultFormData.currenciesArr : e.target.value.split(', ');
    const currenciesDecimals = getViewDecimals(currenciesArr);
    const currenciesDefaultBetIndexes = getDefaultBetIndexes(currenciesArr);
    setFormData(prev => ({
      ...prev,
      currencies: e.target.value,
      currenciesArr,
      currenciesViewDecimals: currenciesDecimals,
      currenciesDefaultBetIndexes: currenciesDefaultBetIndexes
    }));
  };

  const changeDecimals = (e) => {
    if (!integerInputRegExp.test(e.target.value)) return;

    setFormData(prev => ({
      ...prev,
      currenciesViewDecimals: {
        ...prev.currenciesViewDecimals,
        [e.target.dataset.currency.toUpperCase()]: e.target.value
      }
    }));
  };

  const convertDecimalsToNum = (e) => {
    setFormData(prev => ({
      ...prev,
      currenciesViewDecimals: {
        ...prev.currenciesViewDecimals,
        [e.target.dataset.currency.toUpperCase()]: Number(prev.currenciesViewDecimals[e.target.dataset.currency.toUpperCase()]),
      }
    }));
  };

  const onClickBetInput = (currency, betIndex) => {
    if (!defaultBetIndexMode) return;
    setFormData(prev => {
      let currenciesDefaultBetIndexes = {
        ...prev.currenciesDefaultBetIndexes,
      };
      currenciesDefaultBetIndexes = Object.fromEntries(Object.entries(currenciesDefaultBetIndexes).filter(arr => arr[0] !== currency.toUpperCase() || arr[1] !== betIndex));
      if (prev.currenciesDefaultBetIndexes[currency.toUpperCase()] !== betIndex) {
        currenciesDefaultBetIndexes[currency.toUpperCase()] = betIndex;
      }
      return ({
        ...prev,
        currenciesDefaultBetIndexes
      });
    });
  }

  const onChangeAvailableBet = (availableBet, currency, betIndex) => {
    setAvailableBetsView(prev => {
      const availableBetsByCurrency = Array.isArray(prev[currency]) ? [...prev[currency]] : [];
      availableBetsByCurrency[betIndex] = availableBet;
      return {
        ...prev,
        [currency]: availableBetsByCurrency
      }
    });
  };

  const onBlurAvailableBet = (bet, currency, betIndex) => {
    let availableBet = bet;
    if (bet > 0) {
      setAvailableBets(prev => {
        const availableBetsbyCurrency = Array.isArray(prev[currency]) ? [...prev[currency]] : [];
        availableBetsbyCurrency[betIndex] = +bet;

        return {
          ...prev,
          [currency]: availableBetsbyCurrency
        }
      });
    } else {
      availableBet = availableBets[currency][betIndex];
    }
    onChangeAvailableBet(availableBet, currency, betIndex)
  };

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS]: !prev[ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS],
    }));
    setAvailableBetsData(!overriddenBlocks[ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS] ? getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS]) : config)
  };

  const checkOverrideDecimalsView = (currency) => (isOverridden) => {
    setFormData(prev => {
      const decimalsView = {...prev.currenciesViewDecimals};
      if (isOverridden) {
        const config = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_CURRENCIES]);
        const currenciesDecimalsConfig = config.currencies;
        decimalsView[currency.toUpperCase()] = currenciesDecimalsConfig?.[currency.toLowerCase()]?.hasOwnProperty('viewDecimals') ?
          currenciesDecimalsConfig[currency.toLowerCase()].viewDecimals : '';
      } else {
        delete decimalsView[currency.toUpperCase()];
      }

      return {
        ...prev,
        currenciesViewDecimals: decimalsView,
      }
    });
  };

  useLayoutEffect(() => {
    if (!rates) return;
    setFormData(prev => {
      const currenciesArr = prev.currenciesArr === '' || prev.currenciesArr.length === 0 ? defaultFormData.currenciesArr : prev.currenciesArr;
      return {
        ...prev,
        currenciesArr
      }
    });

  }, [rates, setFormData, config, defaultFormData]);

  const reset = () => {
    const configOrCasinoConfig = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS]);
    setAvailableBetsData(configOrCasinoConfig);
  };

  const onGenerateClick = () => {
    setGenerate(true);
  };

  const openAddCurrencyWindow = () => {
    setShowAddCurrencyWindow(true);
  };

  const toggleDefaultBetIndexMode = () => {
    setDefaultBetIndexMode(prev => !prev);
  };

  const openGenerateToEurWindow = () => {
    setShowGenerateToEurWindow(true);
  };

  const closeAddCurrencyWindow = () => {
    setShowAddCurrencyWindow(false);
  };

  const closeGenerateToEurWindow = () => {
    setShowGenerateToEurWindow(false);
  };

  const addCurrenciesAndBets = (currencies, bets) => {
    setAvailableBets(prev => ({...prev, ...bets}));
    setAvailableBetsView(prev => ({...prev, ...bets}));
    setFormData(prev => {
      const currenciesArr = Array.from(new Set([...prev.currenciesArr, ...currencies]));
      return {
        ...prev,
        currenciesArr,
        currencies: currenciesArr.join(', '),
        currenciesViewDecimals: getViewDecimals(currenciesArr),
      }
    });
  };

  const tryToAddCurrencies = (currencies) => {
    const alreadyExistList = [];
    const newList = [];

    for (let currency of currencies) {
      if (availableBets.hasOwnProperty(currency)) {
        alreadyExistList.push(currency);
      } else {
        newList.push(currency);
      }
    }

    if (newList.length) addCurrenciesAndBets(newList, generateAvailableBets(newList));

    if (alreadyExistList.length) {
      dispatch(modalActions.showModal(modalTypes.CONFIRM, {
        text: `${alreadyExistList.join(', ')} already exist in available bets list, override?`,
        buttons: [
          {label: 'Cancel'},
          {
            label: 'Yes',
            callback: () => addCurrenciesAndBets(alreadyExistList, generateAvailableBets(alreadyExistList))
          }
        ]
      }));
    }
  };

  const tryToGenerate = (currencies) => {
    const alreadyExistList = [];
    const newList = [];

    for (let currency of currencies) {
      if (availableBets.hasOwnProperty(currency.toUpperCase())) {
        alreadyExistList.push(currency);
      } else {
        newList.push(currency);
      }
    }

    if (alreadyExistList.length) {
      const bets = generateAvailableBetsToEur(alreadyExistList);
      for (let currency in bets) {
        bets[currency] = roundBets(bets[currency]);
      }
      addCurrenciesAndBets(alreadyExistList, bets);
    }

    if (newList.length) {
      dispatch(modalActions.showModal(modalTypes.ERROR, `Currencies not found: ${newList.join(', ')}`));
    }
  };

  const deleteCurrencyAndBets = (currency) => {
    const bets = Object.keys(availableBets).reduce((res, key) => {
      if (key !== currency) {
        res[key] = availableBets[key];
      }
      return res;
    }, {});

    setAvailableBets(bets);
    setAvailableBetsView(bets);
    setFormData(prev => {
      const currenciesArr = prev.currenciesArr.filter(curr => curr !== currency);
      return {
        ...prev,
        currenciesArr,
        currencies: currenciesArr.join(', '),
        currenciesViewDecimals: getViewDecimals(currenciesArr),
      }
    });
  };

  const showConfirmDeleting = (e) => {
    const currency = e.currentTarget.dataset.currency;
    dispatch(modalActions.showModal(modalTypes.CONFIRM, {
      text: `Delete
      '${currency}' from available bets?`,
      buttons: [
        {label: 'Cancel'},
        {label: 'Yes', callback: () => deleteCurrencyAndBets(currency)}
      ]
    }));
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      {!!selectedCasino && canOverride &&
        <div className={'partners_config_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_AVAILABLE_BETS}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        </div>}
      <span className={'partners_config_body_form_box_title'}>Available Bets</span>
      <div className={'input_form'}>
        <span className={'input_form_title__size_fixed'}>minBet</span>
        <input type={'number'} value={formData.minBet} onChange={onChangeMinBet}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <div className={'input_form'}>
        <span className={'input_form_title__size_fixed'}>maxBet</span>
        <input type={'number'} value={formData.maxBet} onChange={onChangeMaxBet}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <div className={'input_form'}>
        <span className={'input_form_title__size_fixed'}>betsCount</span>
        <input type={'number'} value={formData.betsCount || ''} onChange={onChangeBetsCount}
               disabled={!canChangeBlock}/>
        <Info info={'Max count: 20'} visibility={formData.betsCount > MAX_BETS_COUNT.current}/>
      </div>
      <div className={'input_form'}>
        <span className={'input_form_title__size_fixed'}>generatorFunctionDegree</span>
        <input type={'number'} value={formData.generatorFunctionDegree} onChange={onChangeGeneratorFunctionDegree}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <div className={'input_form'}>
        <span className={'input_form_title'}>currencies</span>
        <input className={'big_input'}
               type={'string'}
               value={formData.currencies}
               onChange={onChangeCurrencies}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>

      <div>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={onGenerateClick}>Generate All
        </button>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={openGenerateToEurWindow}>Update from EUR
        </button>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={openAddCurrencyWindow}>Add currency
        </button>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={toggleDefaultBetIndexMode}>{defaultBetIndexMode ? 'Change available bets' : 'Change default bet indexes'}
        </button>

        <AddCurrencyWindow visible={showAddCurrencyWindow} close={closeAddCurrencyWindow}
                           callback={tryToAddCurrencies}/>
        <GenerateToEurWindow visible={showGenerateToEurWindow} close={closeGenerateToEurWindow}
                             callback={tryToGenerate}/>
      </div>

      <DropdownLayout title={'Bets'}>
        {formData.currenciesArr && formData.currenciesArr.map(currency =>
          <div key={currency} className={'input_form available_bets_row'}>
            <span className={'available_bets_row_currency'}>
              <button onClick={showConfirmDeleting} disabled={!canChangeBlock} data-currency={currency}>
                <DeleteIcon/>
              </button>
              {currency}
            </span>

            <AddCheckboxLayout title={'Decimals view:'} childrenPosition={'right'}
                               checked={formData.currenciesViewDecimals.hasOwnProperty(currency.toUpperCase()) || false}
                               disabled={!canChangeBlock}
                               checkboxId={`${ePartnerConfigFieldName.EPCFN_CURRENCIES}_${currency}_override_checkbox`}
                               setChecked={checkOverrideDecimalsView(currency)}>
                  <span>
                    <input
                      value={formData.currenciesViewDecimals.hasOwnProperty(currency.toUpperCase()) ? formData.currenciesViewDecimals[currency.toUpperCase()] : 'default'}
                      onChange={changeDecimals} onBlur={convertDecimalsToNum} data-currency={currency}
                      disabled={!canChangeBlock || !formData.currenciesViewDecimals.hasOwnProperty(currency.toUpperCase())}/>
                  </span>
            </AddCheckboxLayout>

            <span className={'input_form_subtitle'}>Range:</span>
            {(new Array(formData.betsCount)).fill().map((_, betIndex) => (
                <input key={currency + betIndex} type={'number'}
                       className={`${(formData.currenciesDefaultBetIndexes.hasOwnProperty(currency.toUpperCase()) ? formData.currenciesDefaultBetIndexes[currency.toUpperCase()] : formData.currenciesDefaultBetIndexes['EUR'] || 0) === betIndex ? (formData.currenciesDefaultBetIndexes.hasOwnProperty(currency.toUpperCase()) ? 'blue_border' : 'blue_light_border') : ''} ${defaultBetIndexMode ? 'cursor_pointer' : ''}`}
                       readOnly={defaultBetIndexMode}
                       value={
                         availableBetsView[currency]
                         && availableBetsView[currency][betIndex] !== undefined
                           ? availableBetsView[currency][betIndex]
                           : ''
                       }
                       onChange={(e) => onChangeAvailableBet(e.target.value, currency, betIndex)}
                       onBlur={(e) => onBlurAvailableBet(e.target.value, currency, betIndex)}
                       disabled={!canChangeBlock}
                       onClick={() => onClickBetInput(currency, betIndex)}
                />
              )
            )}
            <Info info={''}/>
          </div>
        )}
      </DropdownLayout>

    </div>
  );
}));
