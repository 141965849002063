export const readJsonFile = async (file, parse = true) => {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = async (event) => {
      const result = parse ? JSON.parse(event.target.result) : event.target.result;
      resolve(result);
    };

    reader.onerror = () => {
      reject();
    };

    reader.readAsText(file);
  });
}
