import { useEffect } from 'react';

const useScrollListener = (callback, condition) => {
  useEffect(() => {
    if (condition) {
      window.addEventListener('scroll', callback, true);
    }

    return () => {
      window.removeEventListener('scroll', callback, true);
    }
  }, [condition, callback]);
};

export {
  useScrollListener,
}
