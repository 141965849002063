import React, { useEffect, useMemo, useState } from 'react';
import { managerRest } from '../../../index';

export default React.memo(function PartnersConfigSidebar({ operators, setSelectedOperator, selectedOperator, setSelectedCasino, selectedCasino }) {
  const [defaultPageTitle] = useState(document.title);

  const selectOperator = (provider) => () => {
    setSelectedOperator(provider);
    setSelectedCasino('');
  };

  const selectCasino = (casino) => () => {
    setSelectedCasino(casino);
  };

  const operatorsIds = useMemo(() => {
    return operators
      ? Object.keys(operators).sort((a, b) => operators[a].name.localeCompare(operators[b].name))
      : [];
  }, [operators]);

  const casinoList = useMemo(() => {
    return operators && operators[selectedOperator] && operators[selectedOperator].casino_list
      ? operators[selectedOperator].casino_list.sort((a, b) => a.localeCompare(b))
      : [];
  }, [operators, selectedOperator]);

  useEffect(() => {
    if (!selectedOperator || !operators) return;
    let serverPrefix = '';
    if (/.stage/.test(managerRest.domain)) {
      serverPrefix = 'STAGE';
    } else if (/.dev/.test(managerRest.domain)) {
      serverPrefix = 'DEV';
    } else if (/localhost|0.0.0.0/.test(managerRest.domain)) {
      serverPrefix = 'LOCALHOST';
    } else {
      serverPrefix = 'PROD';
    }
    document.title = `(${selectedOperator}) ${operators[selectedOperator].name} ${selectedCasino ?'/ ' + selectedCasino : '' } - ${serverPrefix}`;
    return () => {
      document.title = defaultPageTitle;
    }
  }, [selectedOperator, selectedCasino, defaultPageTitle]);

  return (
    <div className={'partners_config_sidebar'}>

      {operatorsIds.map(id =>
        <div key={id}>
              <span onClick={selectOperator(id)}
                    className={`partners_config_sidebar_operator ${selectedOperator === id ? 'partners_config_sidebar_selected' : ''}`}>
                <span className={'partners_config_sidebar_operator__id'}>{id}</span> {operators[id].name}
              </span>

          {selectedOperator === id &&
          <div className={'partners_config_sidebar_casino_list'}>
            {casinoList.map(casino =>
              <span key={casino} onClick={selectCasino(casino)}
                    className={selectedCasino === casino ? 'partners_config_sidebar_selected' : ''}>
                {casino}
              </span>)}
          </div>}
        </div>
      )}

    </div>
  );
})
