import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Info from '../../../inputForms/info';
import DropdownLayout from '../../../dropdownLayout';
import AddCheckboxLayout from '../../../inputForms/addCheckboxLayout';
import { useSelector } from 'react-redux';
import { partnerConfigSelectors } from '../../../../redux/partnersConfig/selectors';
import ePartnerConfigFieldName from '../../ePartnerConfigFieldName';
import Checkbox from '../../../inputForms/checkbox';

export default React.memo(function MessageEvent({ eventName, params, selected = [], onSelect, info,  disabled, checked, onChecked } ) {
  const onCheckedParam = (param) => {
    if (disabled || !checked) return;
    if ((selected.includes(param))) {
      selected = selected.filter((prevParam) => prevParam !== param)
    } else {
      selected.push(param)
    }
    onSelect(selected, eventName);
  }
  return (
      <div className={'input_form'}>
        <Checkbox disabled={disabled} checked={checked} setChecked={onChecked} id={`${eventName}_checkbox`} />
        <span className={'input_form_title_messageEvent'}>{eventName}</span>
        {params && params.map((param => (<div key={param} data-disabled={disabled || !checked} className={'input_form_textCheckbox'} data-select={selected.includes(param)} onClick={() => onCheckedParam(param)}>
          {param}
        </div>)))}
        <Info info={info} />
      </div>
  );
});
