import React, { useMemo } from 'react';
import GameResult from './result';
import moment from 'moment';
import { modalActions } from '../../redux/modals/reducer';
import { modalTypes } from '../../redux/modals/modalTypes';
import { useDispatch } from 'react-redux';

import { ReactComponent as Check } from '../../assets/icons/check.svg';
import { ReactComponent as Uncheck } from '../../assets/icons/uncheck.svg';
import { ReactComponent as Info } from '../../assets/icons/info-icon.svg';
import { validateJson } from '../../utils/validateJson';

export default React.memo(function DropdownRow({ transaction, lightUp, result, resultVersion, game }) {
  const dispatch = useDispatch();

  const transactionInfo = useMemo(() => {
    const keys = ['provider_request', 'provider_response', 'operator_request', 'operator_response'];
    let temp = {};

    for (let key of keys) {
      temp[key] = transaction[key] != null ? validateJson(transaction[key]) : null;
    }

    return temp;
  }, [transaction]);

  const openInfoModal = () => {
    dispatch(modalActions.showModal(modalTypes.INFO, transactionInfo));
  };

  const openPreview = () => {
    dispatch(modalActions.showModal(modalTypes.TRANSACTION, {
      transaction: {
        game_bundle: game,
        result: {
          version: resultVersion,
          transactions: [{ result: result?.result }],
        },
      }
    }))
  };

  return (
    <div className={`transaction_dropdown_body_row ${lightUp ? 'transaction_dropdown_body_row_light_up' : ''}`}>
      <span>{transaction.id}</span>
      <span>{transaction.type}</span>
      <span>{transaction.bet_amount || 0}</span>
      <span>{transaction.win_amount || 0}</span>
      <span>{transaction.operator_tx}</span>
      <span>{transaction.provider_tx}</span>
      <span>{transaction.provider_ref_tx}</span>
      <span>{transaction.is_finished ? <Check /> : <Uncheck />}</span>
      <span>{moment(transaction.created_at).utc(false).format('YYYY-MM-DD HH:mm:ss:SSS')}</span>
      <span><Info className={'pointer'} onClick={openInfoModal} /></span>
      <span className={'pointer'} onClick={openPreview}>
            <GameResult game={game} result={result?.result} version={resultVersion} />
      </span>
    </div>
  );
})
