const SET_ROUNDS = 'OPEN_ROUTE/SET_ROUNDS';
const SET_ROUNDS_DETAILS = 'OPEN_ROUTE/SET_ROUND_DETAILS';

const initialState = {
  rounds: {
    totalCount: 0,
    list: []
  },
  details: {},
};

export const openRouteReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_ROUNDS:
      return {
        ...state,
        rounds: payload,
      };
    case SET_ROUNDS_DETAILS:
      return {
        ...state,
        details: { ...state.details, [payload.round.id]: payload }
      }
    default:
      return state;
  }
};

export const openRouteActions = {
  setRounds(rounds) {
    return {
      type: SET_ROUNDS,
      payload: rounds
    }
  },
  setDetails(details) {
    return {
      type: SET_ROUNDS_DETAILS,
      payload: details
    }
  }
};
