import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../redux/partnersConfig/selectors';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import DropdownLayout from '../../dropdownLayout';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import ePartnerConfigFieldName from '../ePartnerConfigFieldName';

export default React.memo(forwardRef(function Jackpot({
                                                        config,
                                                        canChangeBlock,
                                                        canOverride,
                                                        setSaveFunctionPool,
                                                        selectedCasino,
                                                        overriddenBlocks,
                                                        setOverriddenBlocks,
                                                        getConfigOrCasinoConfig
                                                      }, ref) {
  const rates = useSelector(partnerConfigSelectors.getRates);
  const gameList = useSelector(partnerConfigSelectors.getGameList);
  const defaultFormData = useMemo(() => ({
    jackpotEnabled: false,
    default: 0,
    games: {}
  }), []);
  const [override, setOverride] = useState({});
  const [formData, setFormData] = useState(defaultFormData);

  const saveFunction = useCallback(() => {
    const data = {};
    const jackpotData = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED])) {
      if (formData.default > 0) {
        jackpotData.default = +formData.default || 0;
      }

      if (override && Object.keys(override).length) {
        jackpotData.games = override;
      }

      if (Object.keys(jackpotData).length) data.jackpotPayoutThreshold = jackpotData;

      data.jackpotEnabled = formData.jackpotEnabled;
    }

    return data;
  }, [selectedCasino, overriddenBlocks, formData, override]);

  const setJackpotData = useCallback((config) => {
    setFormData({
      jackpotEnabled: config.hasOwnProperty('jackpotEnabled') ? config.jackpotEnabled : true,
      default: config.jackpotPayoutThreshold?.default || 0,
      games: {...(config.jackpotPayoutThreshold?.games || {})}
    });
    setOverride(config.jackpotPayoutThreshold?.games || {});
  }, [setFormData, setOverride]);

  const reset = useCallback(() => {
    const configOrCasinoConfig = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED, ePartnerConfigFieldName.EPCFN_JACKPOT_PAYOUT_THRESHOLD]);
    setJackpotData(configOrCasinoConfig || defaultFormData);
  }, [getConfigOrCasinoConfig, setJackpotData, defaultFormData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED]: saveFunction,
    }))
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!config) return reset();

    const configOrCasinoConfig = getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED, ePartnerConfigFieldName.EPCFN_JACKPOT_PAYOUT_THRESHOLD]);
    setJackpotData(configOrCasinoConfig);
  }, [config, getConfigOrCasinoConfig, setJackpotData, reset]);

  const updateCurrencies = (value, game) => {
    let currencies = {};
    if (value >= 0) {
      currencies = rates && Object.keys(rates).reduce((prev, currency) => {
        prev[currency] = value * rates[currency];
        return prev;
      }, {})
    }

    setFormData(prev => {
      const gameObj = prev.games[game] ? prev.games[game] : {};
      return ({...prev, games: {...prev.games, [game]: {...gameObj, currencies}}})
    })
  };

  const toggleJackpotEnabled = useCallback((value) => {
    setFormData(prev => ({...prev, jackpotEnabled: value}));
  }, [setFormData]);

  const changeDefaultValue = (e) => {
    setFormData(prev => ({...prev, default: e.target.value}));
  };

  const onClickCurrenciesDropdown = (isOverrideGameDefaultValue, game) => {
    if (isOverrideGameDefaultValue && override[game]) {
      updateCurrencies(override[game].default, game)
    }
  };

  const blurDefaultJackpot = (e) => {
    setOverride(prev => ({
      ...prev,
      [e.target.dataset.game]: {...prev[e.target.dataset.game], default: +prev[e.target.dataset.game].default || 0}
    }));
  };

  const overrideDefaultJackpot = (e) => {
    setOverride(prev => ({
      ...prev,
      [e.target.dataset.game]: {...prev[e.target.dataset.game], default: e.target.value}
    }));
    updateCurrencies(e.target.value, e.target.dataset.game);
  };

  const toggleOverrideDefaultJackpot = (game) => () => {
    if (!canChangeBlock) return;

    setOverride(prev => {
      if (Object.keys(prev).includes(game) && (prev[game].default || prev[game].default === 0) && !prev[game].currencies) {
        const entries = Object.entries(prev);
        return Object.fromEntries(entries.filter(el => el[0] !== game));
      } else if (Object.keys(prev).includes(game) && (prev[game].default || prev[game].default === 0) && prev[game].currencies) {
        return {...prev, [game]: {currencies: prev[game].currencies}}
      } else if (Object.keys(prev).includes(game) && !prev[game].default && prev[game].currencies) {
        const defaultValue = (formData.games && formData.games[game] && formData.games[game].default) || 0;
        updateCurrencies(defaultValue, game);
        return {...prev, [game]: {default: defaultValue, currencies: prev[game].currencies}}
      } else {
        const defaultValue = (formData.games && formData.games[game] && formData.games[game].default) || 0;
        updateCurrencies(defaultValue, game);
        return {...prev, [game]: {default: defaultValue}}
      }
    });
  };

  const overrideCurrencyJackpot = (e) => {
    if (!canChangeBlock) return;

    setOverride(prev => ({
      ...prev,
      [e.target.dataset.game]: {
        ...prev[e.target.dataset.game],
        currencies: {
          ...prev[e.target.dataset.game].currencies,
          [e.target.dataset.currency]: e.target.value
        }
      }
    }));
  };

  const blurCurrencyJackpot = (e) => {
    setOverride(prev => ({
      ...prev,
      [e.target.dataset.game]: {
        ...prev[e.target.dataset.game],
        currencies: {
          ...prev[e.target.dataset.game].currencies,
          [e.target.dataset.currency]: +e.target.value || 0
        }
      }
    }));
  };

  const toggleOverrideCurrencyJackpot = (game, currency) => () => {
    if (!canChangeBlock) return;

    setOverride(prev => {
      if (Object.keys(prev).includes(game) && !(prev[game].default || prev[game].default === 0) && prev[game].currencies && Object.keys(prev[game].currencies).includes(currency) && Object.keys(prev[game].currencies).length === 1) {
        const entries = Object.entries(prev);
        return Object.fromEntries(entries.filter(el => el[0] !== game));

      } else if (Object.keys(prev).includes(game) && !(prev[game].default || prev[game].default === 0) && prev[game].currencies && Object.keys(prev[game].currencies).includes(currency)) {
        const entries = Object.entries(prev[game].currencies);
        return {
          ...prev,
          [game]: {currencies: {...prev[game].currencies, ...Object.fromEntries(entries.filter(el => el[0] !== currency))}}
        };

      } else if (Object.keys(prev).includes(game) && (prev[game].default || prev[game].default === 0) && prev[game].currencies && Object.keys(prev[game].currencies).includes(currency) && Object.keys(prev[game].currencies).length === 1) {
        return {...prev, [game]: {default: prev[game].default}}

      } else if (Object.keys(prev).includes(game) && (prev[game].default || prev[game].default === 0) && prev[game].currencies && Object.keys(prev[game].currencies).includes(currency)) {
        const entries = Object.entries(prev[game].currencies);
        return {
          ...prev,
          [game]: {
            default: prev[game].default,
            currencies: {...prev[game].currencies, ...Object.fromEntries(entries.filter(el => el[0] !== currency))}
          }
        }

      } else if (Object.keys(prev).includes(game) && (prev[game].default || prev[game].default === 0) && !(prev[game].currencies && Object.keys(prev[game].currencies).includes(currency))) {
        return {
          ...prev,
          [game]: {
            default: prev[game].default,
            currencies: {
              ...prev[game].currencies,
              [currency]: (formData.games && formData.games[game] && formData.games[game].currencies && formData.games[game].currencies[currency]) || 0
            }
          }
        }

      } else if (Object.keys(prev).includes(game)) {
        return {
          ...prev,
          [game]: {
            currencies: {
              ...prev[game].currencies,
              [currency]: (formData.games && formData.games[game] && formData.games[game].currencies && formData.games[game].currencies[currency]) || 0
            }
          }
        }
      } else {
        return {
          ...prev,
          [game]: {currencies: {[currency]: (formData.games && formData.games[game] && formData.games[game].currencies && formData.games[game].currencies[currency]) || 0}}
        }
      }
    });
  };

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED]: !prev[ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED]
    }));
    setJackpotData(!overriddenBlocks[ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED] ? getConfigOrCasinoConfig([ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED, ePartnerConfigFieldName.EPCFN_JACKPOT_PAYOUT_THRESHOLD]) : config)
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      {!!selectedCasino && canOverride &&
        <div className={'partners_config_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_JACKPOT_ENABLED}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        </div>}
      <span className={'partners_config_body_form_box_title'}>Jackpot</span>
      <div className={'input_form'}>
        <span className={'input_form_title'}>jackpotEnabled</span>
        <Checkbox checked={!!formData.jackpotEnabled} setChecked={toggleJackpotEnabled}
                  id={'jackpotEnabled'} disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <span>jackpotPayoutThreshold:</span>


      <div className={'input_form'}>
        <span className={'input_form_title'}>default</span>
        <input type={'number'} value={formData.default || ''} onChange={changeDefaultValue}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>

      <DropdownLayout title={'Games'}>
        <div className={'dropdown_body'}>
          {gameList && gameList.map(game => {
              const overriddenDefault = Object.keys(override).includes(game.bundle) && (override[game.bundle].default || override[game.bundle].default === 0 || override[game.bundle].default === '');
              return (
                <DropdownLayout title={game.title} key={game.bundle}>
                  <div className={'input_form input_form__sub'}>
                    <span className={'input_form_title'}>default</span>
                    <input disabled={!overriddenDefault || !canChangeBlock}
                           value={overriddenDefault ? override[game.bundle].default : formData.games && formData.games[game.bundle] && formData.games[game.bundle].default ? formData.games[game.bundle].default : ''}
                           onChange={overrideDefaultJackpot} data-game={game.bundle}
                           onBlur={blurDefaultJackpot}
                    />
                    <AddCheckboxLayout title={'override'} setChecked={toggleOverrideDefaultJackpot(game.bundle)}
                                       checked={overriddenDefault}
                                       checkboxId={`${game.bundle}_max_win_override_checkbox`}>
                    </AddCheckboxLayout>
                    <Info info={''}/>
                  </div>
                  <DropdownLayout onClick={() => onClickCurrenciesDropdown(overriddenDefault, game.bundle)}
                                  title={'Currencies'}
                                  className={'dropdown_layout dropdown_layout__sub'}>
                    {rates && Object.keys(rates).map(currency => {
                        const overriddenCurrency = Object.keys(override).includes(game.bundle)
                          && !!override[game.bundle].currencies
                          && Object.keys(override[game.bundle].currencies).includes(currency);

                        return <div className={'input_form input_form_grid_3_label_right'}
                                    key={`${game.bundle}-${currency}`}>
                          <AddCheckboxLayout title={'override'}
                                             setChecked={toggleOverrideCurrencyJackpot(game.bundle, currency)}
                                             checked={overriddenCurrency}
                                             checkboxId={`${currency}_max_win_override_checkbox`}>
                            <input type={'number'} disabled={!overriddenCurrency || !canChangeBlock}
                                   value={overriddenCurrency ? override[game.bundle].currencies[currency] :
                                     formData.games && formData.games[game.bundle] && formData.games[game.bundle].currencies && formData.games[game.bundle].currencies[currency] ?
                                       formData.games[game.bundle].currencies[currency] : ''}
                                   onChange={overrideCurrencyJackpot} data-game={game.bundle} data-currency={currency}
                                   onBlur={blurCurrencyJackpot}
                            />
                            <span className={'input_form_subtitle'}>{currency}</span>
                          </AddCheckboxLayout>
                        </div>
                      }
                    )}
                  </DropdownLayout>
                </DropdownLayout>
              )
            }
          )}
        </div>
      </DropdownLayout>
    </div>
  );
}));
