const eUserRoles = {
  EUR_ADMIN: 0,
  EUR_PARTNER: 1,
  EUR_MARKETER: 2,
  EUR_GAME_CO_OWNER: 3,
  EUR_SUPPORT: 4,
  EUR_ACCOUNT: 5,
  EUR_INTEGRATION_MANAGER: 6,
  EUR_HEAD_OF_SLOTS: 7,
};

export default eUserRoles;
