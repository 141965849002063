import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout'

export default React.memo(forwardRef(function SingleBooleanField({
                                                                   title,
                                                                   fieldName,
                                                                   canChangeBlock,
                                                                   canOverride,
                                                                   initialValue,
                                                                   config,
                                                                   setSaveFunctionPool,
                                                                   selectedCasino,
                                                                   overriddenBlocks,
                                                                   setOverriddenBlocks,
                                                                   getConfigOrCasinoConfigProperty,
                                                                   info
                                                                 }, ref) {
  const [value, setValue] = useState(initialValue);

  const saveFunction = useCallback(() => {
    const data = {};
    if (!selectedCasino || (selectedCasino && overriddenBlocks[fieldName])) {
      data[fieldName] = value;
    }
    return data;
  }, [selectedCasino, overriddenBlocks, fieldName, value]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [fieldName]: saveFunction,
    }))
  }, [setSaveFunctionPool, saveFunction, fieldName]);

  useEffect(() => {
    const value = getConfigOrCasinoConfigProperty(fieldName) || initialValue;
    setValue(value);
  }, [config, fieldName, setValue, getConfigOrCasinoConfigProperty, initialValue]);

  const checkOverride = useCallback(() => {
    let value;
    if (!overriddenBlocks[fieldName]) {
      if (config && config.hasOwnProperty(fieldName)) {
        value = config[fieldName];
      } else value = initialValue;
    } else {
      value = getConfigOrCasinoConfigProperty(fieldName) || initialValue;
    }

    setValue(value);
    setOverriddenBlocks(prev => ({...prev, [fieldName]: !prev[fieldName]}));
  }, [setValue, setOverriddenBlocks, fieldName, config, getConfigOrCasinoConfigProperty, overriddenBlocks, initialValue]);

  const changeValue = useCallback((value) => {
    canChangeBlock && setValue(value);
  }, [canChangeBlock, setValue]);

  const reset = () => {
    const value = getConfigOrCasinoConfigProperty(fieldName) || initialValue;
    setValue(value);
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>
      <div className={'input_form'}>
        <span className={'input_form_title'}>{title}</span>
        <Checkbox checked={value} setChecked={changeValue} id={`${fieldName}_checkbox`}/>
        {!!selectedCasino && canOverride &&
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[fieldName]}
                             checkboxId={`${fieldName}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />}
        <Info info={info}/>
      </div>
    </div>
  );
}));
