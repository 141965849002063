import React from 'react';
import { ReactComponent as PreloaderIcon } from '../../assets/icons/preloader.svg';

export default function () {
  return (
    <div className={'loading_indicator'}>
      <PreloaderIcon />
    </div>
  );
}
