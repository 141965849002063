import React from 'react';
import { useSelector } from 'react-redux';
import { userSelectors } from '../redux/user/selectors';
import eUserRoles from '../enums/eUserRoles';

const withSuperAdminCheck = function (Component) {
  return React.memo(function (props) {
    const user = useSelector(userSelectors.getUser);
    return user && user.role === eUserRoles.EUR_ADMIN ? <Component {...props} /> : React.Fragment;
  });
};

const SuperAdminCheckLayout = React.memo(function({ children }) {
  const user = useSelector(userSelectors.getUser);
  return user && user.role === eUserRoles.EUR_ADMIN ? children : React.Fragment;
});

const RolesCheckLayout = React.memo(function({ children, Roles }) {
  const user = useSelector(userSelectors.getUser);
  return user && Array.isArray(Roles) && Roles.includes(user.role) ? children : React.Fragment;
});

export {
  withSuperAdminCheck,
  SuperAdminCheckLayout,
  RolesCheckLayout,
}
