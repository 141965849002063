import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../redux/partnersConfig/selectors';
import Checkbox from '../../inputForms/checkbox';
import Info from '../../inputForms/info';
import DropdownLayout from '../../dropdownLayout';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import eOperatorsConfigsFieldName from '../eOperatorsConfigsFieldName';

export default React.memo(forwardRef(function Jackpot({
                                                        configForView,
                                                        canChangeBlock,
                                                        canOverride,
                                                        setSaveFunctionPool,
                                                        overriddenBlocks,
                                                        toggleOverriddenField,
                                                        setValueToConfigForView
                                                      }, ref) {
  const rates = useSelector(partnerConfigSelectors.getRates);
  const defaultFormData = useMemo(() => ({
    jackpotEnabled: true,
    default: 0,
    currencies: {}
  }), []);
  const [formData, setFormData] = useState(defaultFormData);
  const [currencyByRates, setCurrencyByRates] = useState({});

  const saveFunction = useCallback(() => {
    const data = {};
    const jackpotData = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED] === configForView.type) {
      if (formData.default > 0) {
        jackpotData.default = +formData.default || 0;
      }

      if (Object.keys(formData.currencies).length) jackpotData.currencies = formData.currencies;

      if (Object.keys(jackpotData).length) data.jackpotPayoutThreshold = jackpotData;

      data.jackpotEnabled = formData.jackpotEnabled;
    }

    return data;
  }, [overriddenBlocks, formData, configForView.type, configForView.isGlobalConfig]);

  const setJackpotData = useCallback((config) => {
    setFormData({
      jackpotEnabled: typeof config.jackpotEnabled === 'boolean' ? config.jackpotEnabled : defaultFormData.jackpotEnabled,
      default: config.jackpotPayoutThreshold?.default || defaultFormData.default,
      currencies: {...(config.jackpotPayoutThreshold?.currencies || defaultFormData.currencies)}
    });
  }, [setFormData, defaultFormData]);

  const reset = useCallback(() => {
    setJackpotData(configForView.config);
  }, [configForView.config, setJackpotData]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    setJackpotData(configForView.config);
  }, [configForView.config, setJackpotData, reset]);

  const updateCurrencies = useCallback((value) => {
    let currencies = {};
    if (value >= 0) {
      currencies = rates && Object.keys(rates).reduce((prev, currency) => {
        prev[currency] = value * rates[currency] || '';
        return prev;
      }, {})
    }
    setCurrencyByRates(currencies);
  }, [rates, setCurrencyByRates]);

  const toggleJackpotEnabled = useCallback((value) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED, value);
    }, [setValueToConfigForView, configForView.type]);

  const changeDefaultValue = useCallback((e) => {
    setFormData(prev => ({...prev, default: e.target.value}));
  }, [setFormData]);

  const onBlurDefaultValue = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_JACKPOT_PAYOUT_THRESHOLD, { default: +e.target.value || 0, currencies: formData.currencies });
    updateCurrencies(+e.target.value)
  }, [setValueToConfigForView, configForView.type, formData.currencies]);

  const changeCurrencyValue = useCallback((e) => {
    setFormData(prev => ({...prev, currencies: { ...prev.currencies, [e.target.dataset.currency]: e.target.value }}));
  }, [setFormData]);

  const toggleCurrencyOverride = useCallback((currency) => {
    setFormData(prev => {
      let currencies;
      if (prev.currencies.hasOwnProperty(currency)) {
        currencies = Object.keys(prev.currencies).reduce((prevObj, cur) => {
          if (cur !== currency) {
            prevObj[cur] = prev.currencies[cur];
          }
          return prevObj;
        }, {});
      } else {
        currencies = {
          ...prev.currencies,
          [currency]: currencyByRates[currency]
        }
      }
        return ({ ...prev, currencies });
    });
  }, [setFormData, currencyByRates]);

  const onBlurCurrencyValue = useCallback((e) => {
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_JACKPOT_PAYOUT_THRESHOLD, { default: formData.default, currencies: {
      ...formData.currencies,
        [e.target.dataset.currency]: +e.target.value
    } });
  }, [setValueToConfigForView, configForView.type, formData.currencies]);

  const onClickCurrenciesDropdown = useCallback(() => {
      updateCurrencies(formData.default)
  }, [updateCurrencies, formData.default]);


  const checkOverride = useCallback(() => {
    toggleOverriddenField(configForView.type, [eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED, eOperatorsConfigsFieldName.EPCFN_JACKPOT_PAYOUT_THRESHOLD]);
  }, [toggleOverriddenField, configForView]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>
      {!configForView.isGlobalConfig && canOverride &&
        <div className={'operators_configs_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED]}
          />
        </div>}
      <span className={'operators_configs_body_form_box_title'}>Jackpot</span>
      <div className={'input_form'}>
        <span className={'input_form_title'}>jackpotEnabled</span>
        <Checkbox checked={!!formData.jackpotEnabled} setChecked={toggleJackpotEnabled}
                  id={`${configForView.type}_jackpotEnabled`} disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <span>jackpotPayoutThreshold:</span>


      <div className={'input_form'}>
        <span className={'input_form_title'}>default</span>
        <input className={'medium_input'} type={'number'} value={formData.default || ''} onChange={changeDefaultValue} onBlur={onBlurDefaultValue}
               disabled={!canChangeBlock}/>
        <Info info={''}/>
      </div>
      <DropdownLayout onClick={onClickCurrenciesDropdown}
                      title={'Currencies'}
                      className={'dropdown_layout'}>
        {rates && Object.keys(rates).map(currency => {
            const overriddenCurrency = Object.keys(formData.currencies).includes(currency)
            return <div className={'input_form input_form_grid_3_label_right'}
                        key={`${currency}`}>
              <AddCheckboxLayout title={'override'}
                                 setChecked={() => toggleCurrencyOverride(currency)}
                                 checked={overriddenCurrency}
                                 checkboxId={`${configForView.type}_${currency}_max_win_override_checkbox`}>
                <input className={'medium_input'} type={'number'} disabled={!overriddenCurrency || !canChangeBlock}
                       value={overriddenCurrency ? formData.currencies[currency] : currencyByRates[currency]}
                       onChange={changeCurrencyValue} data-currency={currency}
                       onBlur={onBlurCurrencyValue}
                />
                <span className={'input_form_subtitle'}>{currency}</span>
              </AddCheckboxLayout>
            </div>
          }
        )}
      </DropdownLayout>
    </div>
  );
}));
