const SET_ROUNDS = 'APP/SET_ROUNDS';
const SET_LOADING = 'APP/SET_LOADING';
const SET_ROLES = 'APP/SET_ROLES';
const SET_CASINO_LIST = 'APP/SET_CASINO_LIST';
const SET_OPTIONS = 'APP/SET_OPTIONS';
const UPDATE_ROUND = 'APP/UPDATE_ROUND';
const SET_CERTIFICATIONS = 'APP/SET_CERTIFICATIONS';
const SET_TXS = 'APP/SET_TXS';
const UPDATE_TXS = 'APP/UPDATE_TXS';
const REMOVE_TXS = 'APP/REMOVE_TXS';

const initialState = {
  loading: [],
  options: {
    games: [],
    is_closed: [],
    currencies: [],
    operators: {},
  },
  certifications: [],
  casino_list: [],
  casino_list_by_operator: {},
  roles: [],
  rounds: null,
  roundsTotalCount: null,
  txs: {},
};

export const appReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case SET_ROUNDS:
      return {
        ...state,
        rounds: payload,
      };
    case UPDATE_ROUND:
      let roundTypeKey;
      let roundIndex = -1;

      ['new', 'old'].forEach(key => {
        if (roundTypeKey) return;
        roundIndex = state.rounds[key].findIndex(el => el.id === payload.id);
        roundTypeKey = roundIndex >= 0 ? key : undefined;
      });

      if (roundIndex < 0 || !roundTypeKey) return state;

      return {
        ...state,
        rounds: {
          ...state.rounds,
          [roundTypeKey]: [...state.rounds[roundTypeKey].slice(0, roundIndex), payload, ...state.rounds[roundTypeKey].slice(roundIndex + 1)]
        }
      };
    case SET_TXS:
      return {
        ...state,
        txs: {
          ...state.txs,
          [payload.id]: payload.txs
        }
      };
    case UPDATE_TXS:
      const txs = state.txs[payload.id] ? {
        ...state.txs,
        [payload.id]: [payload.tx, ...state.txs[payload.id]]
      } : state.txs;
      return {
        ...state,
        txs: txs
      };
    case REMOVE_TXS:
      const filteredTxs = { ...state.txs };
      delete (filteredTxs[payload]);
      return {
        ...state,
        txs: filteredTxs
      };
    case SET_LOADING:
      const loadingStack = state.loading.includes(payload) ? state.loading.filter(el => el !== payload) : [...state.loading, payload];
      return {
        ...state,
        loading: loadingStack
      };
    case SET_OPTIONS:
      return {
        ...state,
        options: payload
      };
    case SET_ROLES:
      return {
        ...state,
        roles: payload
      };
    case SET_CASINO_LIST:
      return {
        ...state,
        casino_list: payload,
        casino_list_by_operator: payload.reduce((acc, item) => {
          if (!acc[item.operator_id]) acc[item.operator_id] = [];
          acc[item.operator_id].push(item);
          return acc;
        }, {})
      };
    case SET_CERTIFICATIONS:
      return {
        ...state,
        certifications: payload,
      }
    default:
      return state;
  }
};

export const appActions = {
  setRounds(rounds) {
    return {
      type: SET_ROUNDS,
      payload: rounds
    }
  },

  setRoles(roles) {
    return {
      type: SET_ROLES,
      payload: roles,
    }
  },

  setCasinoList(payload) {
    return {
      type: SET_CASINO_LIST,
      payload,
    }
  },

  setLoading(payload) {
    return {
      type: SET_LOADING,
      payload
    }
  },

  setOptions(options) {
    return {
      type: SET_OPTIONS,
      payload: options
    }
  },

  setCertifications(payload) {
    return {
      type: SET_CERTIFICATIONS,
      payload: payload
    }
  },

  updateRound(round) {
    return {
      type: UPDATE_ROUND,
      payload: round
    }
  },

  setTxs(id, txs) {
    return {
      type: SET_TXS,
      payload: {
        id,
        txs,
      }
    }
  },

  updateTxs(id, tx) {
    return {
      type: UPDATE_TXS,
      payload: {
        id,
        tx
      }
    }
  },

  removeTxs(id) {
    return {
      type: REMOVE_TXS,
      payload: id
    }
  }
};
