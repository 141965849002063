import React, { useEffect, useState } from 'react';
import BaseModal from './baseModal';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '../../redux/modals/selectors';
import { modalTypes } from '../../redux/modals/modalTypes';
import { modalActions } from '../../redux/modals/reducer';

export default React.memo(function ConfirmModal() {
  const dispatch = useDispatch();
  const modal = useSelector(getModalData(modalTypes.CONFIRM));
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!modal.data) return;

    setData(modal.data);
  }, [modal.data]);

  const close = () => {
    dispatch(modalActions.closeModal(modalTypes.CONFIRM));
  };

  const handleCallback = (callback) => () => {
    close();
    callback && callback();
  };

  return (
    <BaseModal visible={modal.visible} onClose={close} showCloseButton style={{zIndex: data?.zIndex}}>
      <div className={`modal modal_s`}>
        <div className={'modal_header'}>
          {data?.hasOwnProperty('header') ? data.header : 'CONFIRM'}
        </div>

        <div className={'modal_body'}>
          <div className={'modal_body_message'}>
            <h2>
              {data?.hasOwnProperty('text') ? data.text : 'Please confirm operation'}
            </h2>
          </div>
        </div>

        <div className={'modal_controls'}>
          {data?.buttons.map((buttonData, index) =>
            <button key={index} onClick={handleCallback(buttonData.callback)}
                    className={buttonData.className ? buttonData.className : 'button'}>{buttonData.label}</button>
          )}
        </div>
      </div>
    </BaseModal>
  );
})
