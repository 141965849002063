import React, {useState} from 'react';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import GameResult from '../rounds/result';
import TransactionsDropdown from '../rounds/dropdown';

import {ReactComponent as Check} from '../../assets/icons/check.svg';
import {ReactComponent as Uncheck} from '../../assets/icons/uncheck.svg';

import {modalActions} from '../../redux/modals/reducer';
import {modalTypes} from '../../redux/modals/modalTypes';
import {SuperAdminCheckLayout} from '../../utils/rolesCheck';
import {appSelectors} from '../../redux/app/selectors';

export default React.memo(function RoundBlock({round}) {
  const dispatch = useDispatch();
  const options = useSelector(appSelectors.getOptions);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const openTransactionModal = (e) => {
    e.stopPropagation();
    dispatch(modalActions.showModal(modalTypes.TRANSACTION, {transaction: round, showJson: true}));
  };

  const toggleDropdown = () => {
    setDropdownVisible(prev => !prev);
  };

  const closeRound = (e) => {
    e.stopPropagation();
    dispatch(modalActions.showModal(modalTypes.CLOSE_ROUND, round));
  };

  return (
    <div className={'transaction_block_wrapper'}>
      <div className={'transaction_block'} onClick={toggleDropdown}>
        <div>
          {round.id}
        </div>
        <div>
          {/*todo: remove conditional chaining (backward capability)*/}
          {options.operators?.[round.operator_id]}
        </div>
        <div>
          {String(round.casino_id)}
        </div>
        <div>
          {round.bet_amount}
        </div>
        <div>
          {round.win_amount}
        </div>
        <div>
          {round.currency}
        </div>
        <div>
          {round.game_bundle}
        </div>
        <div className={'relative'}>
          {round.is_closed ? <Check /> : <Uncheck />}
          {!round.is_closed &&
            <SuperAdminCheckLayout>
              <span className={'close_round_button'} onClick={closeRound}>Close</span>
            </SuperAdminCheckLayout>}
        </div>
        <div>
          {round.operator_round}
        </div>
        <div>
          {round.provider_round}
        </div>
        <div>
          {round.operator_user}
        </div>
        <div>
          {moment(round.updated_at).utc(false).format('YYYY-MM-DD HH:mm:ss:SSS')}
        </div>
        <div onClick={openTransactionModal}>
          {Number(round.provider_id) === 4 ?
            <a href={`https://user-game-history.kalambagames.com/frontend/index.html?roundId=${round.provider_round}`}
               target="_blank" rel={'noreferrer noopener'}>Show info</a>
            :
            <GameResult game={round.game_bundle} result={round.result?.transactions[0].result}
                        version={round.result?.version} />
          }
        </div>
      </div>

      <TransactionsDropdown result={round.result} visible={dropdownVisible} id={round.id}
                            game={round.game_bundle} date={round.created_at} region={round.region}/>
    </div>
  );
})
