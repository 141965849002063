function operator_789() {
  
  function ReportSize() {
    var body = document.body;
    var html = document.documentElement;
    var height = Math.max(body.scrollHeight, body.offsetHeight, html.offsetHeight);

    if (window.parent !== window && window.parent.postMessage) {
      window.parent.postMessage(height, document.referrer);
    }
  }
  
  let intervalId = setInterval(ReportSize, 200);
  
  return {
    cleanup: () => clearInterval(intervalId),
  }
}

export default {
  operator_789,
}