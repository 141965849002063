'use client';
import { useCallback, useEffect, useState } from 'react';

const useTriggerBounds = (trigger, calculateBounds) => {
  const [bounds, setBounds] = useState(null);

  const getBounds = useCallback(() => {
    if (!calculateBounds || !trigger) return;
    const triggerBounds = trigger.getBoundingClientRect();
    setBounds(triggerBounds);
  }, [calculateBounds, trigger]);

  useEffect(() => {
    getBounds();
  }, [getBounds]);

  useEffect(() => {
    window.addEventListener('resize', getBounds);
    window.addEventListener('scroll', getBounds);
    return () => {
      window.removeEventListener('resize', getBounds);
      window.removeEventListener('scroll', getBounds);
    }
  }, [getBounds]);

  return bounds;
};

const supportedPositioning = {
  TOP_LEFT: 'top-left',
  BOTTOM_LEFT: 'bottom-left',
  TOP_RIGHT: 'top-right',
  BOTTOM_RIGHT: 'bottom-right',
};

const getTransform = (triggerBounds, position) => {
  let result;
  if (!Object.values(supportedPositioning).includes(position)) {
    console.error(`Unhandled positioning '${position}'`);
  }

  if (triggerBounds && position) {
    switch (position) {
      case supportedPositioning.TOP_LEFT: {
        result = `translate(${triggerBounds.left}px, calc(-100% + ${triggerBounds.top}px))`;
        break;
      }
      case supportedPositioning.TOP_RIGHT: {
        result = `translate(calc(-100% + ${triggerBounds.right}px), calc(-100% + ${triggerBounds.top}px))`;
        break;
      }
      case supportedPositioning.BOTTOM_LEFT: {
        result = `translate(${triggerBounds.left}px, ${triggerBounds.bottom}px)`;
        break;
      }
      case supportedPositioning.BOTTOM_RIGHT: {
        result = `translate(calc(-100% + ${triggerBounds.right}px), ${triggerBounds.bottom}px)`;
        break;
      }
    }
  }

  return result;
}

export {
  useTriggerBounds,
  getTransform,
  supportedPositioning,
}
