import React, { Suspense } from 'react';
import LoadingIndicator from '../loading/indicator';
const ResultView = React.lazy(() => import('@game-modules/game-result-view').then((module) => ({ default: module.ResultView })));
// import { ResultView } from '../../dist/esm';

export default React.memo(function GameResult({ game, result, version }) {

  return (
    <div className={'game_result_box'}>
      <Suspense fallback={<LoadingIndicator />}>
        <ResultView result={result} game={game} version={version} />
      </Suspense>
    </div>
  );
})
